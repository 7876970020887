/*
  JAVASCRIPT FOR YVON TAILLANDIER'S WEBSITE
  Latest update : November 2018
  Author : Claire Chabas
*/
/* --------- Changing logo for small screens --------- */
import logoLarge from "../img/logo-yvont.svg";
import logoSmall from "../img/logo-yt-whitebg.svg";

export function toggleLogoOnLoad() {
  window.addEventListener("load", function() {
    let w = window.innerWidth;
    const logo = document.querySelector(".logo");
    if (w <= 1000) {
      logo.setAttribute("src", logoSmall);
    } else {
      logo.setAttribute("src", logoLarge);
    }
  });
}
export function toggleLogoOnResize() {
  window.onresize = function() {
    let w = window.innerWidth;
    const logo = document.querySelector(".logo");
    if (w <= 1000) {
      logo.setAttribute("src", logoSmall);
    } else {
      logo.setAttribute("src", logoLarge);
    }
  };
}
/* --------- Toggling Home Bottom Popup Menu --------- */
export const toggleHomeBotNav = () => {
  let popupMenu = document.querySelector(".mobile-discover-nav");
  popupMenu.classList.toggle("show");
};
/* --------- Open Larger Image in a Light Box On Click --------- */
export const ImgZoomBox = () => {
  window.onload = function() {
    window.addEventListener("click", function(e) {
      if (
        e.target instanceof HTMLImageElement &&
        e.target.getAttribute("class") !== "logo" &&
        e.target.getAttribute("class") !== "purple-guy" &&
        e.target.getAttribute("class") !== "white-guy"
      ) {
        // Creating the popup
        let popup = document.createElement("div");
        popup.classList.add("popup");
        // Creating the popup content container
        let popupContent = document.createElement("div");
        popupContent.classList.add("popup-content");
        // Creating the close button
        let close = document.createElement("span");
        close.classList.add("close");
        close.innerHTML = '<i class="fas fa-times"></i>';
        // Creating the popup image
        let img = document.createElement("img");
        popupContent.appendChild(img);

        // Disabling click evnets on the image
        img.classList.add("disabled-from-clicks");

        // Changing the hover styling of the image
        img.style.opacity = 1;
        img.style.cursor = "default";

        popup.appendChild(close);
        popup.appendChild(popupContent);
        document.querySelector(".container").appendChild(popup);

        // Showing the popup
        popup.style.display = "block";
        img.src = e.target.getAttribute("src");
        close.onclick = function() {
          popup.style.display = "none";
        };
      }
    });
  };
};
/* --------- Back to top button --------- */
export function backToTop() {
  window.addEventListener("load", function() {
    var lastScrollPosition = 0;
    // Creating the button
    var bttButton = document.createElement("div");
    bttButton.classList.add("back-to-top");
    bttButton.innerHTML = '<i class="fa fa-chevron-circle-up"></i>';
    document.querySelector("#root").appendChild(bttButton);
    bttButton.style.display = "none";

    window.addEventListener("scroll", function(e) {
      lastScrollPosition = window.scrollY;
      // Displaying the button on scroll
      if (lastScrollPosition > 0) {
        window.requestAnimationFrame(function() {
          bttButton.style.display = "block";
          bttButton.onclick = function() {
            window.scrollTo(0, 0);
          };
        });
        // Hiding the the button if on top of page
      } else if (lastScrollPosition === 0) {
        bttButton.style.display = "none";
      }
    });
  });
}
