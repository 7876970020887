import React from 'react';
// import { Link } from 'react-router-dom';
import Footer from './Footer';
// import Bonhomme from '../img/yt-bonhomme-b.svg';
import yt130 from '../img/yt-130.svg';

const MentionsLegales = props => (
  <div className="page">
    <div className="container">
      <div className="mentions">
        <h1>Mentions Légales</h1>
        <div className="en-construction">
          <h2>Cette page est en cours de rédaction</h2>
          <img src={yt130} alt="" />
        </div>
        {/* <p>
          Les mentions légales obligatoires concernant yvon-taillandier.com sont indiquées sur cette page
          conformément à la loi pour la confiance dans l'économie numérique (LCEN) de juin 2004.
        </p>
        <div className="bonhomme"><img src={Bonhomme} alt="" /></div>
        <h3>Identification de l'éditeur</h3>
        <p>
          Association Les Amis d'Yvon Taillandier, association loi 1901, X rue XXXXX 75009 Paris.
          <br />N° SIREN : xxx-xxx-xxx
          <br />N° SIRET : xxx-xxx-xxx xxxxx
          <br />Code APE : XXXXZ / Association
        </p>
        <div className="bonhomme"><img src={Bonhomme} alt="" /></div>
        <h3>Responsable de la publication</h3>
        <p>
          "Au sens de l'article 93-2 de la loi n° 82-652 du 29 juillet 1982."
          <br />Laurent CHABAS, Secrétaire Général de l'Association Les Amis d'Yvon Taillandier.
        </p>
        <div className="bonhomme"><img src={Bonhomme} alt="" /></div>
        <h3>Prestataire d'hébergement</h3>
        <p>
          OVH
          <br />SAS au capital de 10 069 020 €
          <br />RCS Lille Métropole 424 761 419 00045
          <br />Code APE 2620Z
          <br />N° TVA : FR 22 424 761 419
          <br />Siège social : 2 rue Kellermann - 59100 Roubaix - France
          <br />Téléphone : 1007
        </p>
        <div className="bonhomme"><img src={Bonhomme} alt="" /></div>
        <h3>Traitement des données à caractère personnel</h3>
        <p>
          yvon-taillandier.com a fait l'objet d'une déclaration à la Commission Nationale de l'Informatique et des
          Libertés (CNIL) sous le n° XXXXXX.
          <br />Conformément aux dispositions de la loi n° 78-17 du 6 janvier 1978 relative à l'informatique,
          aux fichiers et aux libertés, vous disposez d'un droit d'accès, de modification, de rectification et
          de suppression des données qui vous concernent. Pour demander une modification, rectification ou
          suppression des données vous concernant, il vous suffit d'envoyer un courrier par voie électronique
          ou postale à la DILA en justifiant de votre identité.
        </p>
        <div className="bonhomme"><img src={Bonhomme} alt="" /></div>
        <h3>Prestataire</h3>
        <p>
          Le site officiel yvon-taillandier.com ainsi que son système d'administration ont été intégralement
          réalisés par :
          <br />Claire Chabas
          <br />Développement Web et Mobile
          <br />N° SIRET : xxx-xxx-xxx xxxxx
          <br />Code APE XXXXZ
          <br />Site web : codemesomething.com
          <br />E-mail : claire@codemesomething.com
        </p>
        <div className="bonhomme"><img src={Bonhomme} alt="" /></div>
        <h3>Propriété intellectuelle</h3>
        <p>
          <strong>Établir un lien</strong>
          <br />Tout site public ou privé est autorisé à établir, sans autorisation préalable, un lien vers les
          informations diffusées sur yvon-taillandier.com. En revanche, les pages du portail ne doivent pas être
          imbriquées à l'intérieur des pages d'un autre site. Pour plus de détails sur la manière d'établir
          des liens : <Link className="link" to="/contact">contactez-nous</Link>.
        </p>
        <p>
          <strong>Contenu</strong>
          <br />Yvon Taillandier a fait l'objet d'une déclaration auprès de l'ADAGP.... BLA BLA BLA
          <br />L'intégralité du contenu écrit et visuel (images, vidéos, ...) sont la propriété de l'association
          Les Amis d'Yvon Taillandier et ne peuvent être copié ou réutilisé sans accord préalable. Pour toute
          demande relative au contenu présenté sur le site
          yvon-taillander.com : <Link className="link" to="/contact">contactez-nous</Link>.
        </p>
        <div className="bonhomme"><img src={Bonhomme} alt="" /></div>
        <h3>Contact</h3>
        <p>Pour nous contacter : <Link className="link" to="/contact">rendez-vous sur notre page Contact</Link>.</p> */}
      </div>
    </div>
    <Footer />
  </div>
);

export default MentionsLegales;
