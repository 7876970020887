import React, { Component } from 'react';
import Footer from './Footer';
import OeuvresList from './OeuvresList';
import ThemesList from './ThemesList';

class Oeuvres extends Component {
  render() {
    return (
      <div className="page">

        <div className="container">
          <div className="oeuvres">
            <h1>Oeuvres</h1>
            <div className="oeuvres-page">
              <nav className="oeuvres-themes" onClick={() => document.querySelector('.oeuvres-themes ul').classList.toggle('hide')}>
                <h2>Thèmes <span><i class="fas fa-angle-down"></i></span></h2>
                <ThemesList />
              </nav>
              <OeuvresList byTheme={this.props.match.params.theme ? this.props.match.params.theme : ''} />
            </div>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}

export default Oeuvres;
