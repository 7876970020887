import axios from 'axios';

let axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL
});

export function apiCall(method, path, data) {
  return new Promise((resolve, reject) => {
    return axiosInstance[method.toLowerCase()](path, data)
      .then(res => {
        return resolve(res.data);
      })
      .catch(err => {
        return reject(err.response.data.error);
      });
  });
}
