import React from 'react';
import { Link } from 'react-router-dom';
import Footer from './Footer';
import dessin from '../img/yt-130.svg';
import purpleGuy from '../img/yt-bonhomme-v.svg';
import whiteGuy from '../img/yt-bonhomme-w.svg';

const NotFound = props => (
  <div className="page">

    <div className="container">
      <div className="not-found">
        <h1>Oups ! Nous n'arrivons pas à trouver cette page</h1>
        <p><img src={dessin} alt="" /></p>
        <div className="item-page-link">
          <Link to="/"><img className="purple-guy" src={purpleGuy} alt="" /><img className="white-guy" src={whiteGuy} alt="" />Retourner à l'accueil</Link>
        </div>
      </div>
    </div>

    <Footer />
  </div>
);

export default NotFound;
