import React from 'react';
import { Link } from 'react-router-dom';

const Homepage = () => {
  return (
    <section className="home">
      <div className="home-content">
        <p className="home-quote">"Mes tableaux se veulent des chants joyeux, voire des hymnes à la joie."</p>
        <div className="home-discover">
          <div className="home-box">Découvrez l'univers d'Yvon Taillandier :</div>
          <Link to='/le-taillandier-land' className="home-box"><span>Voyage au Taillandier-land</span></Link>
          <Link to='/le-peintre' className="home-box"><span>Le peintre</span></Link>
          <Link to='/le-critique-et-historien-dart' className="home-box"><span>Le critique et l'historien d'art</span></Link>
          <Link to='/la-figuration-liberatrice' className="home-box"><span>La figuration libératrice et altruiste</span></Link>
        </div>
        <div className="mobile-discover" onClick={() => toggleHomeBotNav()}>
          Découvrez l'univers d'Yvon Taillandier
          <nav className="mobile-discover-nav">
            <Link to='/le-taillandier-land'>Voyage au Taillandier-land</Link>
            <Link to='/le-peintre'>Le peintre</Link>
            <Link to='/le-critique-et-historien-dart'>Le critique et l'historien d'art</Link>
            <Link to='/la-figuration-liberatrice'>La figuration libératrice et altruiste</Link>
          </nav>
        </div>
      </div>
    </section>
  );
};

/* --------- Toggling Home Bottom Popup Menu --------- */
function toggleHomeBotNav() {
  let popupMenu = document.querySelector('.mobile-discover-nav');
  popupMenu.classList.toggle('show');
}

export default Homepage;
