import { LOAD_ACTUS, LOAD_ONE_ACTU } from '../actionTypes';

const actualites = (state = [], action) => {
  switch (action.type) {
    case LOAD_ACTUS:
      return [...action.actualites];
    case LOAD_ONE_ACTU:
      return [action.actu];
    default:
      return state;
  }
};

export default actualites;
