import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Moment from 'react-moment';
import Footer from './Footer';
import { getExpo } from '../store/actions/expositions';
import ImageItem from './ImageItem';
import purpleGuy from '../img/yt-bonhomme-v.svg';
import whiteGuy from '../img/yt-bonhomme-w.svg';
import avion from '../img/avion.svg';

class ExpoSingle extends Component {
  componentDidMount() {
    this.props.getExpo(this.props.match.params.id);
  }

  render() {
    const { expo } = this.props;
    let expoDom = null;
    let expoImages = null;

    if (expo) {
      expoImages = expo.images.map((img, id) =>
        <ImageItem image={img} key={id} />
      );
      expoDom =
        (<div className="item-page">
          <h1>{expo.title}</h1>
          <p className="item-page-date"><Moment format="DD / MM / YYYY">{expo.date}</Moment></p>
          <p className="item-page-subtitle">{expo.subtitle}</p>
          <div className="item-page-main-img">
            <img src={process.env.REACT_APP_API_URL+'/expositions/'+expo.images[0]} alt="" />
          </div>
          <div className="trix-text-editor">
            <div dangerouslySetInnerHTML={{ __html: expo.text }} />
          </div>
          {(expo.startEndDates && expo.address) &&
          <div className="expo-infos">
            {expo.startEndDates &&
            <div>
              <i className="far fa-calendar-alt"></i><strong>Quand</strong>
              <p>{expo.startEndDates}</p>
            </div>
            }
            {expo.address &&
            <div>
              <i className="fas fa-map-marker-alt"></i><strong>Où</strong>
              <p>{expo.address}</p>
            </div>
            }
            {expo.hours &&
            <div>
              <i className="far fa-clock"></i><strong>Horaires</strong>
              <p>{expo.hours}</p>
            </div>
            }
            {expo.link &&
            <div>
              <i className="fas fa-link"></i><strong>Plus d'infos</strong>
              <p><a href={expo.link}>Site officiel</a></p>
            </div>
            }
          </div>}
          <div className={expo.images.length > 1 ? "avion" : "avion hide"}><img src={avion} alt="" /></div>
          <div className="item-page-images">
            {expoImages.length > 1 ? imagesDisplay(expoImages) : ''}
          </div>
          <div className="item-page-link">
            <Link to="/expositions"><img className="purple-guy" src={purpleGuy} alt="" /><img className="white-guy" src={whiteGuy} alt="" />Retour aux expositions</Link>
          </div>
        </div>
        );
    }
    return (
      <div className="page">
        <div className="container">
          {expoDom}
        </div>
        <Footer />
      </div>
    );
  }
}

function imagesDisplay(images) {
  const NUM_COL = 4;
  const NUM_PER_COL = images.length < NUM_COL ? 1 : Math.floor(images.length/NUM_COL);
  let cols = [];

  if(NUM_PER_COL) {
    for(let i=0; i < images.length; i += NUM_PER_COL) {
      let rows = [];
      for(let j=i; j < (i + NUM_PER_COL); j++) {
        rows.push(images[j]);
      }
      cols.push(<div key={i} className="column">{rows}</div>);
    }
    return cols;
  }
};

function mapStateToProps(state) {
  return {
    expo: state.expositions[0]
  };
}

export default connect(mapStateToProps, { getExpo })(ExpoSingle);
