import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Moment from 'react-moment';
import Footer from './Footer';
import { getActu } from '../store/actions/actualites';
import ImageItem from './ImageItem';
import purpleGuy from '../img/yt-bonhomme-v.svg';
import whiteGuy from '../img/yt-bonhomme-w.svg';
import avion from '../img/avion.svg';

class ActuSingle extends Component {
  componentDidMount() {
    this.props.getActu(this.props.match.params.id);
  }

  render() {
    const { actu } = this.props;
    let actuDom = null;
    let actuImages = null;

    if (actu) {
      actuImages = actu.images.map((img, id) =>
        <ImageItem image={img} key={id} />
      );
      actuDom =
        (<div className="item-page">
          <h1>{actu.title}</h1>
          <p className="item-page-date"><Moment format="DD / MM / YYYY">{actu.date}</Moment></p>
          <p className="item-page-subtitle">{actu.subtitle}</p>
          <div className="item-page-main-img">
            <img src={process.env.REACT_APP_API_URL+'/actualites/'+actu.images[0]} alt="" />
          </div>
          <div className="trix-text-editor">
            <div dangerouslySetInnerHTML={{ __html: actu.text }} />
          </div>
          {actu.video && (
            <div className="item-page-video">
              <iframe title={actu.title} width="600" height="400" src={`https://www.youtube-nocookie.com/embed/${actu.video.split('/')[3]}`} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"></iframe>
            </div>
          )}
          <div className={actu.images.length <= 1 ? "avion hide" : "avion"}><img src={avion} alt="" /></div>
          <div className="item-page-images">
            {actuImages.length > 1 ? imagesDisplay(actuImages) : ''}
          </div>
          <div className="item-page-link">
            <Link to="/actualites"><img className="purple-guy" src={purpleGuy} alt="" /><img className="white-guy" src={whiteGuy} alt="" />Retour aux actualités</Link>
          </div>
        </div>
        );
    }
    return (
      <div className="page">
        <div className="container">
          {actuDom}
        </div>
        <Footer />
      </div>
    );
  }
}

function imagesDisplay(images) {
  const NUM_COL = 4;
  const NUM_PER_COL = images.length < NUM_COL ? 1 : Math.floor(images.length/NUM_COL);
  let cols = [];

  if(NUM_PER_COL) {
    for(let i=0; i < images.length; i += NUM_PER_COL) {
      let rows = [];
      for(let j=i; j < (i + NUM_PER_COL); j++) {
        rows.push(images[j]);
      }
      cols.push(<div key={i} className="column">{rows}</div>);
    }
    return cols;
  }
};

function mapStateToProps(state) {
  return {
    actu: state.actualites[0]
  };
}

export default connect(mapStateToProps, { getActu })(ActuSingle);
