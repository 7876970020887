import { apiCall } from '../../services/api';
import { addError } from './errors';
import { LOAD_ACTUS, LOAD_ONE_ACTU } from '../actionTypes';

// Action creator to load news
export const loadActualites = actualites => ({
  type: LOAD_ACTUS,
  actualites
});
// Action creator to load one news
export const loadActu = actu => ({
  type: LOAD_ONE_ACTU,
  actu
});

export const fetchActualites = () => {
  return dispatch => {
    return apiCall('get', '/api/actualites')
      .then(res => {
        dispatch(loadActualites(res));
      })
      .catch(err => {
        dispatch(addError(err.message));
      });
  };
};

export const getActu = (actu_id) => {
  return dispatch => {
    return apiCall('get', `/api/actualites/${actu_id}`)
      .then(res => {
        dispatch(loadActu(res));
      })
      .catch(err => {
        dispatch(addError(err.message));
      });
  };
};
