import React from "react";
import Footer from "./Footer";
/* Importing all images from img/bio folder */
function importAll(imgs) {
  let images = {};
  imgs.keys().map((i, index) => {
    return (images[i.replace("./", "")] = imgs(i));
  });
  return images;
}
const images = importAll(
  require.context("../img/bio", false, /\.(png|jpe?g|svg)$/)
);

const Biography = () => {
  return (
    <div className="page">
      <div className="container">
        <div className="bio">
          <h1>Regards (auto) biographiques</h1>
          <div className="bio-img">
            <img src={images["bio-0.png"]} alt="" />
            <p>
              A l'âge de 4 ans, devant un massif de fleurs dans le jardin de mes
              grands-parents maternels, à Lyon, au volant de ma « première »
              automobile.
            </p>
          </div>
          <article className="bio-para">
            <h4 className="bio-date">1926</h4>
            <p className="bio-text">Naissance à Paris le 28 mars.</p>
          </article>
          <article className="bio-para">
            <h4 className="bio-date">1935-1945</h4>
            <p className="bio-text">
              Vit à Saint-Rambert-L'Ile-Barbe, dans la région lyonnaise, au bord
              de la Saône.
            </p>
          </article>
          <article className="bio-para">
            <h4 className="bio-date">1939</h4>
            <p className="bio-text">
              Ses portraits dessinés sont remarqués par Henriette et Jean Couty,
              qui le présentent à son futur marchand, Renaud Icard.
            </p>
          </article>
          <article className="bio-para">
            <h4 className="bio-date">1942</h4>
            <p className="bio-text">
              Exposition à la galerie « L'art français » à Lyon. Rencontre le
              philosophe Jean Wahl.
            </p>
          </article>
          <div className="bio-img left">
            <img src={images["bio-1.jpg"]} alt="" />
            <p>Dessin, vers 1932</p>
          </div>
          <article className="bio-para">
            <h4 className="bio-date">1944</h4>
            <p className="bio-text">
              Publication de <span className="italic">Pages</span> (comprenant
              l'
              <span className="italic">Eloge de l'imbécilité</span>
              ), plaquette éditée par la revue{" "}
              <span className="italic">Confluences</span>.
            </p>
          </article>
          <article className="bio-para">
            <h4 className="bio-date">1945</h4>
            <p className="bio-text">
              Retour à Paris. Rencontre Braque, qui lui apprend l'égalité
              d'importance des vides et des pleins. Fait la connaissance du
              philosophe existentialiste chrétien Gabriel Marcel qui le convie
              par la suite à ses réunions du dimanche après-midi, rue de
              Tournon.
            </p>
          </article>
          <article className="bio-para">
            <h4 className="bio-date">1946</h4>
            <p className="bio-text">
              Publication d'un article sur l'art roman dans l'hebdomadaire{" "}
              <span className="italic">Paris Arts et Lettres</span>, remarqué
              par Gaston Diehl. Service militaire.
            </p>
          </article>
          <article className="bio-para">
            <h4 className="bio-date">1947</h4>
            <p className="bio-text">
              Rencontre Gaston Diehl, qui lui commande une exposition didactique
              et itinérante sur l'œuvre de Gauguin.
              <br />
              Découvre les « lignes imaginaires » (qui, sans être tracées,
              unissent les formes semblables) et le rôle du déplacement du
              regard.
              <br /> Gaston Diehl le nomme secrétaire général des Jeunesses
              artistiques (section des jeunes du mouvement des Amis de l'Art,
              association pour l'initiation à l'art moderne). Il assume cette
              fonction jusqu'en 1953.
              <br /> Gaston Diehl, président-fondateur du Salon de Mai, le fait
              nommer secrétaire du comité de celui-ci. Il le restera
              quarante-quatre ans.
            </p>
          </article>
          <div className="bio-img left">
            <img src={images["bio-2.png"]} alt="" />
            <p>Autoportrait à l'encre de Chine, vers 1945</p>
          </div>
          <article className="bio-para">
            <h4 className="bio-date">1948</h4>
            <p className="bio-text">
              Rencontre André Derain qui, évoquant ses conversations poétiques
              avec un gardien de phare à l'esprit remarquable, lui confie que
              l'intelligence serait selon lui sans rapport avec le niveau
              social.
            </p>
          </article>
          <article className="bio-para">
            <h4 className="bio-date">1949</h4>
            <p className="bio-text">
              Elabore avec Gaston Diehl un questionnaire destiné aux artistes
              sur le rapport de l'art avec l'espace et le temps.
              <br /> Il conçoit dans le catalogue du Salon de Mai les questions
              sur le temps, devenant ainsi un précurseur de la figuration
              narrative.
              <br />
              Rencontre Soulages. Essai sur Giotto, aux éditions Hypérion. Il
              collabore deux ans à la revue mensuelle{" "}
              <span className="italic">Etudiantes</span> où il publie
              d'importants articles didactiques sur la peinture, l'architecture
              et la sculpture.
            </p>
          </article>
          <article className="bio-para">
            <h4 className="bio-date">1950</h4>
            <p className="bio-text">
              Effectue de nombreuses visites-conférences dans les grandes
              expositions officielles et devant les monuments les plus
              importants de Paris, dans le cadre des « Jeunesses artistiques ».
              <br />
              Découvre le fonctionnement des formes, semblable à celui des
              rouages d'une mécanique.
            </p>
          </article>
          <article className="bio-para">
            <h4 className="bio-date">1951</h4>
            <p className="bio-text">
              Réalise un questionnaire sur l'art et le climat visuel
              contemporain (dans le catalogue du Salon de Mai), annonçant ainsi
              avec dix ans d'avance le nouveau réalisme et le pop art.
              <br />À cette occasion, rencontre Picasso.
            </p>
          </article>
          <article className="bio-para">
            <h4 className="bio-date">1952</h4>
            <p className="bio-text">
              Trois jours après sa rencontre avec Alberto Giacometti, qui allait
              jouer un rôle considérable dans sa vie, il réalise une interview
              dans la revue <span className="italic">Konstrevy</span> de
              Stockholm. Elle en sera publiée en France qu'en 1993 par les
              éditions de l'Echoppe, sous le titre{" "}
              <span className="italic">
                Je ne sais ce que je vois qu'en travaillant
              </span>
              .
            </p>
          </article>
          <article className="bio-para">
            <h4 className="bio-date">1953</h4>
            <p className="bio-text">
              Termine son roman{" "}
              <span className="italic">Les voyageurs involontaires</span>{" "}
              commencé en 1946.
            </p>
          </article>
          <div className="bio-img bio-img-big">
            <img src={images["bio-3.png"]} alt="" />
            <p>
              Avec Jacqueline Selz, dans la maison de campagne de Louis Carré,
              1955
            </p>
          </div>
          <article className="bio-para">
            <h4 className="bio-date">1954</h4>
            <p className="bio-text">
              Entre à la revue{" "}
              <span className="italic">Connaissance des arts</span> où il
              travaillera quinze ans, jusqu'en 1969.
              <br />
              Premiers articles: « Le portrait dans la peinture contemporaine »
              et « Camille Pissarro »...
            </p>
          </article>
          <article className="bio-para">
            <h4 className="bio-date">1955</h4>
            <div className="bio-text">
              <p>
                Inaugure avec{" "}
                <span className="italic">Conaissance des arts</span> une
                collaboration mensuelle. Parmi les articles publiés cette
                année-là :<br />
                <span className="bio-articles-list">
                  « André Derain collectionneur » ; « Rétrospective André Derain
                  » ; « Divinités du Népal et du Tibet » ; « Le Caravage et la
                  France » ; « Henri Laurens » ; « Hommage au Machado » ; «
                  Gilioli » ; « Singier » ; « Modigliani » (à cette occasion,
                  rencontre le grand collectionneur Dutilleux) ; « Vallotton »;
                  « De David à Toulouse-Lautrec » ; « Eugène Boudin, roi des
                  ciels » ; « Picasso, rétrospective du musée des Arts
                  décoratifs » ; « Bonnard » ; « Vieira da Silva » ; « Vasarely
                  »
                </span>
              </p>
            </div>
          </article>
          <article className="bio-para">
            <h4 className="bio-date">1956</h4>
            <div className="bio-text">
              <p>
                Articles dans{" "}
                <span className="italic">Connaissance des arts :</span>
                <br />
                <span className="bio-articles-list">
                  « L'école provençale du XVème siècle » ; « Nicolas de Staël »
                  ; « Balthus » ; « Soulages » ; « André Lhote » ; « Manessier,
                  prix Carnegie » ; « Jacques Villon » ; « Jawlensky » ; «
                  Lapicque » ; « Lardera » ; « Rebeyrolle » ; « Picabia » ; «
                  Tal-Coat, La Biennale de Venise » ; « Matisse » ; « La nature
                  morte dans l'art moderne » ; « Hartung » ; « Poliakoff » ; «
                  Max Ernst » ; « Germaine Richier » ; « Tchelitchev, peintre
                  des Ballets russes » ; « Singier »
                </span>
              </p>
            </div>
          </article>
          <article className="bio-para">
            <h4 className="bio-date">1957</h4>
            <div className="bio-text">
              <p>
                Articles dans{" "}
                <span className="italic">Connaissance des arts :</span>
                <br />
                <span className="bio-articles-list">
                  « Pevsner » ; « Jacobsen » ; « Mondrian » ; « Aristide
                  Caillaud » ; « Mario Prassinos » ; « Robert Delaunay » ; «
                  Bazaine » ; « Marc Chagall » ; « Pierre Courtin » ; « Jacques
                  Villon » ; « Giacometti » ; « Labisse » ; « Kandinsky » ; «
                  Fautrier » ; « Vieira da Silva » ; « Sonia et Robert Delaunay
                  » ; « Magnelli »
                </span>
              </p>
            </div>
          </article>
          <article className="bio-para">
            <h4 className="bio-date">1958</h4>
            <div className="bio-text">
              <p>
                Articles dans{" "}
                <span className="italic">Connaissance des arts :</span>
                <br />
                <span className="bio-articles-list">
                  « Max Ernst » ; « Chana Orloff » ; « Baudelaire » ; « Pignon »
                  ; « François Bott et la nouvelle figuration » (il est l'un des
                  premiers à employer cette expression, inventée par André
                  Breton et Charles Estienne à propos de Degottex) ; « Tamayo »
                  ; « Dmitrienko » ; « lbert Gleizes » ; « Pierre Soulages » ; «
                  Kupka » ; « Herbin » ; « Germaine Richier » ; « Renoir » ; «
                  Zadkine » ; « Nicolas Schöffer » ; « Lapicque » ; « Jean
                  Pellotier » ; « Francis Gruber » ; « Dodeigne » ; « Sugaï » ;
                  « Hajdu »
                </span>
              </p>
              <p>
                Rencontre San Lazarro, directeur de la revue{" "}
                <span className="italic">XXe siècle</span>, avec lequel il
                collabore jusqu'à sa mort. San Lazarro avait remarqué son
                interview d'Henri Laurens publiée d'abord dans la revue des{" "}
                <span className="italic">Amis de l'art</span> et reprise par la
                galerie Louise Leiris pour servir de préface au catalogue de
                l'exposition du sculpteur cubiste.
                <br />
                Rencontre et interviewe Miró.
                <br />
                Au mois d'août, au Cap-Martin, écrit sa nouvelle{" "}
                <span className="italic">Mélanctho</span>, première d'une série
                intitulée{" "}
                <span className="italic">Contes triangulaires ou coniques</span>
                , qui sera à l'origine de son retour à la peinture.
              </p>
            </div>
          </article>
          <div className="bio-img">
            <img src={images["bio-4.png"]} alt="" />
            <p>Autoportrait, 2 novembre 1959</p>
          </div>
          <article className="bio-para">
            <h4 className="bio-date">1959</h4>
            <div className="bio-text">
              <p>
                Articles dans{" "}
                <span className="italic">Connaissance des arts :</span>
                <br />
                <span className="bio-articles-list">
                  « Singier » ; « Kijno » ; « Rebeyrolle » ; « Roger Edgar
                  Gillet » ; « Eugène Leroy » ; « Rezvani » ; « Calder » ; «
                  Cardenas » ; « Ozenfant » ; « Edouard Pignon » ; « Tàpies » ;
                  « Que va devenir l'art abstrait ? » (en collaboration avec
                  Jean-François Revel) ; « Couturier » ; « Lipchitz » ; « César
                  » ; « Chagall » ; « Courtin » ; « Pierre Charbonnier » ; «
                  Dayez » ; « Victor Brauner » ; « Degottex » ; « Brown » ; «
                  Martin Barré »
                </span>
              </p>
              <p>
                Articles dans <span className="italic">XXe siècle :</span>
                <span className="bio-articles-list">
                  Interviews de Miró et de Jacques Villon, « De la pyramide au
                  carré »; de César, « Il ne faut pas se plier à la technique »
                </span>
              </p>
            </div>
          </article>
          <article className="bio-para">
            <h4 className="bio-date">1960</h4>
            <div className="bio-text">
              <p>
                Articles dans{" "}
                <span className="italic">Connaissance des arts :</span>
                <br />
                <span className="bio-articles-list">
                  « Dmitrienko » ; « Gauguin » ; « L'exposition internationale
                  du surréalisme » ; « Antagonisme (Musée des arts décoratifs) »
                  ; « Robert Muller » ; « Van Gogh » ; « Marquet » ; « Adam » ;
                  « Picasso » ; « Léger » ; « Miró » ; « Marfaing » ; « Pignon »
                  ; « Joan Mitchell » ; « Saura » ; « Germaine Richier
                  (exposition posthume) » ; « Kandinsky » ; « Gontcharova » ; «
                  Herbin » ; « Dunoyer de Segonzac » ; « Estève » ; « Dubuffet »
                  ; « Gilioli » ; « Giacometti » ; « Alicia Penalba » ; « Asger
                  Jorn » ; « Marcel Pouget »; « Hommage à Colette Allendy » ; «
                  Henry Laurens (rétrospective) » ; « Rouault (rétrospetive) » ;
                  « Picasso (45 gravures sur linoleum) » ; « Arp » ; « Riopelle
                  » ; « Zack » ; « Bryen » ; « Soulages » ; « Charchoune » ; «
                  Les sources du XXe siècle au musée d'Art moderne » ; « Julius
                  Bissier » ; « Maryan » ; « Etienne-Martin » ; « Martine
                  Boileau » ; « Geneviève Asse » ; « Messagier » ; « Tal-Coat
                  (rétrospective) » ; « Sonderborg » ; « Hartung » ; « Schneider
                  » ; « Nevelson » ; « Picasso » ; « Léger » ; « Lapicque ».
                </span>
              </p>
              <p>
                Articles dans XXe siècle :<br />
                <span className="bio-articles-list">
                  « François Stahly » ; « Dessins de Gilioli »
                </span>
              </p>
            </div>
          </article>
          <article className="bio-para">
            <h4 className="bio-date">1961</h4>
            <div className="bio-text">
              <p>
                Parution du <span className="italic">Voyage de l'œil</span> aux
                éditions Calmann-Lévy, couverture illustré par Lanskoy.
                <br />
                Parution chez Flammarion, dans la colletion « Les maîtres de la
                peinture moderne », d'une monographie sur Cézanne.
                <br />
                Participation à l'enquête sur les meilleurs peintres vivants.
                <br />
                Publication aux éditions RM Barcelone de{" "}
                <span className="italic">
                  Création Miró 1961 (Miró vu d'avion)
                </span>
                .
              </p>
              <p>
                Articles dans{" "}
                <span className="italic">Connaissance des arts :</span>
                <span className="bio-articles-list">
                  « Gargallo » ; « Kandinsky » ; « Dubuffet » ; « Le Douanier
                  Rousseau » ; « Victor Brauner » ; « Dmitrienko » ; « Singier »
                  ; « André Lhote » ; « Matisse » ; « Wotruba » ; « Berthe
                  Morisot » ; « Estève » ; « Jacobsen » ; « Corneille » ; «
                  Raoul Dufy » ; « Jacques Villon » ; « Miró » ; « Stahly » ; «
                  Burri » ; « Eugène Leroy » ; « Agam » ; « Klee » ; « Degottex
                  » ; « Villon » ; « Prassinos » ; « Tàpies » ; « Marta Pan » ;
                  « Geza Szobel » ; « Mark Tobey » ; « Fontana » ; « Goya » ; «
                  Jean Fournier » ; « Schneider » ; « Delaunay » ; « Wols » ; «
                  Maurice Henry » ; « Pourquoi les abstraits utilisent le
                  clair-obscur ? »
                </span>
              </p>
            </div>
          </article>
          <div className="bio-img left bio-img-vbig">
            <img src={images["bio-6.png"]} alt="" />
            <p>
              Au cours du voyage au Japon pour la Salon de Mai à Tokyo, 1962
            </p>
          </div>
          <article className="bio-para">
            <h4 className="bio-date">1962</h4>
            <div className="bio-text">
              <p>
                Premier voyage au Japon, pour y présenter le Salon de Mai.
                <br />
                Premier séjour en Inde.
              </p>
              <p>
                Articles dans{" "}
                <span className="italic">Connaissance des arts :</span>
                <span className="bio-articles-list">
                  « Brassaï » ; « Pougny » ; « Arp » ; « Pompon » ; « Picasso »
                  ; « Artias » ; « Le Moal » ; « Aristide Caillaud » ; « Pierre
                  de Maria » ; « Bernard Dufour » ; « Borès » ; « Victor Brauner
                  » ; « Baj » ; « Le Salon de Mai » ; « Dessins en trois
                  dimensions » ; « Matta » ; « La nouvelle figuration, une
                  nouvelle tendance chez certains abstraits » ; « Hundertwasser
                  » ; « Berrocal » ; « Filinger » ; « Fahlström »
                </span>
              </p>
              <p>
                Article dans <span className="italic">Problèmes :</span>
                <span className="bio-articles-list">« De Corot à Miró »</span>
              </p>
              <p>
                Articles dans <span className="italic">XXe siècle :</span>
                <span className="bio-articles-list">
                  « Miró précurseur » ; « La chute d'Icare, revanche des
                  humiliés »
                </span>
              </p>
            </div>
          </article>
          <article className="bio-para">
            <h4 className="bio-date">1963</h4>
            <div className="bio-text">
              <p>
                Parution chez Flammarion, dans la colletion « Les maîtres de la
                peinture moderne », d'une monographie sur Monet.
                <br />
                Parution aux éditions Les libraires associés des{" "}
                <span className="italic">
                  Naissances de la peinture moderne
                </span>
                .
              </p>
              <p>
                Articles dans{" "}
                <span className="italic">Connaissance des arts :</span>
                <span className="bio-articles-list">
                  « Dewasne » ; « Gillet » ; « Chaissac » ; « Pol Bury » ; « La
                  proposition de classNameement des peintres selon l'emploi de
                  certains procédés (ligne calligraphique, clair-obscur) » ; «
                  Sculpture, quatre aspects : marbre, fer, filiforme, mécanique
                  (Bertholle, Matta, Signori, Jacobsen, Lardera) » ; Interview
                  de Robert Couturier : « Singularité du classNameicisme en 1963
                  » ; « Braque » ; « Corneille » ; « Tàpies » ; « Raymond
                  Duchamp-Villon » ; « Ils ne peindront plus (mort de Jacques
                  Villon et Geza Szobel) » ; « Kijno » ; « Signac » ; « Geissler
                  » ; « Beaudin » ; « Alechinsky et Ting » ; « Calder » ; «
                  Prévert » ; « Bellmer »
                </span>
              </p>
              <p>
                Articles dans <span className="italic">Arts</span> en juin 1963,
                sur la mort de Jacques Villon :
                <span className="bio-articles-list">
                  « Jacques Villon : si j'avais une éternité devant moi ».
                </span>
              </p>
              <p>
                Articles dans <span className="italic">XXe siècle :</span>
                <span className="bio-articles-list">
                  « Jawlensky ou l'obsession du visage » ; « Le thème des
                  batraciens dans la peinture contemporaine » ; Interview de
                  Saura : « J'aime passionnément les spectacles de la vie »
                </span>
              </p>
              <p>
                Edition de luxe, illustrée par Miró, de l'interview de 1959
                intitulée{" "}
                <span className="italic">Je travaille comme un jardinier</span>.
              </p>
            </div>
          </article>
          <div className="bio-img bio-img-big">
            <img src={images["bio-5.png"]} alt="" />
            <p>Avec Giacometti, rue Victorien-Sardou, 1964</p>
          </div>
          <article className="bio-para">
            <h4 className="bio-date">1964</h4>
            <div className="bio-text">
              <p>
                Chapitre sur <span className="italic">Singier</span>, dans la
                collection « Peintres contemporains » aux éditions Mazenod.
                <br />
                <span className="italic">Un spectacle initiatique</span>,
                livre-objet en collaboration avec Camille Bryen.
              </p>
              <p>
                Articles dans{" "}
                <span className="italic">Conaissance des arts :</span>
                <span className="bio-articles-list">
                  « Picasso » ; « Ernst » ; « Matta » ; « Gischia » ; «
                  Bettencourt » ; « Cobra, quinze ans après » ; « Juan Gris » ;
                  « Roland Berthon » ; « Enrico Baj » ; « Sérusier » ; « Chilida
                  » ; « Muller » ; « Lobo » ; « Sophie Taeuber-Arp » ; « Kupka »
                  ; « Severini » ; « Noguchi » ; « Lapicque » ; « Hartung » ; «
                  Hopper » ; « Davis » ; « De Kooning » ; « Tobey » ; «
                  Rauschenberg » ; « Hiquily » ; « Picabia » ; « Poliakoff »
                </span>
              </p>
              <p>
                Articles dans <span className="italic">XXe siècle :</span>
                <span className="bio-articles-list">
                  « Rencontre avec Tàpies en quête de l'homme pur » ; « Pour une
                  cosmogonie de Miró » ; « Augustin Fernandez » ; « Agam et le
                  réalisme de l'infini », plaquette éditée ensuite par les
                  éditions Ariel.
                </span>
              </p>
            </div>
          </article>
          <article className="bio-imgs-line">
            <div className="bio-img">
              <img src={images["bio-7.png"]} alt="" />
              <p>Dans l'atelier de Matta, 1964</p>
            </div>
            <div className="bio-img">
              <img src={images["bio-8.png"]} alt="" />
              <p>Avec Roland Penrose, 1964</p>
            </div>
            <div className="bio-img">
              <img src={images["bio-9.png"]} alt="" />
              <p>Avec Enrico Baj dans son atelier à Milan, 1964</p>
            </div>
          </article>
          <div className="bio-img">
            <img src={images["bio-14.png"]} alt="" />
            <p>Avec Tapiès dans sa maison de Campins en Catalogne, 1966</p>
          </div>
          <article className="bio-para">
            <h4 className="bio-date">1965</h4>
            <div className="bio-text">
              <p>
                Le mille-pattes, texte d'Yvon Taillandier avec des illustrations
                d'Augustin Fernandez, édition de luxe.
              </p>
              <p>
                Articles dans{" "}
                <span className="italic">Conaissance des arts :</span>
                <span className="bio-articles-list">
                  « Rancillac et la théorie des mythologies quotidiennes » ; «
                  Gillet et la science-fiction » ; « Saura, porte-drapeau de la
                  jeune école espagnole » ; « Etienne-Martin, sculptures à
                  habiter » ; « Atila : ce qui se passe après le pop art » ; «
                  Messagier piège la nature »
                </span>
              </p>
              <p>
                Article dans <span className="italic">XXe siècle :</span>
                <span className="bio-articles-list">
                  « Naissance de l'image »
                </span>
              </p>
            </div>
          </article>
          <article className="bio-imgs-line">
            <div className="bio-img">
              <img src={images["bio-11.png"]} alt="" />
              <p>Avec Saura, au Salon de Mai, 1966</p>
            </div>
            <div className="bio-img">
              <img src={images["bio-salonmai-1966.png"]} alt="" />
              <p>
                Salon de Mai 1966, Musée d'Art Moderne, Paris.
                <br />
                De haut en bas et de droite vers la gauche :<br />
                Rebeyrolle
                <br />
                Alechinsky, César, Stahly
                <br />
                Gaston Diehl, Etienne-Martin
                <br />
                Messagier, Busse, Lobo, Coutaud
                <br />
                Yvon Taillandier, Pignon, Labisse, Dayez
                <br />
                Jacqueline Selz
              </p>
            </div>
            <div className="bio-img">
              <img src={images["bio-13.png"]} alt="" />
              <p>Avec Miró, 1966</p>
            </div>
          </article>
          <article className="bio-para">
            <h4 className="bio-date">1966</h4>
            <div className="bio-text">
              <p>
                <span className="italic">
                  L'homme, la femme et les vêtements
                </span>
                , texte d'Yvon Taillandier, illustrations d'Enrico Baj, éditions
                Sergio Tosi (Milan), édition de luxe.
              </p>
              <p>
                Articles dans{" "}
                <span className="italic">Conaissance des arts :</span>
                <span className="bio-articles-list">
                  « Martine Boileau » ; « Pignon, l'éternel combattant » ; « Le
                  Cheval majeur de Duchamp-Villon » ; « Magritte : des
                  mystifications enchantées » ; « Le jardin de Demeter »,
                  préface du catalogue de l'exposition de Martin Boileau, à la
                  galerie Creuzevault.
                </span>
              </p>
              <p>
                Articles dans <span className="italic">XXe siècle :</span>
                <span className="bio-articles-list">
                  « De Baudelaire à la sculpture mouvante » ; « Tàpies ou le mur
                  qui rêve »
                </span>
              </p>
              <p>Voyage en Yougoslavie pour présenter le Salon de Mai.</p>
            </div>
          </article>
          <article className="bio-para para-block">
            <div className="bio-img left bio-img-big">
              <img src={images["bio-15.png"]} alt="" />
              <p>
                A Cuba, dessinant au tableau noir lors d'une conférence pour
                expliquer les visages picassiens, 1967
              </p>
            </div>
            <h4 className="bio-date">1967</h4>
            <div className="bio-text">
              <p>
                Deux monographies sur Rodin et sur Corot dans la collection «
                Les maîtres de la peinture moderne », Flammarion.
              </p>
              <p>
                Articles dans{" "}
                <span className="italic">Conaissance des arts :</span>
                <span className="bio-articles-list">
                  « Alan Davie, l'enchanteur écossais » ; « Hundertwasser » ; «
                  Michaux » ; « Edmund Aleyn. L'angoisse de l'an 2000 » ; « La
                  peinture lumière » ; « Le photographisme » ; « L'Amérique
                  latine apporte une nuance nouvelle » ; « Les peintres juges de
                  leur temps : Cremonini »
                </span>
              </p>
              <p>
                Articles dans <span className="italic">XXe siècle :</span>
                <span className="bio-articles-list">
                  « Généalogie de l'homme-oiseau (à propos de l'oiseau-lune et
                  de l'oiseau-soleil de Miró) »
                </span>
              </p>
              <p>Voyage à Cuba pour présenter le Salon de Mai.</p>
              <p>
                Article dans l'hebdomadaire cubain{" "}
                <span className="italic">Gramma :</span>
                <span className="bio-articles-list">
                  « Le Salon de Mai vu d'avion »
                </span>
              </p>
            </div>
          </article>
          <div className="bio-img bio-img-big">
            <img src={images["bio-16.png"]} alt="" />
            <p>
              Avec Christian Geelhaar, auteur de{" "}
              <span className="italic">Paul Klee et le Bauhaus</span> (Ides et
              Calendes)
            </p>
          </div>
          <article className="bio-para">
            <h4 className="bio-date">1968</h4>
            <div className="bio-text">
              <p>
                Article dans{" "}
                <span className="italic">Conaissance des arts :</span>
                <span className="bio-articles-list">
                  « Un observatoire en Inde nouvellement photographié »
                </span>
              </p>
              <p>
                Fin de la collaboration avec{" "}
                <span className="italic">Conaissance des arts</span>
              </p>
              <p>
                Article dans <span className="italic">XXe siècle :</span>
                <span className="bio-articles-list">
                  « Aventure au musée Rodin »
                </span>
              </p>
              <p>
                « Voyage à travers une œuvre », 2è chapitre dans{" "}
                <span className="italic">Van Gogh</span>, collection « Génies et
                réalités », éditions Réalités.
                <br />
                Voyage à Cuba comme consultant à l'Unesco : deux moi et demi de
                conférences sur la psycho-vision.
                <br />
                Voyage au Mexique.
              </p>
            </div>
          </article>
          <article className="bio-para">
            <h4 className="bio-date">1969</h4>
            <div className="bio-text">
              <p>
                Publication de{" "}
                <span className="italic">
                  L'abstrait 1900-1950. Un histoire gloable de l'art et du monde
                </span>
                , dans la collection « Les métamorphoses de l'humanité »,
                éditions Planète.
              </p>
              <p>
                Article dans <span className="italic">XXe siècle :</span>
                <span className="bio-articles-list">
                  « Yves Klein et Arman au musée »
                </span>
              </p>
            </div>
          </article>
          <div className="bio-img left bio-img-big">
            <img src={images["bio-17.png"]} alt="" />
            <p>
              Au Salon de Mai, interprétant et dessinant les oeuvres exposées :
              « Mon ambition : faire un portrait du Salon comme on fait le
              portrait d'une personne, première forme de la{" "}
              <span className="italic">Nocrida</span> (nouvelle critique d'art)
              »
            </p>
          </div>
          <article className="bio-para">
            <h4 className="bio-date">1970</h4>
            <div className="bio-text">
              <p>Voyage en Allemagne.</p>
              <p>
                Article dans <span className="italic">XXe siècle :</span>
                <span className="bio-articles-list">
                  « Pourquoi tant d'horloges et de poissons-volants ? » (à
                  propos de Chagall) ; « Matisse et la préhistoire du bonheur »
                </span>
              </p>
              <p>
                Publication de{" "}
                <span className="italic">Dessins de Wifredo Lam</span>, texte «
                Le Diable et le Dessin », aux éditions Denoël (collection
                dirigée par Alex Grall).
                <br />« La pays de Renoir : puissance et douceur », dnas{" "}
                <span className="italic">Renoir</span>, collection « Génies et
                réalités », aux éditions Réalités.
                <br />
                Voyage en Yougoslavie pour la présentation du Salon de Mai à
                Skopje et Belgrade. Inauguration du musée de Skopje.
                <br />
                Voyage en Grèce. Voyage en Turquie. Nommé commissaire artistique
                de la Biennale de Katmandou (Népal). Admirateur des beautés
                ornementales des monuments et édifices népalais, il exécute ses
                premiers dessins sur papier de format raisin. Pour lui, qui a
                toujours dessiné dans des cahiers, ce changement de support
                constitue un pas décisif vers la peinture.
                <br />
                Voyage en Thaïlande et en Inde. L'écriture thaïlandaise lui
                inspire le thème des personnages associés, les lettres y étant
                liées les unes aux autres par un trait qui les domine. Les
                influences exercées par les spectacles et les œuvres vus au
                cours de ce voyage sont déterminantes pour lui. De retour à
                Paris, Il commence à dessiner et à colorer des œuvres d'un
                format raisin sur papier et à exécuter de grandes compositions
                sur carton de plus d'un mètre de haut.
              </p>
            </div>
          </article>
          <div className="bio-img">
            <img src={images["bio-18.png"]} alt="" />
            <p>Yvon Taillandier à quarante-cinq ans</p>
          </div>
          <article className="bio-para">
            <h4 className="bio-date">1971</h4>
            <div className="bio-text">
              <p>
                Article dans <span className="italic">XXe siècle :</span>
                <span className="bio-articles-list">
                  « La faune du paradis retrouvé » (sur le bestiaire de Max
                  Ernst) ; « La partie de campagne ou les fonctions
                  complémentaires de l'arbre et de la machine dans l'œuvre de
                  Fernand Léger » ; « Un musée d'art moderne en Macédoine » (à
                  propos de l'ouverture du musée de Skopje) ; « Agam : les
                  sculptures transformables » (ce texte a servi de préface à une
                  plaquette).
                </span>
              </p>
              <p>
                Exposition personnelle : Galerie Delta à Rotterdam, peintures
                sur papier et sur carton, dont le thème principal est « la
                nouvelle critique d'art ». Ce sera la seule exposition sur ce
                thème, intitulée « Vive les grosses têtes au paradis ».
                <br />
                Premier catalogue.
              </p>
            </div>
          </article>
          <article className="bio-para">
            <h4 className="bio-date">1972</h4>
            <div className="bio-text">
              <p>
                Article dans <span className="italic">XXe siècle :</span>
                <span className="bio-articles-list">
                  « Francis Bacon ou l'espace du{" "}
                  <span className="italic">naissant</span> » ; « Nostalgie du
                  féminin » (sur Henry Moore) ; « De Calder, des bijoux, des
                  araignées et des anges »
                </span>
              </p>
              <p>
                Publication de <span className="italic">Miró à l'encre</span>{" "}
                aux éditions XXe siècle : pour la première fois, il fait parler
                un point de couleur comme s'il était une personne humaine.
                <br />
                Première exposition au Salon de Mai, sur l'invitation de
                Singier, d'un petit monument de carton, reproduit dans{" "}
                <span className="italic">Les Lettres françaises</span>. Depuis,
                y expose chaque année.
                <br />
                Edition de sérigraphies par l'atelier de Nikos.
              </p>
            </div>
          </article>
          <div className="bio-img left">
            <img src={images["bio-22.png"]} alt="" />
            <p>
              Jacqueline Selz avec André Pieyre de Mandiargues, lequel disait de
              Taillandier qu'« en paraissant s'amuser à peindre, il donne des
              leçons de peinture à bien des gens ». (photo : Heidi Meister)
            </p>
          </div>
          <article className="bio-para">
            <h4 className="bio-date">1973</h4>
            <div className="bio-text">
              <p>
                Article dans <span className="italic">XXe siècle :</span>
                <span className="bio-articles-list">
                  « Joan Miró et l'inconnu des sobreteixims »
                </span>
              </p>
              <p>
                Publication dans un journal du sud de l'Inde de « Picasso vivant
                ou de <span className="italic">Guernica</span> à la{" "}
                <span className="italic">Joconde</span> ».
                <br />
                Préface du catalogue du peintre Ouanès Amor.
                <br />
                Voyage en Inde en tant que commissaire artistique invité par le
                gouvernement indien.
              </p>
              <p>
                Exposition personelle :
                <span className="bio-articles-list">
                  Sur invitation des Artias, grande exposition à la maison de la
                  culture de Saint-Etienne [catalogue]
                </span>
              </p>
              <p>
                Exposition collective :
                <span className="bio-articles-list">
                  Participation à la rétrospective « Prospective des galeries
                  des Halles-Beaubourg » avec la galerie de Cérès Franco.
                </span>
              </p>
            </div>
          </article>
          <div className="bio-img">
            <img src={images["bio-19.png"]} alt="" />
            <p>
              Devant une partie du char réalisé pour la fête des Femmes à Vitry
              exposée au Salon de Mai, 1975. (photo : André Morain)
            </p>
          </div>
          <div className="bio-img left">
            <img src={images["bio-21.png"]} alt="" />
            <p>
              Meret Oppenheim (à gauche), lors du vernissage de l'exposition à
              L'Œil-de-bœuf en 1974. (photo : Heidi Meister)
            </p>
          </div>
          <article className="bio-para">
            <h4 className="bio-date">1974</h4>
            <div className="bio-text">
              <p>
                Interview dans <span className="italic">XXe siècle :</span>
                <span className="bio-articles-list">
                  « Miró : maintenant, je travaille par terre... »
                </span>
              </p>
              <p>
                Expositions personnelles :
                <span className="bio-articles-list">
                  Centre culturel Pablo Neruda, Corbeil-Essonnes [catalogue]
                  <br />
                  Galerie L'Œil-de-bœuf, Paris
                </span>
              </p>
              <p>
                Exposition collective :
                <span className="bio-articles-list">
                  « Parentés imaginaires », M. J. C. de Saint-Etienne
                  [réalisation du catalogue]
                </span>
              </p>
              <p>
                Rouleau de carton converti en fresque pour une salle de l'école
                Grandjean à Paris.
              </p>
            </div>
          </article>
          <article className="bio-para">
            <h4 className="bio-date">1975</h4>
            <div className="bio-text">
              <p>
                Article dans <span className="italic">XXe siècle :</span>
                <span className="bio-articles-list">
                  « San Lazarro et ses amis »
                </span>
              </p>
              <p>
                Voyage en Inde : donne trois mois de cours sur l'art européen,{" "}
                <span className="italic">de Guernica à nos jours</span>, à
                l'université Nehru de New Delhi; achat d'un grand tableau{" "}
                <span className="italic">Eloge de la lecture</span> par la
                bibliothèque de l'université de Nehru.
              </p>
              <p>
                Exposition personnelle :
                <span className="bio-articles-list">
                  Alliance française de New Delhi
                </span>
              </p>
              <p>
                Réalisation d'un monument de bois opur le{" "}
                <span className="italic">Char des femmes associées</span> dans
                le cadre de la fête des Lilas à Vitry.
              </p>
              <p>
                Article de « nouvelle critique d'art » (« nocrida ») dans le n°
                25 des <span className="italic">Cahiers de la peinture</span>.
                Un second article de « nocrida » y paraîtra en 1977.
                <br />
                Décoration d'une chambre de service appelée « Chapelle Sixtine
                de la rue Victorien-Sardou ».
              </p>
            </div>
          </article>
          <div className="bio-img left bio-img-big">
            <img src={images["bio-20.png"]} alt="" />
            <p>
              Avec Jacqueline Selz, Singier et Berthon devant une bannière
              présentant des bateaux-gigognes, 1976
            </p>
          </div>
          <article className="bio-para">
            <h4 className="bio-date">1976</h4>
            <div className="bio-text">
              <p>
                Importante décoration d'une terrasse à Paris.
                <br />
                Dans le cadre du 1%, réalisation de quatre fresques (longueur 7
                m, hauteur 0,8 m pour le collège de Faches-Thumesnil près de
                Lille).
                <br />
                Participation à l'exposition « Bannières » à Paris, avec une
                bannière sur le thème des bateaux-gigognes.
                <br />
                Participation à l'exposition sur les boîtes au musée d'Art
                moderne de la Ville de Paris.
              </p>
            </div>
          </article>
          <article className="bio-para">
            <h4 className="bio-date">1977</h4>
            <div className="bio-text">
              <p>
                Dans la maison de Nikos en Normandie, iportante série de dessins
                sur la vie du Christ dans le Taillandier-land et sculpture en
                béton.
              </p>
              <p>
                Expositions personnelles :
                <span className="bio-articles-list">
                  Galerie L'Œil-de-bœuf et galerie Maître Albert, Paris
                  <br />
                  Alliance française de New Delhi
                </span>
              </p>
            </div>
          </article>
          <div className="bio-img bio-img-big">
            <img src={images["bio-23.png"]} alt="" />
            <p>
              Avec Corneille lors d'un hommage à Cuba à l'Unesco, 1979 (photo :
              Heidi Meister)
            </p>
          </div>
          <article className="bio-para">
            <h4 className="bio-date">1978</h4>
            <div className="bio-text">
              <p>
                Expositions personnelles :
                <span className="bio-articles-list">
                  Club de l'Unesco de l'université Nehru de New Delhi
                  <br />« Indian News » à la galerie Jacques Damase, à Paris. Un
                  projet de livre avec ce grand éditeur n'aboutira pas.
                </span>
              </p>
              <p>
                Acquisition par le musée Hara de Tokyo de deux œuvres réalisées
                au cours d'un voyage au Japon.
              </p>
            </div>
          </article>
          <article className="bio-para">
            <h4 className="bio-date">1979</h4>
            <div className="bio-text">
              <p>Voyage au Japon avec le Salon de Mai.</p>
              <p>
                Expositions personnelles :
                <span className="bio-articles-list">
                  A Montmorillon (Vienne), à Paris, à la galerie du centre
                  culturel et chorégraphique
                </span>
              </p>
              <p>
                Expositions collectives :
                <span className="bio-articles-list">
                  Hommage à Cuba (Unesco)
                  <br />« Féminie dialogue 79 », Maison de L'Unesco
                </span>
              </p>
            </div>
          </article>
          <div className="bio-img left">
            <img src={images["bio-24.png"]} alt="" />
            <p>
              Avec Kijno et Jacques Damase (à gauche) à la galerie
              L'Œil-de-bœuf, 1980 (photo : Heidi Meister)
            </p>
          </div>
          <article className="bio-para">
            <h4 className="bio-date">1980</h4>
            <div className="bio-text">
              <p>Sélectionné pour la Bourse d'art monumental d'Ivry.</p>
              <p>
                Expositions collectives :
                <span className="bio-articles-list">
                  Galerie de Caroline Corre, Bar de l'aventure, Paris, avec
                  trois livres peints sur partitions musicales.
                  <br />« Aspects de la sculpture », centre culturel
                  Elsa-Triolet, Saint-Cyr-l'Ecole [préface du catalogue].
                  <br />« Formes humaines », jardins du musée Rodin, à Paris.
                  Acaht par le musée de la sculpture en plein air du monument de
                  bois exposé.
                </span>
              </p>
              <p>
                Décoration de la façade de la mairie d'Ivry.
                <br />
                Participation à la « semaine de l'enfance »; exposition des
                dessins préparatoires au centre médico-social; réalisation de
                l'affiche.
                <br />
                Donation personnelle d'une œuvre au musée d'Art moderne de Rio
                de Janeiro.
              </p>
              <p>
                Expositions collectives :
                <span className="bio-articles-list">
                  « La suite fabuleuse du Taillandier-land », Galerie
                  L'Œil-de-bœuf.
                </span>
              </p>
            </div>
          </article>
          <div className="bio-img bio-img-vbig ceresfranco">
            <img src={images["bio-27.png"]} alt="" />
            <p>Avec Cérès Franco, 1983</p>
            <p>
              <span className="italic">
                « Une très importante rencontre dans ma vie fut celle de Cérès
                Franco, qui devait défendre ma peinture au point de l'exposer à
                sept reprises. Cérès, d'origine brésilienne, avait étudié
                l'histoire de l'art et la littérature à New York et commencé
                d'exercer, comme moi, le métier de critique d'art dans son pays
                en publiant notamment un grand nombre d'articles importants sur
                la vie artistique européenne. Elle a diffusé en France l'art
                populaire brésilien, en particulier des ex-voto en bois d'une
                qualité exceptionnelle aux yeux des spécialistes, et organisé
                des expositions qui la rendirent célèbre. Elle eut notamment
                l'idée originale de proposer à un grand nombre de jeunes
                peintres de talent d'exécuter des tableaux ovales, à un moment
                où, à Paris, dans les milieux d'avant-garde, se posait la
                question très importante du support de la peinture. En 1972,
                elle ouvrit la célèbre galerie L'Œil-de-bœuf - première galerie
                de la rue Quincampoix - où elle devait, vinght-cinq ans durant,
                manifester son indépendance, sa curiosité, son savoir et sa
                prescience, en exposant des artistes négligés des grandes
                galeries et qui devaient cependant devenir fameux par la suite.
                En achetant pour elle-même des œuvres aux artistes qu'elle
                présentait, elle a réuni au fil des ans une immense collection -
                installée dans un très beau village classNameé des Corbières,
                Lagrasse, dans l'Aude - témoignant de ses goûts, de ses pensées,
                de sa perspicacité critique et dont elle aime à dire qu'elle
                représente sa vie.
                <br />
                Aujourd'hui, au XXIe siècle, on parle beaucoup des singuliers de
                l'art : cette expression a été employée pour la première fois en
                1978 (Arc 2, musée d'Art moderne de la Ville de Paris) et en
                1988 par Catherine Huber dans le même musée, pour rendre hommage
                à l'activité de Cérès Franco. Je participai à cette
                manifestation avec Corneille, Chaïbia, Da Costa, Guimarès,
                Jaber, Lattier, Macréau, Mandive, Piciotto et Selassié. Dans le
                catalogue Naïfs, bruts, singuliers?, Cérès me qualifie de «
                fabulateur exacerbé ».
                <br />
                Douée d'une forte personnalité, cette grande découvreuse de
                talents, apparaît désormais aux côtés de Denise René, Iris
                Clert, Colette Allendy ou Ileana Sonnabend, comme l'une des plus
                brillante galeristes de la seconde moitié du XXe siècle. Une
                grande dame. »
              </span>
            </p>
          </div>
          <article className="bio-para">
            <h4 className="bio-date">1981</h4>
            <div className="bio-text">
              <p>
                Expositions personnelles :
                <span className="bio-articles-list">
                  Comité d'entreprise d'Air France, Roissy; réalisation d'un
                  catalogue consacré l'aviation taillandier-landaise, la «
                  Compata ».
                  <br />
                  Galerie Fine Arts, Tokyo.
                </span>
              </p>
              <p>
                Expositions collectives :
                <span className="bio-articles-list">
                  Exposition de livres organisées par Caroline Corre au Grand
                  Palais, à Paris.
                  <br />
                  IIème Triennale de sculpture au Grand Palais, Paris.
                </span>
              </p>
            </div>
          </article>
          <article className="bio-para">
            <h4 className="bio-date">1982</h4>
            <div className="bio-text">
              <p>
                Expositions collectives :
                <span className="bio-articles-list">
                  « Bateaux et autres figures d'un voyage » organisée par
                  Caroline Corre à la galerie Bar de l'aventure : cinq
                  partitions peintes.
                  <br />« Union des femmes françaises », Paris.
                  <br />
                  Musée de Sculpture en plein air, Paris.
                </span>
              </p>
              <p>
                Acquisition par le Fonds national d'art contemporain (Centre
                Georges Pompidou) du livre peint{" "}
                <span className="italic">La conjuration des fleurs</span>.<br />
                Publication de 18 reproductions dans la revue{" "}
                <span className="italic">Signos</span> (ministère de la Culture
                de Cuba).
              </p>
            </div>
          </article>
          <article className="bio-para">
            <h4 className="bio-date">1983</h4>
            <div className="bio-text">
              <p>
                Expositions collectives :
                <span className="bio-articles-list">
                  « Monument/Détournement », Boulogne-sur-mer
                  <br />
                  Trois toiles, exposition itinérante, musées d'art
                  contemporain, Caracas et Bogota
                  <br />« La valise » à la M. J. C. de Créteil
                </span>
              </p>
              <p>
                Expositions personnelles :
                <span className="bio-articles-list">
                  « L'entrée des robots dans le Taillandier-land », galerie
                  L'Œil-de-bœuf, Paris.
                  <br />« Fresque amovible pour Paco Ibanez » (longueur 50 m;
                  hauteur 1,30 m), Carré Sylvia Montfort.
                  <br />
                </span>
              </p>
              <p>
                Monument de ciment peint pour le complexe sportif
                Honoré-de-Balzac de Vitry (hauteur 4 m, six faces) :
                photographie du monument en couverture du bulletin municipal.
              </p>
            </div>
          </article>
          <article className="bio-para">
            <h4 className="bio-date">1984</h4>
            <div className="bio-text">
              <p>
                Expositions personnelles :
                <span className="bio-articles-list">
                  Service culturel de l'ambassade de France à Washington
                  <br />
                  Galerie Caroline Corre, Paris
                </span>
              </p>
              <p>Fresques pour la Fête de l'Humanité à la Courneuve.</p>
              <p>
                Exposition collective : « Figures », M. J. C. Les Hauts de
                Belleville, Paris.
              </p>
            </div>
          </article>
          <article className="bio-imgs-line">
            <div className="bio-img">
              <img src={images["bio-25.png"]} alt="" />
              <p>
                Avec Raoul-Jean Moulin, auteur en 1980 du premier grand article
                sur la peinture d'Yvon Taillandier, à la galerie L'Œil-de-bœuf,
                1983. (photo Heidi Meister)
              </p>
            </div>
            <div className="bio-img">
              <img src={images["bio-26.png"]} alt="" />
              <p>
                Jacqueline Selz et son amie Juliette Browner, veuve de Man Ray à
                la galerie L'Œil-de-bœuf, 1983. (photo Heidi Meister)
              </p>
            </div>
            <div className="bio-img">
              <img src={images["bio-28.png"]} alt="" />
              <p>
                Hélène Parmelin (au centre) et Edouard Pignon (à droite) à
                l'exposition « Taillandier » à la galerie L'Œil-de-bœuf, 1983.
                (photo Heidi Meister)
              </p>
            </div>
          </article>
          <article className="bio-para">
            <h4 className="bio-date">1985</h4>
            <div className="bio-text">
              <p>
                Expositions collectives :
                <span className="bio-articles-list">
                  « Hommage à Tintin » à Liévin (Pas-de-Calais)
                  <br />« Regards sur la ville », groupe 109, au Grand Palais,
                  Paris
                  <br />« L'art en milieu hospitalier », exposition organisée
                  par le docteur Max Dana à l'hôpital de Poissy; grand
                  collectionneur Max Dana a fondé L'Œil neuf, association
                  culturelle pour la défense de l'art moderne
                </span>
              </p>
              <p>
                Exposition personnelle :
                <span className="bio-articles-list">
                  « Hommage à Yvon Taillandier » à la Galerie Fine Arts de Tokyo
                </span>
              </p>
            </div>
          </article>
          <div className="bio-img bio-img-big">
            <img src={images["bio-29.png"]} alt="" />
            <p>Avec Bertrand-Moulin dans l'atelier</p>
          </div>
          <article className="bio-para">
            <h4 className="bio-date">1986</h4>
            <div className="bio-text">
              <p>
                Parution du livre par Baltazar et Yvon Taillandier :{" "}
                <span className="italic">Il faut être de son temps</span>, livre
                de bibliophilie, 2 exemplaires.
              </p>
              <p>
                Expositions collectives :
                <span className="bio-articles-list">
                  « Enveloppes peintes et transfigurées », galerie Caroline
                  Corre, Paris
                  <br />« Florilège de l'art contemporain », Faches-Thumesnil,
                  près de Lille
                </span>
              </p>
              <p>
                Expositions personnelles :
                <span className="bio-articles-list">
                  Galerie Lavigne, Paris
                  <br />« L'imagerie », au centre municipal de psychanalyse de
                  Vitry, avec notamment 9 peintures (2 x 2 m) [préface du
                  catalogue]
                </span>
              </p>
            </div>
          </article>
          <article className="bio-para">
            <h4 className="bio-date">1987</h4>
            <div className="bio-text">
              <p>
                Exposition collective :
                <span className="bio-articles-list">
                  Participation importante à une exposition sur l'art postal au
                  Bourget
                </span>
              </p>
              <p>
                Exposition personnelle :
                <span className="bio-articles-list">
                  « Les habitants du ciel », galerie L'Œil-de-bœuf, Paris
                </span>
              </p>
              <p>Article de Pierre Brisset sur Yvon Taillandier dans L'œil.</p>
            </div>
          </article>
          <div className="bio-img bio-img-big left">
            <img src={images["bio-30.png"]} alt="" />
            <p>
              Avec Gaston Diehl, dont l'importance fut considérable dans la
              carrière de critique d'art d'Yvon Taillandier.
            </p>
          </div>
          <article className="bio-para">
            <h4 className="bio-date">1988</h4>
            <div className="bio-text">
              <p>
                Expositions personnelles :
                <span className="bio-articles-list">
                  « Peintures 1970-1987 », galerie municipale de Vitry [préface
                  du catalogue]
                  <br />
                  Galerie « K », Washington
                  <br />
                  Galerie du Théâtre de Vanves, avec un catalogue
                </span>
              </p>
              <p>
                Expositions collectives :
                <span className="bio-articles-list">
                  Importante participation à « Singuliers, bruts ou naïfs ? »,
                  avec un catalogue, musée des enfants, au musée d'Art moderne
                  de la Ville de Paris
                  <br />« Hommage à Iris Clert », Biennale des femmes, Grand
                  Palais, Paris
                </span>
              </p>
              <p>
                Parution en italien du livre{" "}
                <span className="italic">Taillandier-land</span> (edizioni La
                Gradiva).
                <br />
                Réalisation d'un étendard pour la défense de Nelson Mandela,
                présenté devant le Centre Pompidou et acquis par la ville de
                Gardanne (Bouches-du-Rhône).
                <br />
                Sérigraphie <span className="italic">Agenda</span> pour Del Arco
                à Paris.
              </p>
            </div>
          </article>
          <div className="bio-img">
            <img src={images["peinture-65x50-1990.jpg"]} alt="" />
            <p>Peinture 65x50, 1990</p>
          </div>
          <article className="bio-para">
            <h4 className="bio-date">1989</h4>
            <div className="bio-text">
              <p>
                Expositions collectives :
                <span className="bio-articles-list">
                  « La liberté, l'égalité, la fraternité », galerie L'art et la
                  paix, Saint-Ouen
                  <br />
                  Invité d'honneur à la 11è Biennale de Langres [catalogue avec
                  préface intitulée : « Les huit règles de la peinture de l'an
                  2000 vues du Taillandier-land »]
                  <br />
                  8e Marché de la Poésie, numéro spécial Taillandier : « Petite
                  parade pour le Taillandier-land ». Editeur Landier.
                </span>
              </p>
              <p>
                Expositions personnelles :
                <span className="bio-articles-list">
                  Galerie Sonia K à Lille, publication du premier tome du{" "}
                  <span className="italic">
                    Dictionnaire du Taillandier-land
                  </span>{" "}
                  en guise de catalogue
                  <br />
                  Fine Arts, Tokyo
                </span>
              </p>
            </div>
          </article>
          <article className="bio-para">
            <h4 className="bio-date">1990</h4>
            <div className="bio-text">
              <p>
                Exposition personnelle :
                <span className="bio-articles-list">
                  Galerie Lavignes-Bastille, Paris
                </span>
              </p>
              <p>
                Réalisation d'une affiche pour la campagne de promotion de la
                santé mentale de la revue{" "}
                <span className="italic">La folie</span>; aricle de R. Desmons
                (association médico-psycho-sociale de Lille).
              </p>
            </div>
          </article>
          <div className="bio-img">
            <img src={images["bio-31.jpg"]} alt="" />
            <p>Affiche pour la Fiac, 1991</p>
          </div>
          <article className="bio-para bio-separator" />
          <article className="bio-para">
            <h4 className="bio-date">1991</h4>
            <div className="bio-text">
              <p>
                Exposition collective :
                <span className="bio-articles-list">
                  FIAC avec la galerie Lavignes-Bastille [catalogue avec 64
                  reproductions]
                  <br />
                  Carroserie peinte d'une Travant, au Centre international de
                  l'automobile.
                </span>
              </p>
              <p>
                Expositions personnelles :
                <span className="bio-articles-list">
                  « Noël au Taillandier-land », galerie Lavignes-Bastille, Paris
                  <br />
                  Litographies récentes aux éditions de l'Atelier Clot, à Paris
                </span>
              </p>
              <p>
                Poème de Claude Raphaël Samama « Chorus pour la peinture d'Yvon
                Taillandier », recopié à la main et illustré par Yvon
                Taillandier.
              </p>
            </div>
          </article>
          <article className="bio-para">
            <h4 className="bio-date">1992</h4>
            <div className="bio-text">
              <p>
                Articles dans <span className="italic">Valeurs de l'art :</span>
                <span className="bio-articles-list">
                  « Eloge de l'imbécilité », « Taillandier par Taillandier », et
                  par Armel Crew « Taillandier dans la tête » et illustration de
                  la couverture de la revue.
                </span>
              </p>
              <p>
                Exposition personelle :
                <span className="bio-articles-list">
                  Galerie Sonia K, Lille, publication du second tome du{" "}
                  <span className="italic">
                    Dictionnaire du Taillandier-land
                  </span>{" "}
                  en guise de catalogue.
                </span>
              </p>
              <p>
                Couverture de{" "}
                <span className="italic">Journal d'une anorexie</span> de Kostas
                Nassikas, éditions L'Harmattan.
                <br />
                Couverture du livre{" "}
                <span className="italic">Familles en souffrance</span>,
                Scandéditions.
              </p>
            </div>
          </article>
          <div className="bio-img left">
            <img src={images["peinture-146x114-1994.jpg"]} alt="" />
            <p>Peinture 146x114, 1994</p>
          </div>
          <article className="bio-para">
            <h4 className="bio-date">1993</h4>
            <div className="bio-text">
              <p>
                Réalisation de l'affiche du Salon international de l'affiche au
                Grand Palais, Paris.
                <br />
                Publication d'
                <span className="italic">
                  Alberto Giacometti, Je ne sais ce que je vois qu'en
                  travaillant
                </span>
                , propos recueillis par Yvon Taillandier, aux éditions de
                l'Echoppe.
              </p>
              <p>
                Exposition collective :
                <span className="bio-articles-list">
                  Organisée par André Parinaud au Grand Palais, Paris
                  <br />
                  Avec Alain Compas, galerie Sonia K à Paris
                  <br />
                  Yvon Taillandier avec Annie Polak, graveur, «
                  Encadreurs/Encadrés », galerie Anne Blanc, Paris
                </span>
              </p>
            </div>
          </article>
          <div className="bio-img">
            <img src={images["bio-32.png"]} alt="" />
            <p>
              Yvon Taillandier installé sur l'un de ses monuments en bois pour
              en prouver la solidité.
            </p>
          </div>
          <article className="bio-para">
            <h4 className="bio-date">1994</h4>
            <div className="bio-text">
              <p>
                Expositions personnelles :
                <span className="bio-articles-list">
                  Galerie Daniel Duchoze, Rouen
                  <br />
                  Galerie Hélios, Calais
                  <br />
                  Galerie Sonia K, Lille
                </span>
              </p>
              <p>
                Exposition collective :
                <span className="bio-articles-list">
                  « Bannières de la paix », galerie L'art et la paix,
                  Saint-Ouen; édition d'une carte postale{" "}
                  <span className="italic">Le dragon bonasse</span>.
                </span>
              </p>
              <p>
                <span className="italic">Bandonéon</span>, sérigraphie imprimée
                par Del Arco à Paris.
                <br />
                Carrosserie peinte d'une <span className="italic">Twingo</span>,
                collection Robert Vidili.
              </p>
            </div>
          </article>
          <article className="bio-para">
            <h4 className="bio-date">1995</h4>
            <div className="bio-text">
              <p>
                <span className="italic">Rosita au Taillandier-land</span>,
                pièce de théâtre de Bob Villette, jouée à la Comédie errante;
                couverture et illustration du texte par Yvon Taillandier.
                <br />
                Exposition collective : « Picturographie » avec le photographe
                Pascal Houdart, galerie GNG, Paris.
              </p>
            </div>
          </article>
          <div className="bio-img left">
            <img src={images["peinture-73x60-1997.jpg"]} alt="" />
            <p>Peinture 73x60, 1997</p>
          </div>
          <article className="bio-para">
            <h4 className="bio-date">1996</h4>
            <div className="bio-text">
              <p>
                Expositions personnelles :
                <span className="bio-articles-list">
                  Musée Marcel Sahut à Volvic [catalogue écrit par Noël Nel et
                  illustré par Yvon Taillandier]
                  <br />
                  Galerie GNG, Paris
                </span>
              </p>
            </div>
          </article>
          <article className="bio-para">
            <h4 className="bio-date">1997</h4>
            <div className="bio-text">
              <p>
                Exposition collective :
                <span className="bio-articles-list">
                  Invité d'honneur au Salon d'art contemporain du château des
                  Bouillants, Dammarie-les-Lys
                </span>
              </p>
              <p>
                Exposition personnelle :
                <span className="bio-articles-list">Musée Adzak, Paris</span>
              </p>
            </div>
          </article>
          <article className="bio-para">
            <h4 className="bio-date">1998</h4>
            <div className="bio-text">
              <p>
                Expositions personnelles :
                <span className="bio-articles-list">
                  « Espace 1789 », Saint-Ouen
                  <br />
                  Galerie Selmersheim, Paris
                  <br />
                  Galerie GNG, Paris
                  <br />« Encadreurs/Encadrés » avec le photgraphe Michel
                  Dambrine, Galerie{" "}
                  <a
                    href="https://oeilduhuit.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    L'œil du huit
                  </a>
                  , Paris
                </span>
              </p>
              <p>
                Carte postale pour le 50e anniversaire de la Déclaration
                universelle des droits de l'homme, galerie L'art et la paix,
                Saint-Ouen.
                <br />
                Sérigraphie constituée d'un ensemble de maquettes de bandonéons,
                éditée par Del Arco, Paris. Exposée au Saga.
              </p>
            </div>
          </article>
          <div className="bio-img bio-img-big">
            <img src={images["bio-33.png"]} alt="" />
            <p>
              Tableau décorant ka camionnette de la galerie Sonia K à Lille (à
              gauche, Anne-Marie Gontier, à droite Patrick Boutillier,
              directeurs de la Galerie).
            </p>
          </div>
          <article className="bio-para">
            <h4 className="bio-date">1999</h4>
            <div className="bio-text">
              <p>
                Exposition collective :
                <span className="bio-articles-list">
                  « Noir et blanc », galerie Patrice-Alexis, Thonon-les-Bains
                  (Haute-Savoie)
                </span>
              </p>
              <p>
                Rencontre Jean-Claude Kervot à l'occasion de la première vente
                aux enchères de l'Association Robert Debré (Hôpital
                Robert-Debré, Paris) d'où naîtra une étroite collaboration
                artistique.
                <br />
                La{" "}
                <a
                  className="link"
                  href="https://www.youtube.com/watch?v=RUCfAcDg0wQ"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  fresque des façades des ateliers d'Yvon Taillandier
                </a>{" "}
                est signalée dans le guide de Rosine Klatzmann{" "}
                <span className="italic">Les carnets parisiens</span>. Elle est
                publiée en 2000 et 2001 dnas des catalogues de la société
                Virgin.
              </p>
            </div>
          </article>
          <article className="bio-para">
            <h4 className="bio-date">2000</h4>
            <div className="bio-text">
              <p>
                Rencontre importante avec Alain Benoit, éditeur de poésie à
                Rochefort-du-Gard, qui deviendra un ami proche autant qu'un
                défenseur actif et avisé de l'œuvre écrit et peint. Il travaille
                en outre au catalogue raisonné.
              </p>
              <p>
                Exposition personnelle :
                <span className="bio-articles-list">Galerie GNG, Paris</span>
              </p>
              <p>
                Exposition collective :
                <span className="bio-articles-list">
                  Avec Pierre Cornudet à la galerie A l'enseigne des Oudin,
                  Paris. « Jésus et l'humanité » avec la collaboration de Pierre
                  Cornudet, au siège du Parti communiste français, à Paris.
                  L'oeuvre est donnée à Gilbert Delaine pour le Centre d'art
                  sacré contemporain de Lille. Elle a été reproduite dans le
                  journal <span className="italic">L'humanité</span>.
                </span>
              </p>
              <p>
                Création d'enveloppes illustrées pour Pierre Stéphane Proust, en
                vue d'une exposition au musée de la Poste, Paris.
                <br />
                Parution d'un article de T. Demaubus dans la revue{" "}
                <span className="italic">Valeurs de l'art</span>; couverture du
                numéro.
              </p>
            </div>
          </article>
          <div className="bio-img">
            <img src={images["papier-50x64-2001.jpg"]} alt="" />
            <p>Papier 50x64, 2001</p>
          </div>
          <article className="bio-para">
            <h4 className="bio-date">2001</h4>
            <div className="bio-text">
              <p>
                Dessin « Labyrinthe intestinal et cycle vital » pour la 1ère et
                la 4e de couverture du{" "}
                <span className="italic">Petit digital illustré</span>.<br />
                Participation à la vente aux enchères organisée par Jean-Claude
                Kervot au profit des adolescents malades de l'hôpital
                Robert-Debré à Paris.
              </p>
              <p>
                Expositions collectives :
                <span className="bio-articles-list">
                  « La Commune de Paris a 130 ans » avec vingt peintres, à
                  l'Assemblée nationale, Paris
                  <br />« Peinture et poésie », exposition réalisée par François
                  Féret, tryptique sur la{" "}
                  <span className="italic">Prose du Transsibérien</span> de
                  Blaise Cendrars, Espace 1789, Saint-Ouen.
                </span>
              </p>
              <p>
                Illustration de l'article du quotidien{" "}
                <span className="italic">Le Monde</span> du 25 juin, « l'art
                contemporain pour tous se vend bien ».
              </p>
            </div>
          </article>
          <div className="bio-img bio-img-big left">
            <img src={images["bio-34.png"]} alt="" />
            <p>
              Signature de{" "}
              <span className="italic">
                La véritable histoire d'Alexandre-le-Grand
              </span>
              , 2002. (photo Michel Dambrine)
            </p>
          </div>
          <article className="bio-para">
            <h4 className="bio-date">2002</h4>
            <div className="bio-text">
              <p>
                Réalisation des carton d'invitation, affiche, tee-shirt et
                banderole de la « Fête du lilas » à Vitry.
              </p>
              <p>
                Expositions collectives :
                <span className="bio-articles-list">
                  « Une collection politique ? », organisée par le Fonds d'art
                  moderne et contemporain du Parti communiste français
                  [catalogue Alain Georges Leduc, Editions Cercle d'Art, Paris]
                  <br />
                  Galerie Ars in fine Pierre Duchamp, à Paris
                  <br />« Corps à Corps », choix d'estampes, au centre
                  Wallonie-Bruxelles, Paris
                </span>
              </p>
              <p>
                Illustration du tee-shirt de « La régate des oursons 2002 » de
                l'hôpital Robert-Debré, Paris.
                <br />
                Réalisation d'enveloppes illustrées pour Michel Bohbot, Paris.
                <br />
                Quatre sérigraphies pour le Mouvement de la paix, galerie L'art
                et la paix, Saint-Ouen.
                <br />
                Publication du texte illustré{" "}
                <span className="italic">
                  La véritable histoire d'Alexandre-le-Grand
                </span>
                , éditions Alain Lucien Benoït, Rochefort-du-Gard.
                <br />
                Reproduction d'une œuvre dans la revue Artension, n° 5, pour
                l'article de Jean-Claude Caire « Génération singulière ».
              </p>
            </div>
          </article>
          <article className="bio-para">
            <h4 className="bio-date">2003</h4>
            <div className="bio-text">
              <p>
                Exposition personnelle :
                <span className="bio-articles-list">
                  « La figuration libératrice », siège du journal L'Humanité,
                  Saint-Denis [catalogue], commmissaire d'exposition Christian
                  Lanoy.
                </span>
              </p>
              <p>
                Exposition collective :
                <span className="bio-articles-list">
                  « Le courrier s'expose », collection Michel Bohbot, à la
                  bibliothèque Louis Nucéra, Nice.
                </span>
              </p>
              <p>
                Invité d'honneur aux « XXVIe rencontre d'arts plastiques »,
                Château-Arnoux, Saint-Auban.
                <br />
                Affiche et participation à la vente aux enchères d'oeuvres d'art
                contemporain organisée par Jean-Claude Kervot au profit des
                enfants malades de l'hôpital Robert-Debré, Artcurial, Paris.
              </p>
            </div>
          </article>
          <div className="bio-img">
            <img src={images["bio-38.png"]} alt="" />
            <p>
              Autoportrait à la mine de plomb devant le miroir, octobre 2005
            </p>
          </div>
          <article className="bio-para">
            <h4 className="bio-date">2004</h4>
            <div className="bio-text">
              <p>
                Expositions personnelles :
                <span className="bio-articles-list">
                  Invité d'honneur à « Art-Metz » (présidente Marie-Louise
                  Rigaux), organisée par Noël Ne. Articles dans{" "}
                  <span className="italic">Artension</span> et{" "}
                  <span className="italic">Azart</span> (couverture).
                  <br />« L'amour la poésie », Maison Elsa Triolet-Louis Aragon,
                  Saint-Arnoult-en-Yvelines; rencontre Bernard Vasseur,
                  philosophe et homme de culture
                </span>
              </p>
              <p>
                Exposition collective :
                <span className="bio-articles-list">
                  « Une nuit d'art-ventures », organisée par Leila Voight, place
                  Saint-Sulpice, Paris
                </span>
              </p>
              <p>
                Publication de <span className="italic">Ouste</span>, texte
                d'Yvon Taillandier, dessins d'Antonio Seguí, aux éditions Alain
                Lucien Benoit, Rochefort-du-Gard.
                <br />
                Couverture du livre de poèmes de Matthieu Messagier,{" "}
                <span className="italic">L'universelle et l'idiot irrompu</span>
                , éditions Dumerchez, Paris.
                <br />
                Fresque-façade pour le local du Mouvement de la paix : commande
                de Christian Lanoy, Saint-Ouen.
              </p>
            </div>
          </article>
          <article className="bio-para">
            <h4 className="bio-date">2005</h4>
            <div className="bio-text">
              <p>
                Expositions collectives :
                <span className="bio-articles-list">
                  « Résister, c'est créer », pour les soixante ans de « Femmes
                  solidaires », Paris
                  <br />« Une nuit d'art-ventures » [réalisation du catalogue]
                  <br />« Cyrano de Bergerac », directeur de l'exposition
                  Frédéric Ballester; réalisation d'un rideau de scène (4 x 5
                  m), d'une maquette de scène et d'un mannequin grandeur nature
                  peint (avec la collaboration de la costumière de théâtre
                  Claude Barré), au musée de la Malmaison (Espace Miramar) à
                  Cannes.
                </span>
              </p>
              <p>
                Expositions personnelles :
                <span className="bio-articles-list">
                  Galerie 5, Toulouse, conception de l'affiche
                  <br />
                  Centre d'art contemporain de Bédarieux (Hérault), conception
                  de l'affiche
                </span>
              </p>
            </div>
          </article>
          <article className="bio-para">
            <h4 className="bio-date">2006</h4>
            <div className="bio-text">
              <p>
                Parution de De Démocrite à Claude Viallat, texte d'Yvon
                Taillandier, accompagnement pictural de Viallat, éditions Alain
                Lucien Benoit, Rochefort-du-Gard.
                <br />« Totem » : colonne de 7 mètres de haut en bois peint,
                pour l'hôpital Robert-Debré, Paris.
                <br />
                Aux paravents de carton réalisés dans les années 1970, ont
                succédé des paravents en bois; l'un fut exposé au Salon de Mai
                cette année.
                <br />
                Réalisation d'une commande de panneaux décoratifs (en
                collaboration avec Jean-Luc Juhel) pour la société
                Bouygues-Immobilier.
              </p>
              <p>
                Expositions personnelles :
                <span className="bio-articles-list">
                  Musée régional de l'Air, Angers, conception de l'affiche
                  <br />
                  Menin, Belgique
                </span>
              </p>
              <p>
                Expositions collectives :
                <span className="bio-articles-list">
                  « Michel Hénocq-Yvon Taillandier », galerie Argument, Esvres
                  (Indres-et-Loire)
                  <br />
                  Caixa Banque de Dijon, en collaboration avec Jean-Luc Juhel
                </span>
              </p>
            </div>
          </article>
          <article className="bio-imgs-line">
            <div className="bio-img">
              <img src={images["bio-35.png"]} alt="" />
              <p>
                Portrait du joyeux octogénaire par Antoine Stéphani, le 1er
                avril 2006, fête sdes quatre-vingts ans d'Yvon Taillandier à son
                atelier.
              </p>
            </div>
            <div className="bio-img">
              <img src={images["bio-36.png"]} alt="" />
              <p>
                Jean-Pierre Raynaud, François Taillandier, Alain Lucien Benoît
                et Yvon Taillandier, le 1er avril 2006, jour de la fête
                d'anniversaire de ses quatre-vingts ans. (photo M. M. K.)
              </p>
            </div>
            <div className="bio-img">
              <img src={images["bio-37.png"]} alt="" />
              <p>
                François et Yvon Taillandier, le 1er avril 2006. (photo M. M.
                K.)
              </p>
            </div>
          </article>
          <div className="bio-img">
            <img
              src={images["affiche-kiev-2008.jpg"]}
              alt="Affiche de l'exposition de Kiev, 2008"
            />
            <p>Affiche de l'exposition de Kiev, 2008</p>
          </div>
          <article className="bio-para">
            <h4 className="bio-date">2008</h4>
            <div className="bio-text">
              <p>
                Expositions personnelles :
                <span className="bio-articles-list">
                  Expositions en Ukraine : Trois grandes expositions des œuvres
                  d’Yvon Taillandier ont été organisées en 2008, dans les Villes
                  de Kiev, d’Odessa et de Lviv, avec la participation du Musée
                  National des Beaux-Arts de Kiev, l’Alliance Française d’Odessa
                  et l’Ambassade de France en Ukraine.
                  <br />
                  Musée National des Beaux-Arts d'Ukraine à Kiev – avril et Mai
                  2008 - Un tableau d'Yvon Taillandier est entré au Musée
                  National des Beaux-Arts de Kiev, en souvenir de sa venue.
                  <br />
                  Galerie Maritime d'Odessa - octobre et novembre 2008.
                  <br />
                  Lviv – septembre à décembre 2008
                  <br />
                  Maison des arts de Chatillon sur Seine - novembre – œuvres sur
                  toiles et de dessins de jeunesse exposés à l’âge de 16 ans à
                  Lyon.
                  <br />
                  Exposition à l’Espace Sextius – Aix en Provence
                </span>
              </p>
            </div>
          </article>
          <article className="bio-para">
            <h4 className="bio-date">2009</h4>
            <div className="bio-text">
              <p>
                Expositions personnelles :
                <span className="bio-articles-list">
                  APACC Montreuil, association d’art contemporain 19 rue Carnot
                  – 93100 Montreuil – Octobre.
                  <br />
                  PULS'ART 2009 - Centre Paul Courboulay, le MANS - Exposition
                  Yvon Taillandier, comportant notamment les trois grandes
                  toiles : "Hommage à Jacqueline SELZ", "l'Origine du Sang" et
                  "Chaine de têtes" (140x248).
                </span>
              </p>
              <p>
                Exposition collective :
                <span className="bio-articles-list">
                  LE GRAND BAZ'ART A BEZU, festival International d'Art
                  Singulier - 25 artistes provenant de 12 nations exposent à
                  BEZU avec le soutien du Ministère de la Culture - Mai - Yvon
                  TAILLANDIER, invité d’honneur de ce festival.
                </span>
              </p>
            </div>
          </article>
          <div className="bio-img bio-img-big left">
            <img
              src={images["carton-valreas-2010.jpg"]}
              alt="Carton peint, Valréas, 2010"
            />
            <p>Carton peint, Valréas, 2010</p>
          </div>
          <article className="bio-para">
            <h4 className="bio-date">2010</h4>
            <div className="bio-text">
              <p>
                Exposition personnelle :
                <span className="bio-articles-list">
                  Valréas (Vaucluse), Salon de l'Enclave - Chateau de Simiane –
                  Juillet et août - Yvon Taillandier a présenté à cette occasion
                  son œuvre : la "bâche de Kiev" exposée au musée d'Art Moderne
                  de Kiev en 2009.
                </span>
              </p>
              <p>
                Exposition collective :
                <span className="bio-articles-list">
                  Barbizon, espace culturel Marc Jacquet – Mai à Juillet -
                  Hommage à l’occasion des 150 ans du tableau de Millet «
                  l’Angelus » ; un groupe de 150 artistes réinterprètent J.F.
                  Millet avec la participation d'Yvon Taillandier.
                  <br />
                  Exposition « Les papillons de Carpentras » - juin.
                </span>
              </p>
            </div>
          </article>
          <div className="bio-img">
            <img
              src={images["affiche-mont-de-marsan-2012.jpg"]}
              alt="Affiche de l'exposition de Mont de Marsan, 2012"
            />
            <p>Affiche de l'exposition à Mont de Marsan, 2012</p>
          </div>
          <article className="bio-para">
            <h4 className="bio-date">2011</h4>
            <div className="bio-text">
              <p>
                Exposition personnelle :
                <span className="bio-articles-list">
                  Rétrospective - Mairie du IXe arrondissement de Paris, grande
                  salle du Conseil – Mai - organisée en souvenir des quarante
                  années où Yvon Taillandier a résidé 8, rue de l'agent Bailly,
                  au coeur du 9e, où il avait son atelier. C'est dans cet
                  atelier que l'essentiel de sa "production" de peintre a été
                  réalisée.
                  <br />
                  Le dimanche 22 Mai, lors de la fête du quartier
                  Lorette-Martyrs, organisée par le Conseil de quartier, Yvon
                  Taillandier a présenté aux visiteurs, devant son ancien
                  atelier, sa manière de dessiner et de peindre.
                </span>
              </p>
            </div>
          </article>
          <article className="bio-para">
            <h4 className="bio-date">2012</h4>
            <div className="bio-text">
              <p>
                Exposition personnelle :
                <span className="bio-articles-list">
                  Rétrospective au Centre d'Art Contemporain Raymond Farbos à
                  Mont de Marsan - février et mars. Présentation d’un ensemble
                  d'oeuvres couvrant la "carrière" du peintre, de l'écrivain, du
                  critique d'Art, ainsi que ses derniers dessins.
                </span>
              </p>
            </div>
          </article>
          <article className="bio-para">
            <h4 className="bio-date">2013</h4>
            <div className="bio-text">
              <p>
                Exposition collective :
                <span className="bio-articles-list">
                  Yvon Taillandier est l’invité du Festival a-part, d’art
                  contemporain des Alpilles, qui se tient chaque année au mois
                  de juillet à Saint-Rémy-de-Provence. Des rencontres sont
                  organisées au cours desquelles les artistes, les partenaires,
                  les collectionneurs et les visiteurs échangent sur leurs
                  démarches artistiques.
                </span>
              </p>
            </div>
          </article>
          <div className="bio-img">
            <img
              src={images["oeuvre-galerie-gng-2015.jpg"]}
              alt="Oeuvre exposée à la Galerie GNG, 2015"
            />
            <p>Oeuvre exposée à la Galerie GNG, 2015</p>
          </div>
          <div className="bio-img">
            <img
              src={images["carton-gng-2015.jpg"]}
              alt="Carton peint, Galerie GNG, 2015"
            />
            <p>Carton peint, Galerie GNG, 2015</p>
          </div>
          <article className="bio-para">
            <h4 className="bio-date">2014</h4>
            <div className="bio-text">
              <p>
                Exposition collective :
                <span className="bio-articles-list">
                  Festival a-part d’art contemporain des Alpilles, au mois de
                  juillet à Saint-Rémy-de-Provence. Participation d’Yvon
                  Taillandier pour la deuxième année consécutive.
                </span>
              </p>
            </div>
          </article>
          <article className="bio-para">
            <h4 className="bio-date">2015</h4>
            <div className="bio-text">
              <p>
                Expositions personnelles :
                <span className="bio-articles-list">
                  Yvon Taillandier – cartons peints réalisés entre 1970 et 1980
                  – Galerie GNG Paris (6è).
                  <br />
                  Yvon Taillandier – Œuvres sur toiles et la « bâche de Kiev » –
                  Cloître Saint Louis à Avignon.
                </span>
              </p>
            </div>
          </article>
          <div className="bio-img bio-img-big">
            <img
              src={images["carton-oeilduhuit-2016.jpg"]}
              alt="Carton peint, Galerie L'Oeil du Huit, 2016"
            />
            <p>Carton peint, Galerie L'Oeil du Huit, 2016</p>
          </div>
          <article className="bio-para">
            <h4 className="bio-date">2016</h4>
            <div className="bio-text">
              <p>
                Lancement du projet de conservation-restauration de «
                l’Ambassade du Taillandier-land », peinture murale monumentale
                située sur la façade de l’ancien atelier de l’artiste, 8 rue de
                l’Agent Bailly à Paris (9e). A l’initiative de l’association
                L’Oeil du Huit - Appel à financement participatif sur le site «
                Dartagnan ». Succès de l’opération qui permet de réaliser
                l’étude préalable à la restauration, par une
                conservatrice-restauratrice en œuvres d’art.
              </p>
              <p>
                Expositions personnelles :
                <span className="bio-articles-list">
                  Exposition de soutien à « la restauration de la façade de
                  l’Ambassade du Taillandier-land » - Dessins réalisés en 2013 à
                  Avignon et collecte de dons pour contribuer au financement de
                  la restauration – Galerie associative L’œil du Huit, Paris
                  (9e) - mars.
                  <br />
                  Rétrospective - « Soutien à la restauration de la façade de «
                  l’Ambassade du Taillandier-land » – Toiles, dessins,
                  sérigraphies, mobiliers – Collecte de dons pour la
                  restauration – Galerie associative l’Oeil du Huit, Paris (9e)
                  - novembre.
                </span>
              </p>
            </div>
          </article>
          <div className="bio-img fullwidth">
            <img
              src={images["lithographie-restauration-2016.jpg"]}
              alt="Lithographie de la restauration de la peinture murale de l'Ambassade du Taillandier-land, 2016"
            />
            <p>
              Lithographie de la restauration de la peinture murale de
              l'Ambassade du Taillandier-land, 2016
            </p>
          </div>
          <div className="bio-img">
            <img
              src={images["pot-abbayestandre-2017.jpg"]}
              alt="Pot, Abbaye Saint André, 2017"
            />
            <p>Pot, Abbaye Saint André, 2017</p>
          </div>
          <article className="bio-para">
            <h4 className="bio-date">2017</h4>
            <div className="bio-text">
              <p>
                Exposition collective :
                <span className="bio-articles-list">
                  Yvon Taillandier invité d’Honneur du Salon d’Automne 2017 –
                  Avenue des Champs Elysées à Paris (8è) - exposition de neuf
                  grandes toiles.
                </span>
              </p>
              <p>
                Expositions personnelles :
                <span className="bio-articles-list">
                  Exposition « Yvon Taillandier – peintures fraiches » -
                  Atelier-Musée d’art brut à Montpellier.
                  <br />
                  Rétrospective à l’Abbaye de Saint-André à Villeneuve les
                  Avignon : reconstitution de l’atelier que l’artiste avait à
                  Paris (9e) et exposition d’une oeuvre monumentale réalisée en
                  1974 pour la galerie l’Oeil de Bœuf à Paris (rouleau de carton
                  peint de plus de 20m de long).
                  <br />« Quartier d’été » à la galerie associative l’Oeil du
                  huit à Paris (9e) – dessins et toiles – collecte de dons en
                  soutien à la restauration de « l’Ambassade du Taillandier-land
                  ».
                  <br />
                  Exposition à la Galerie « Espace d’un instant » à Paris (4e) –
                  toiles - septembre 2017 à septembre 2018.
                </span>
              </p>
            </div>
          </article>
          <article className="bio-para">
            <h4 className="bio-date">2018</h4>
            <div className="bio-text">
              <p>
                Décès d’Yvon Taillandier à Avignon le 3 mars 2018.
                <br />
                Dernière phase du chantier de conservation-restauration de «
                l’Ambassade du Taillandier-land », peinture murale monumentale
                située sur la façade de l’immeuble 8, rue de l’Agent Bailly à
                Paris (9e) – septembre et octobre.
                <br />
                Réalisation d’une vidéo sur la restauration de « l’Ambassade du
                Taillandier-land » avec interview d’une
                restauratrice-conservatrice (https://yvon-taillandier.com) -
                décembre 2018.
                <br />
                Parution du Hors série n° 840 de Connaissance des arts (68 p) :
                « Hommage à Yvon Taillandier précurseur de la figuration libre »
                - décembre 2018.
              </p>
              <p>
                Expositions collectives :
                <span className="bio-articles-list">
                  Biennale 109 à Paris (11e).
                  <br />
                  Hommage à Yvon Taillandier - Salon d’Automne 2018, Avenue des
                  Champs Elysées à Paris (8e).
                </span>
              </p>
              <p>
                Expositions personnelles :
                <span className="bio-articles-list">
                  « J’écris ton nom : Taillandier » Hommage - Galerie GNG ,
                  Paris (6e) - cartons peints et dessins - Mai.
                  <br />
                  Yvon Taillandier : Hommage « Les encadreurs encadrés » -
                  dessins – Galerie associative L’œil du Huit Paris (9e) - Juin.
                  <br />« Quartier d’été » à la galerie associative l’Oeil du
                  huit à Paris (9e) – dessins et toiles – collecte de dons en
                  soutien à la restauration de « l’Ambassade du Taillandier-land
                  ».
                </span>
              </p>
            </div>
          </article>
          <div className="bio-img fullwidth">
            <img
              src={images["hommage-yt-2018.jpg"]}
              alt="Hommage - 2018 - Yvon Taillandier devant son atelier du 8 rue de l'Agent Bailly, Paris 9ème"
            />
            <p>
              Yvon Taillandier devant son atelier du 8 rue de l'Agent Bailly,
              Paris 9ème.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Biography;
