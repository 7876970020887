import React from 'react';
import { Link } from "react-router-dom";
import Footer from './Footer';
import yt69 from '../img/yt-69.svg';
import yt67 from '../img/yt-67.svg';
import yt68 from '../img/yt-68.svg';
import yt219 from '../img/yt-219.svg';
import yt47 from '../img/yt-47.svg';
import yt218 from '../img/yt-218.svg';
import yt2001 from '../img/yt-2001.jpg';
import yt2006 from '../img/yt-congespayes-2006-114x146.jpg';


const LePeintre = props => (
  <div className="page">
    <div className="container">
      <div className="le-peintre">
        <h1>Le peintre</h1>
        <div className="atelier">
          <div>
            <strong>Yvon Taillandier dans son atelier</strong>
            <p>
              A partir de 1970, Yvon Taillandier se consacre entièrement à la peinture, il s'installe dans l'atelier
              d'un ancien menuisier au pued de la butte Montmartre, 8 rue de l'Agent Bailly dans le 9ème arrondissement
              à Paris.
            </p>
            <p>Découvrez en vidéo Yvon Taillandier dans son atelier.</p>
            <p>
              Sur la devanture de cet atelier, alors recouverte de tags et de graffitis, Yvon Taillandier réalise
              cette même année sur  plus de 15 mètres de long une peinture murale qu'il intitule : « L'Ambassade
              du Taillandier-land ».
            </p>
          </div>
          <iframe title="Yvon Taillandier dans son atelier" width="560" height="415" src="https://www.youtube-nocookie.com/embed/RUCfAcDg0wQ" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"></iframe>
        </div>
        <p>
          Exposée depuis plus de 40 ans en extérieur, cette oeuvre monumentale a subi de fortes dégradations.
          Une association de quartier, <a className="link" href="https://oeilduhuit.com/" target="_blank" rel="noopener noreferrer">L'Oeil du Huit</a> a
          pris l'initiative de la préserver sur financement participatif. Le travail minutieux de conservation et de
          restauration a été réalisé par Françoise Joseph, David Cueco, Rémi Rabu et Marie-Laure Martiny, redonnant
          à l'oeuvre sa fraîcheur d'origine.
          <br />Cette peinture murale est un spectacle quotidien pour les passants et habitants de la rue de l'Agent Bailly,
          un incontournable pour les touristes et promeneurs en quête du Paris insolite.
          <br />Découvrez sur ce site <Link className="link" to="/oeuvres/">les photos de l'oeuvre</Link> et <Link className="link" to="actualites">la vidéo de sa restauration</Link>.
        </p>

        <div className="img-center"><img src={yt69} alt="" /></div>

        <h2>Mes tableaux se veulent des chants joyeux, voire des hymnes à la joie</h2>
        <p>
          Je peins pour faire partager aux autres le plaisir que j’éprouve à la simple action de regarder et de voir.
          Comme mon ami Giacometti, je me suis aperçu que le travail qui consiste à créer une image, peinte, sculptée
          ou dessinée, améliorait considérablement la faculté de vision.
          <br />Telle est une des raisons pour lesquelles je m’adonne à la peinture. Une autre raison est qu’elle me
          permet de satisfaire la passion que j’éprouve pour le dessin.
          <br />La couleur, qu’on oppose souvent au dessin, m’est longtemps apparue comme cette chose que Gauguin
          qualifiait de mystérieuse et qu’il fallait, disait-il, utiliser mystérieusement ! Toutefois, je ne partage
          plus cette opinion. Je me contente de constater que la couleur solidifie les lignes qui, sans elles, sont
          d’une extrême fragilité. Or, le graphisme me permet de donner forme à des personnages que j’invente et que
          je qualifie de progressifs. Très simples à certains endroits, ils se compliquent et s’élargissent jusqu’à
          occuper et animer toute la surface de la toile, n’y laissant que des vides très réduits.
          <br />J’ai opté pour des couleurs simples, pure et gaies, parce que mes tableaux, si chargés qu’ils semblent
          parfois, refusent de paraître lourds et se veulent des chants joyeux, voire des hymnes à la joie. Je voudrais
          qu’ils disent aux spectateurs « Nous vous aimons, et la vie que nous vous montrons mérite d’être vécue avec
          ingéniosité et allégresse ».
        </p>
        <p className="italic align-right">Miroir de l’art, n° 90</p>

        <div className="img-center"><img src={yt67} alt="" /></div>

        <h2>Yvon Taillandier : Trafic de Jouvence</h2>
        <p>
          Né à Paris, il vécut sa prime jeunesse à côté de Lyon jusqu’à l’âge de 19 ans où grâce à des rencontres heureuses il fut encouragé. à dessiner. Puis ensuite à Paris, il s’exerça durant une longue période comme critique d’art, période durant laquelle il rencontre de nombreux artistes qui marquèrent l’art contemporain. Essayiste, poëte, l'écriture fut toujours présente même dans de nombreux tableaux parcourus de commentaires éclairants.
        </p>
        <p>
          Secrétaire du Comité du Salon de Mai pendant 44 ans, il participa à d’importants évènements artistiques. Depuis 1970, il se remit vraiment à dessiner en employant le feutre sur papier et l’acrylique sur toile pour produite une œuvre conséquente (..).
        </p>
        <p>
          La mouvance allègre et labyrinthique dans le surplus des choses vues, est particulièrement remarquable dans l’œuvre d’Yvon Taillandier. Elle détonne singuliè- rement par la grande délirante de ses exposés, et, par la frénésie de l’abouchement, elle entonne le vaste chant de l’hypercommunication en déclenchant le grand tintamarre des cuivres tonitruants annonçant l'avènement inoubliable d’un miracle accompli. Car dans l’espace inclusif que l’artiste s'impose où personnages et objets se dupliquent, s’encastrent, s’aboutent sur un rythme endiablé, une cohésion hermétique éclate au grand jour : elle est fermée dans un système entièrement clos et ouverte dans son expression de fête avérée. Le contenant et le contenu fusionnent, s’assimilent l’un l’autre pour produire une cohérence inattendue et heureuse.
        </p>
        <p>
          Mais au-delà de l’enclos figuré, étendu à la relative infinitude de la nature humaine, le principe de l’inter-communication à l’ère de la mondialisation est devenu naturellement une évidence et que l'artiste (..) le mette tout aussi naturellement en exergue montre assez que toute vraie création inspirée par le monde qui la procrée porte l’entaille de la greffe d’une vision pénétrante. Tout est dans tout et versé dans le quotidien le moins que rien fluctue avec le plus que tout et inversement…permanence devenue tangible et qu’Yvon Taillandier, en a fait depuis belle lurette sa seconde nature mise en images et reliées entre-elles par le cordon subliminal de l’interdépendance.
        </p>
        <p>
          Tous les tableaux de l’artiste se déclinent sur le mode du parcours narratif concentré, cadré à la manière d’un spot dont la magie n’est pas de faussement convaincre à la manière de la publicité, mais d’exposer un imaginaire délirant qui s’interconnecte dans un espace défini et renvoie indéfiniment aux reliefs multiples de l'abondance. l'exposition de sa trame narrative est toujours une fête où pullule une énergie galopante à travers une fiction prenant la tournure d’une fable sans le moralisme habituel à ce genre. L’organique interfère avec l’inorganique ; le corps social à l'exemple du corps humain, impulse et expulse, ingurgite et dégurgite généralement des scènes de liesse ; tout passe par le transit interactif de la communication. Le moindre objet est relié à l’ensemble et participe activement à celui-ci.
        </p>
        <p>
          Hormis les sujets traités proprement dits, tous les états d’ame inhérents à une nature trop humaine sont présents dans I’oeuvre de I’artiste : l’humour à travers une fantaisie délirante où percent une ironie frondeuse et une cruauté ingénue ; l’érotisme tapageur grand avaleur d’un désir éternellement insatisfait ; le défilement d’un conditionnement des individus lourd et abêtissant, mais bien sûr de la tendresse, de la joie et de par un entremêlement fusionnel : de l’amour, neutralisé parfois par son contraire  : la haine, qui se signale par la séparation, I’abandon, la défection ... Dans sa jeunesse l’artiste mentionne aux divinités contraires représentatives de l’amour et de la haine. Mythologie prégnante ayant pu décliner une suite de métaphores obsédantes concourant à ce dynamisme mû par ce jeu des opposés et source de cette trame fantastique.
        </p>
        <p>
          L’artiste se définit Iui-même plus comme un dessinateur que comme un peintre même qu’il manipule la couleur depuis près d’une quarantaine d’années grâce à l’influence d’un Edouard Pignon, en élargissant  sa gamme par rapport au noir et blanc par ses contrastes nuancés, ses lignes imaginaires, ses valorisations des couleurs entre-elles, ses effets de perspective, de détachement de scénes se superposant, d’accentuation des rythmes, de mise en résonance par la répétition de couleurs dissonantes ... Tumulte enchanteur, bonheur affairé, engorgement d’illusions nécessaires, la couleur multiplie des gerbes d’éclats par rapport à cette seule tonalité du noir sur fond blanc où, compte tenu du graphisme compacte de l’artiste, elle déploie un effet  esthétique,  un parfum d’élégance et de gravité.
        </p>
        <p>
          Au summum de son art, I’oeuvre d’Yvon Taillandier, se grandit elle-méme par l’apport constant d’un renouvellement subtil, nourrie de thèmes, de formes et de supports nouveaux, tout en gardant son essence première, indélébile, un coeur rayonnant dans sa compacité, un style forgé à son enseigne, unique en sa demeure et identifiable a lieues.
        </p>
        <p className="italic align-right">MJ ESTRADE<br />Avril 2OO8</p>

        <div className="img-center"><img src={yt67} alt="" /></div>

        <h2>Regard sur l'oeuvre d'Yvon Taillandier aujourd'hui</h2>
        <p>
          Le monde imaginaire d’Yvon Taillandier nous est-il si éloigné ? qu’évoquent pour nous aujourd’hui ces étonnants personnages « aux organes multipliés, polycéphales, polybrachiens, polytroncs ou polypèdes » ?
          <br />
          Le Taillandier-land est-il une nation invraisemblable peuplée de Taillandier-landais(es) à l’anatomie folle, s’activant sur d’étranges machines, ou de manière fusionnelle dans des « cycles vitaux », et tous reliés entre eux par des tubes qui seraient de simples lignes artificielles ?
        </p>
        <p>
          Yvon Taillandier s’est souvent exprimé sur l’élaboration de son lexique figuratif. Mais qu’elle intention artistique sous-tend l’œuvre de ce précurseur de la figuration libre, dont la peinture, figurative et narrative, donne autant à voir qu’à lire ?
        </p>
        <blockquote>
          J’ai constaté que les tableaux sont vus et lus, que ces deux opérations sont contradictoires et qu’il s’agit de les concilier.
        </blockquote>
        <p>
          Selon Yvon Taillandier, « l’œuvre d’art (..) doit posséder deux caractères opposés : elle doit gêner et elle doit plaire. Gêner pour attirer l’attention (..) par le contraste, voire la disharmonie, puis plaire par les qualités inverses », telles que l’harmonie et la douceur ».
          <br />
          Ces caractères opposés se retrouvent dans la façon dont il utilise les moyens picturaux que sont la ligne et les couleurs, faisant ainsi se côtoyer le stable et l'instable, l'équilibre et le chaos.
        </p>
        <div className="img-left"><img src={yt219} alt="" /></div>
        <p>
          La force de la ligne s’impose dans son œuvre. Elle se veut au premier abord rassurante, soulignant les formes qui confortent l'existence des corps. « Toute ligne est un chemin (..), l’on peut y voir quelque chose qui lie, qui contribue à l’unité ».
          <br />
          L’importance de la ligne s’est manifestée très tôt dans l’œuvre d’Yvon Taillandier. Elle était déjà présente dans les premiers portraits et natures mortes qu’il a exposés à l’âge de seize ans. La douceur de ses dessins suscite de l’empathie, de la tendresse envers ses personnages qui inspirent « un sentiment d’ingéniosité, voire d’astuce et de délectation ».
        </p>
        <p>
          Certains décalages et « dissonances » sont néanmoins introduits, notamment par l’utilisation de l’oblique car « l’image doit s’opposer au contour de son support pour qu’elle soit intéressante ». « Le nombre des positions obliques est infini », à l’inverse des orthogonales, et crée des instabilités.
          <br />
          « C’est pourquoi je m’inquiète beaucoup de ménager les obliques dans mes images en faisant agir les jambes, les bras de mes Taillandier-landais, ou en leur donnant deux corps attachés à la même tête, l’un tout droit, l’autre penché sur le premier, mais bien ostensiblement penché ».
          <br />
          L’usage de la perspective inversée (« les lignes se rapprochent au premier plan alors qu’elles s’écartent en allant vers le fond ») ainsi que la juxtaposition de différents points de vue (« on voit en même temps l’avant, l’arrière et le profil »), créent eux aussi des ambiguïtés propres à déstabiliser et provoquer le chaos.
        </p>
        <p>
          De même, la multiplication des motifs (chaîne de têtes, de membres…) introduit à la fois la disharmonie et le mouvement. Elle symbolise « le temps qui passe et que nous subissons ». « La peinture est un moyen d’annuler le temps et d’avoir l’illusion de vivre dans l’éternité ».
          <br />
          La répétition des motifs permet également « d’apparenter la peinture à un récit ». Pour mieux matérialiser ce rapport entre la peinture et l’écrit, Yvon Taillandier introduit des textes dans nombre de ses œuvres.
          <br />
          Ainsi, que ce soit par le dessin ou par le texte, la ligne, dont la présence se veut a priori rassurante, devient une sorte de délire graphique.
        </p>
        <div className="img-right"><img src={yt68} alt="" /></div>
        <p>
          Vient ensuite le rôle de la couleur, sujet dont l’artiste s'est souvent entretenu avec Giacometti. Yvon Taillandier use de la teinte en aplat qui permet d’exalter l’identité des formes, car elle est inerte et s’oppose aux tensions et à la suractivité des personnages.
          <br />
          « Dans un aplat, la couleur est partout pareille ; (..) il semble que la surface qu’elle recouvre soit éclairée partout avec la même intensité. (..) l’aplat exclut l’impression de profondeur, l’impression du volume ».
          <br />
          Ces aplats sont cependant nuancés par l’introduction de transparences qui leur donne du rythme, l’intensité des teintes variant selon le tracé du pinceau.
        </p>
        <p>
          Le choix de l’acrylique pour ses toiles et du feutre pour ses dessins, est aussi un signe de stabilité. A la différence de la peinture à l’huile, on sait d’avance comment la peinture acrylique ou le feutre se comportera. Ainsi le rôle de la couleur est de susciter l’enchantement par le délire décoratif.
        </p>
        <p>
          Cependant, la juxtaposition de couleurs primaires et complémentaires génère une intensité presque violente qui renforce la sensation de chaos. Par leur densité elles saturent la totalité du support (« Je mets le plus de choses possibles dans le plus petit espace possible comme les primitifs »), et deviennent, par leur répétition dissonante, à peine supportables.
        </p>
        <p>
          Par le traitement de la ligne et de la couleur, Yvon Taillandier laisse entendre que l’harmonie et le chaos, le stable et l’instable, et donc l’imprévu, président au cours des choses, mais aussi que l’acte créateur lui-même génère de la disharmonie.
        </p>
        <p>
          C’est à l’aune de ces choix picturaux qu’Yvon Taillandier a élaboré son propre lexique figuratif. Il a voulu représenter non pas des formes existantes (« elles auraient trop de vraisemblance et de proximité », les modèles « seraient monstrueux et insupportables »), mais des formes imagées. Son parti pris délibérément figuratif n’exprime pas une attitude nostalgique face à l’essor de l’abstraction ou un refus de ce courant dominant – il admirait trop d’ailleurs les œuvres de peintres abstraits tels que Poliakoff, Manessier, Lanskoy, Miro, qui étaient ses amis.
        </p>
        <div className="img-left"><img src={yt218} alt="" /></div>
        <p>
          Sa mythologie, le « Taillandier-land » est peuplée d’extravagants personnages « polymorphes » aux membres démultipliés : centaures, horizontalistes, altistes, polycéphales, capitipèdes, céphalo-tronc, Parabécos…
          <br />
          Il introduit notamment dans ce pays imaginaire des automobiles, des avions, des vélos et toutes sortes d’étonnantes machines avec lesquelles les Taillandier-landais entretiennent un rapport quasi fusionnel.
        </p>
        <p>
          Cependant, avec ironie, Yvon Taillandier les présente s'activant sur des engins aux rouages défiant toute logique et dont la performance réside dans leur inutilité. Les Taillandier-landais sont ainsi les acteurs d'une facétie qui constitue leur quotidien et d'une certaine manière le nôtre.
          <br />
          Yvon Taillandier prend ainsi le contre-pied du positivisme dominant concernant le rôle des machines, en montrant qu’elles peuvent se retourner contre leur utilisateur. Plus généralement, il met en évidence ce qu’il y a d’éphémère dans la technologie.
        </p>
        <p>
          Le regard satyrique qu’il porte sur le monde se retrouve également dans les « cycles vitaux », où les Taillandier-landais s’entremêlent de manière fébrile dans des rapports improductifs, mais qui sont révélateur d’une « éternelle insatisfaction » (MJ Estrade - 2008).
          <br />
          Quant aux tubes reliant les personnages du Taillandier-Land, « loin de diviser, [ils] unissent et rassemblent ».
          <br />
          C’est à un monde connecté que nous renvoie l’artiste, vision prémonitoire d’un univers peuplé d’internautes avant l’heure, reliés entre eux sur la toile, au sens propre comme au sens figuré.
          <br />
          De cette connexion, piétons, cyclistes, automobilistes et autres aviateurs tirent leur énergie vitale, leur activité, leur ingéniosité.
        </p>
        <div className="img-right"><img src={yt2001} alt="" /></div>
        <p>
          Le lien avec notre monde en crise et en quête de sens devient une évidence. Le maelstrom de la mondialisation est une période de ruptures, sur les plans économique, politique et sociale, scientifique et également artistique. Les valeurs héritées du rationalisme des lumières sont remises en cause. L’aléatoire et l’imprévu se sont imposés, y compris dans le domaine de l’art.
          <br />
          Mais c’est aussi une période prolifique en recherches, réflexions, projets sociétaux et environnementaux, creuset de la révolution scientifique, technologique et numérique qui façonne aujourd’hui notre quotidien.
          <br />
          Dans ce contexte, l’art se fait le reflet du désarroi de l’individu cherchant à se définir et se représenter, mais aussi de la recherche d'une identité et de sens.
        </p>
        <p>
          L’œuvre d’Yvon Taillandier est révélatrice de la complexité qui s’est imposée dans notre univers, mais aussi de ce qui peut être fécond dans l’instabilité et les bouleversements qu’il connaît.
          <br />
          C’est pourquoi son oeuvre renvoie à une perspective, individuelle et collective. Elle s'affirme notamment dans le morcellement du corps et le « pouvoir associateur » des tubes.
        </p>
        <p>
          Le thème de la génération – de la genèse, pourrait-on dire – du corps est essentiel : ce dernier naît d’« une boule abdominale, à laquelle s’en ajoute une autre, thoracique celle-là, puis une troisième qui est céphalique », pour devenir un « céphalo-tronc duquel émergera une puis deux jambes ». Ce corps évolutif est le symbole d’un être en devenir, à la recherche de son essence, de son identité.
        </p>
        <div className="img-left"><img src={yt2006} alt="" /></div>
        <p>
          Par ailleurs, les tubes « au pouvoir associateur » qui fédèrent les Taillandier-landais(e) sont les instruments d’une prise de conscience de la solidarité qui lie et unit les êtres humains entre eux. C’est ainsi une perspective profondément humaniste et positive qui se dégage de l’œuvre d’Yvon Taillandier, et non pas la vision utopique d’un « antimonde » intérieur,  ni d’un monde fantastique.
        </p>
        <p>
          Cet humanisme se double de convictions nettement affirmées : ses œuvres réalisées contre l’apartheid, pour la libération de Nelson Mandela, en soutien au mouvement pour la paix, ou aux femmes, en faveur des droits de l’homme, au profit des enfants malades de l’Hôpital Robert Debré, etc témoignent de l’engagement résolu de l’artiste.
        </p>
        <p>
          Mais ce qui donne à l’œuvre d’Yvon Taillandier sa véritable envergure réside dans la dimension mythique qu’il confère à ses personnages. Le mythe guide leur action, crée un sens commun et sollicite la connivence du spectateur.
          <br />
          Par l’usage du mythe, Yvon Taillandier se détache du fétichisme des formes, sort du carcan de la représentation figurative « classique » et propose, sous le prisme du merveilleux, du poétique et de l’ironie, une approche visionnaire de notre société, à la fois critique et porteuse d’espérance.
        </p>
        <p>
          Au spectateur de ressentir et d’interpréter, par la liberté qui lui est offerte, la puissance prospective et créatrice des oeuvres d’Yvon Taillandier, caractéristique de la figuration libre.
        </p>
        <p className="italic align-right">Laurent CHABAS<br />Paris, le 26 novembre 2018</p>
        <div className="img-center"><img src={yt47} alt="" /></div>
      </div>
    </div>
    <Footer />
  </div>
);

export default LePeintre;
