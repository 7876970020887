import React from "react";
import Footer from "./Footer";
import img1 from "../img/yt-70.svg";
import img2 from "../img/yt-58.svg";
import img3 from "../img/yt-52.svg";
import img4 from "../img/yt-56.svg";
import img5 from "../img/yt-10.png";

const FigurationLib = props => (
  <div className="page">
    <div className="container">
      <div className="figuration">
        <h1>La figuration libératrice et altruiste</h1>
        <p>
          Pour moi, les origines de la figuration libératrice résident dans mes
          réflexions sur l'art de voir les tableaux, réflexions dont j'ai publié
          une partie dans un petit livre intitulé
          <span className="italic"> Le Voyage de l'oeil</span>, paru en 1961, et
          qui m'a demandé douze ans de travail. En résumé, j'ai constaté que les
          tableaux sont vus et lus, que ces deux opérations sont contradictoires
          et qu'il s'agit de les concilier.
        </p>
        <p>
          En suivant l'enseignement d'Edouard Pignon, je dirais que les tableaux
          sont constitués de deux éléments : une grande forme et des détails. On
          voit la grande forme, on lit les détails : cette lecture peut être
          infinie, en revanche, la vision de la grande forme est immédiate. On
          se contente souvent de celle-ci, c'est un tort. Il faut la compléter
          d'une lecture pendant laquelle, d'ailleurs, continue, mais comme un
          accompagnement, la vision de la grande forme, ou d'un contraste, ou
          d'une dominante qui a, tout d'abord, attiré et mobilisé le regard.
        </p>
        <div className="img-center">
          <img src={img1} alt="" />
        </div>
        <p>
          C'est par suite de cette association des deux manières de voir,
          percevoir d'un seul coup et lire ou découvrir peu à peu, que se
          manifeste le sentiment de la libération. Car cette lecture, on est
          libre de la commencer où on veut : en haut, en bas, à droite, à
          gauche, au milieu ou sur les bords. Le tableau est comme un livre dont
          toutes les pages seraient déchiffrables en même temps, une histoire
          dont importerait peu qu'un épisode précède ou suive un autre épisode,
          dont toutes les parties pourraient être le début ou la fin, une
          musique dont l'auditeur pourrait mettre dans l'ordre qui lui plaît
          l'ouverture, le développement et le finale, commencer par le finale,
          continuer par l'ouverture et achever par le développement.
        </p>
        <div className="img-right">
          <img src={img2} alt="" />
          <p>
            Cependant, pour que ce sentiment de liberté soit ressenti, il faut
            qu'il y ait une musique ou, mieux, qu'il y ait une histoire. Pour
            moi je raconte une histoire simple comme celle qu'on nomme au
            Taillandier-land, la « bélibo », c'est-à-dire la belle histoire
            d'une boule. Il s'agit d'un personnage dont toute la personne
            physique se réduit à une boule : une boule abdominale. Un peu plus
            tard, à cette boule s'en ajoute une autre, thoracique celle-là.
            Passe encore un peu de temps et, à l'addition des deux sphères,
            l'une ventrale, l'autre poitrinaire, s'en ajoute une troisième, qui
            est céphalique. C'est ce qu'on appelle au Taillandier-land un
            céphalo-tronc, c'est-à-dire un tronc humain surmonté par une tête.
            La croissance se poursuivant, apparaissent ensuite une, puis deux
            jambes et deux pieds. Enfin surgissent deux bras suivis de deux
            avant-bras et de deux mains. Ainsi le personnage devient normal,
            selon nous. Mais la croissance n'est pas finie. Son postérieur
            grossit et bientôt ses fesses se changent en un deuxième tronc qui,
            rapidement, sera porté par une troisième et une quatrième jambe.
            Toutefois, rien n'est terminé. Le quatrupède que l'on compare à un
            Centaure, encore qu'il n'ait rien d'un cheval, possède des fesses,
            lesquelles se mettent à grossir, deviennent un troisième tronc porté
            par une cinquième et une sixième jambe. Et ainsi de suite.
          </p>
        </div>
        <div className="img-left">
          <img src={img4} alt="" />
          <p>
            Si je raconte cette histoire, ce n'est pas seulement qu'une telle
            histoire et d'autres semblables sont le fond invariable de mon
            inspiration de peintre, c'est aussi parce qu'elle ressemble à
            l'écoulement du temps : une minute répétée fait une heure, une heure
            répétée fait un jour. Or, si l'on profite de la liberté de lecture
            que donne le tableau, on peut avoir l'impression de jouer avec le
            temps, d'être soustrait à sa règle implacable, d'échapper à sa
            tyrannie. Vous êtes en mesure de commencer votre lecture au stade du
            Centaure amélioré qui a six jambes, ou bien au stade du personnage
            qui n'en possède que deux, ou au stade de la boule, ou à tout autre
            moment du récit, et de le continuer selon votre fantaisie. Ainsi
            cette figuration est-elle aussi libératrice que l'oeuvre des
            abstraits, qui prétendaient que figurer, c'était emprisonner et
            paralyser la faculté d'invention.
          </p>
        </div>
        <p>
          La figuration libératrice est une figuration altruiste avec un genre
          d'escalier pour faciliter l'accès au sujet, au motif, afin de rendre
          l'abord de la peinture facile pour le non-peintre. Pour aider celui-ci
          à se concentrer, les formes ne sont pas coupées, amputées par le
          cadre, tout se trouve à l'intérieur des limites visibles. En outre,
          les formes se répètent avec de petites variantes permenttant
          d'imaginer, de percevoir une croissance ou une décroissance à
          l'intérieur de l'image (non à l'extérieur) et d'apparenter la peinture
          à un récit.
        </p>
        <div className="img-right">
          <p>
            Je mets aussi des textes à lire pour diminuer, d'une autre manière,
            la distance entre peinture et littérature et pour aider la
            métamorphose de l'amateur de livres, du lecteur en regardeur de
            tableaux. Appliqué à la figuration, le choix des qualifications de «
            libératrice » et d'« altruiste » n'est pas anodin. Des peintres qui,
            pour quelques-uns, m'ont imité et m'ont suivi avec dix ans de
            retard, ont accepté (ou inventé) d'être désignés par l'adjectif «
            libres ». Bien qu'on m'assimile souvent à eux, je refuse pour moi la
            participation à la figuration libre, car ma qualité d'historien de
            l'art nouveau, dont j'ai donné des preuves en écrivant un ouvrage
            intitulé{" "}
            <span className="italic">
              Les Naissances de la peinture moderne
            </span>
            , (histoire de la peinture inventive de Ingres au tachisme), ne me
            permet pas d'ignorer qu'il y a beau temps que les peintres sont
            devenus libres. La libération du peintre est devenue patente dès
            Jean-Dominique Ingres, que monsieur de Kératry avait accusé d'avoir
            ajouté trois vertèbres au dos immense et si élégant de sa fameuse{" "}
            <span className="italic">Odalisque</span>.
          </p>
          <img src={img3} alt="" />
        </div>
        <p>
          Plus tard, les impressionnistes se sont débarassés du clair-obscur,
          les expressionnistes ont fait fi de l'anatomie classique, les cubistes
          de la perspective renaissante, les surréalistes ont rejeté le carcan
          du rationalisme et de la logique ; enfin, les abstraits ont éliminé la
          contrainte du sujet.
        </p>
        <p>
          Je dis « enfin », mais les libérations que les peintres pratiquent à
          leur bénéfice ne semblent pas avoir de fin. C'est là que la figuration
          libératrice se veut nouvelle : le figuratif libérateur ne se contente
          pas de penser à lui ; il ne lui suffit pas de se libérer de telle ou
          telle contrainte, il veut surtout que la spectatrice ou le spectateur
          se sente libre autant que lui, et même plus que lui.
        </p>
        <p>
          A ce sentiment de liberté qu'il se donne pour but de prodiguer, il
          apporte des renforcements en opérant un tri parmi les trouvailles et
          les simplifications de ses prédécesseurs. Pour ma part, j'ai, comme
          Gauguin ou Matisse, renoncé au modelé, de sorte que mes personnages
          aux organes multipliés, polycéphales, polybrachiens, polytroncs,
          polypèdes, gardent un certain éloignement qui les situe au niveau
          d'une rêverie douce. S'ils étaient modelés à la façon classique, ils
          auraient trop de vraisemblance et de proximité, et ils seraient
          monstrueux et insupportables. Le contraire doit se passer ; ils
          doivent inspirer un sentiment d'ingéniosité, voire d'astuce et de
          délectation.
        </p>
        <p>
          Comme les impressionnistes, j'ai, dans mes tableaux polychromes, exclu
          le noir, que je remplace par un violet bleu. d'où un allègement
          stimulant de l'image. La grande majorité de mes personnages sont
          reliés par des tubes associatifs, de sorte que Gisèle Prassinos a pu
          me dire :
        </p>
        <blockquote>
          Vos personnages ne sont jamais seuls : ça me plaît.
        </blockquote>
        <p>
          Ces tubes associatifs perdent souvent leur caractère tubulaire pour
          devenir des solides plats, des plateaux, des tapis volants, de manière
          que les conducteurs de mes automobiles n'ont pas besoin de mettre leur
          moteur en marche : le tapis volant qui porte leur voiture les mène où
          ils veulent.
        </p>
        <p>
          Il y a une quarantaine d'années, un critique d'art, dont j'étais alors
          le confrère, terminant la préface par laquelle il présentait un
          peintre encore nouveau en écrivant : « Et puis ce peintre vous dit le
          mot de Cambronne », je respecte le courage de Pierre-Jacques Etienne,
          vicomte de Cambronne ; cependant je trouve insupportable de maltraiter
          ainsi les regardeuses et les regardeurs susceptibles d'êtres changés
          en admiratrices et admirateurs et de leur marquer, avant toute chose,
          tant de mépris. Ne fût-ce que mon irritation m'a amené à prendre une
          position toute contraire et à nourrir l'intention de calligraphier un
          jour, parmi les textes qui remplissent dans mes tableaux une fonction
          à la fois ornementale et, pour les francophones, éclairante, si besoin
          est, l'exclamation que voici :
        </p>
        <blockquote>
          Regardeuses et regardeurs, mes amis, les Taillandier-landais vous
          aiment.
        </blockquote>
        <div className="fig-narr">
          <img src={img5} className="fig-narr-img" alt="" />
          <h3>La vraie figuration narrative</h3>
          <p>
            Si j'avais à raconter l'histoire de Napoléon traversant les Alpes
            avec son armée ou celle d'Hannibal franchissant ces mêmes montagnes
            avec ses éléphants qui transportaient ses soldats et leurs armes
            pour aller menacer Rome, mais destiné à être vaincu par les délices
            de Capou, je ne me contenterais pas de peindre comme on l'a fait
            Napoléon monté sur un cheval qui se cabre gracieusement sur un fond
            de pics neigeux ou le général carthaginois, borgne mais génial
            installé sur un éléphant environné de ces mêmes obstacles
            géologiques. Non, je ne ferais pas cela; et c'est dans ce refus que
            réside une des caractéristiques principales de ma peinture.
            <img src={img5} className="fig-narr-img-mob" alt="" />
          </p>
        </div>
      </div>
    </div>
    <Footer />
  </div>
);

export default FigurationLib;
