import { apiCall } from '../../services/api';
import { addError } from './errors';
import { LOAD_OEUVRES, LOAD_ONE_OEUVRE } from '../actionTypes';

// Action creator to load oeuvres
export const loadOeuvres = oeuvres => ({
  type: LOAD_OEUVRES,
  oeuvres
});
// Action creator to load one oeuvre
export const loadOeuvre = oeuvre => ({
  type: LOAD_ONE_OEUVRE,
  oeuvre
});

export const fetchOeuvres = () => {
  return dispatch => {
    return apiCall('get', '/api/oeuvres')
      .then(res => {
        dispatch(loadOeuvres(res));
      })
      .catch(err => {
        dispatch(addError(err.message));
      });
  };
};

export const getOeuvre = (oeuvre_id) => {
  return dispatch => {
    return apiCall('get', `/api/oeuvres/${oeuvre_id}`)
      .then(res => {
        dispatch(loadOeuvre(res));
      })
      .catch(err => {
        dispatch(addError(err.message));
      });
  };
};
