import React from 'react';

const ImageItem = ({id, image}) => (
  <div>
    {(image.split('/')[1] === 'expositions') && (
      <img src={process.env.REACT_APP_API_URL+'/expositions/'+image} alt="" />
    )}
    {(image.split('/')[1] === 'actualites') && (
      <img src={process.env.REACT_APP_API_URL+'/actualites/'+image} alt="" />
    )}
  </div>
);

export default ImageItem;
