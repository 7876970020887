/*
  Root Reducer
*/
import { combineReducers } from 'redux';
import errors from './errors';
import oeuvres from './oeuvres';
import themes from './themes';
import expositions from './expositions';
import actualites from './actualites';

const rootReducer = combineReducers({
  errors,
  oeuvres,
  themes,
  expositions,
  actualites
});

export default rootReducer;
