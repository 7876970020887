import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { configureStore } from '../store';
import ScrollToTop from './ScrollToTop';

import Header from './Header';
import Homepage from './Homepage';
import Biography from './Biography';
import Contact from './Contact';
import Oeuvres from './Oeuvres';
import Expositions from './Expositions';
import ExpoSingle from './ExpoSingle';
import Actualites from './Actualites';
import ActuSingle from './ActuSingle';
import TaillandierLand from './TaillandierLand';
import FigurationLib from './FigurationLib';
import RencontresDeYT from './RencontresDeYT';
import CritiqueArt from './CritiqueArt';
import LePeintre from './LePeintre';
import MentionsLegales from './MentionsLegales';
import NotFound from './NotFound';

const store = configureStore();

const App = props => (
  <Provider store={store}>
    <Router>
      <ScrollToTop>
      <div className="app">
        <Header />
        <Switch>
          <Route exact path="/" component={Homepage} />
          <Route exact path="/biographie" component={Biography} />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/oeuvres/" component={Oeuvres} />
          <Route exact path="/oeuvres/par-theme/:theme" component={Oeuvres} />
          <Route exact path="/expositions/" component={Expositions} />
          <Route exact path="/actualites/" component={Actualites} />
          <Route exact path="/actualites/:id" component={ActuSingle} />
          <Route exact path="/expositions/:id" component={ExpoSingle} />
          <Route exact path="/le-taillandier-land" component={TaillandierLand} />
          <Route exact path="/la-figuration-liberatrice" component={FigurationLib} />
          <Route exact path="/les-rencontres-d-yvon-taillandier" component={RencontresDeYT} />
          <Route exact path="/le-critique-et-historien-dart" component={CritiqueArt} />
          <Route exact path="/le-peintre" component={LePeintre} />
          <Route exact path="/mentions-legales" component={MentionsLegales} />
          <Route component={NotFound} />
        </Switch>
      </div>
      </ScrollToTop>
    </Router>
  </Provider>
);

export default App;
