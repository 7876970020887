import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchExpositions } from '../store/actions/expositions';
import ExpoListItem from '../components/ExpoListItem';
import avion from '../img/avion.svg';

class ExposList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1
    }
  }
  componentDidMount() {
    this.props.fetchExpositions();
  }
  render() {
    const { expositions } = this.props;
    const { currentPage } = this.state;
    const numPages = Math.ceil((expositions.length-1)/10);
    const pagination = [];
    for (let i = 1; i <= numPages; i++) {
      pagination.push(i);
    }

    if(expositions) {
      var exposList = expositions.map(e => (
        <ExpoListItem
          key={e._id}
          id={e._id}
          title={e.title}
          subtitle={e.subtitle}
          date={e.date}
          text={e.text}
          images={e.images}
        />
      ));
    }

    if(currentPage === 1) {
      var exposListCloneP1 = Array.from(exposList);
      var paginatedExposList = exposListCloneP1.slice(currentPage, currentPage * 10 + 1);
      var expoListDom = (
        <div className="list list-page1">
          {exposList[0]}
          <div className="avion"><img src={avion} alt="" /></div>
          {paginatedExposList}
        </div>
      );
    } else {
      var exposListCloneAll = Array.from(exposList);
      paginatedExposList = exposListCloneAll.slice(Number((currentPage-1)+'1'), currentPage * 10 + 1);
      expoListDom = (<div className="list">{paginatedExposList}</div>);
    }

    return (
      <div>
        {expoListDom}
        <div className="pagination">
          {pagination.map(p => <span key={p} onClick={this.handlePagination}>{p}</span>)}
        </div>
      </div>
    );
  }

  // Handling clicks on pagination
  handlePagination = (e) => {
    e.preventDefault();
    this.setState({ currentPage: Number(e.target.innerText) });
    window.scrollTo(0,0);
  }
}

function mapStateToProps(state) {
  return {
    expositions: state.expositions
  };
}

export default connect(mapStateToProps, { fetchExpositions })(ExposList);
