import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import logoLarge from "../img/logo-yvont.svg";
import {
  toggleLogoOnLoad,
  toggleLogoOnResize,
  ImgZoomBox,
  backToTop
} from "../services/main.js";

class Header extends Component {
  render() {
    toggleLogoOnLoad();
    toggleLogoOnResize();
    ImgZoomBox();
    backToTop();
    const location = this.props.location.pathname;

    return (
      <header className="main-header">
        <h1>
          <Link to="/">
            <img className="logo" src={logoLarge} alt="Yvon Taillandier" />
          </Link>
        </h1>
        <label>
          <input type="checkbox" />
          <span
            className="menu-s"
            onClick={() =>
              document.querySelector(".menu").classList.toggle("hide")
            }
          >
            <span className="hamburger" />
          </span>

          <nav className="menu hide">
            <li>
              <Link
                to="/"
                className={location === "/" ? "isActive" : ""}
                onClick={this.closeBurgerMenu}
              >
                Accueil
              </Link>
            </li>
            <li>
              <Link
                to="/biographie"
                className={location.includes("biographie") ? "isActive" : ""}
                onClick={this.closeBurgerMenu}
              >
                Biographie
              </Link>
            </li>
            <li>
              <Link
                to="/oeuvres"
                className={location.includes("oeuvres") ? "isActive" : ""}
                onClick={this.closeBurgerMenu}
              >
                Oeuvres
              </Link>
            </li>
            <li>
              <Link
                to="/expositions"
                className={location.includes("exposition") ? "isActive" : ""}
                onClick={this.closeBurgerMenu}
              >
                Expositions
              </Link>
            </li>
            <li>
              <Link
                to="/actualites"
                className={location.includes("actualites") ? "isActive" : ""}
                onClick={this.closeBurgerMenu}
              >
                Actualités
              </Link>
            </li>
            <li>
              <Link
                to="/contact"
                className={location.includes("contact") ? "isActive" : ""}
                onClick={this.closeBurgerMenu}
              >
                Contact
              </Link>
            </li>
          </nav>
        </label>
      </header>
    );
  }

  closeBurgerMenu = () => {
    let menu = document.querySelector(".menu");
    if (!menu.classList.incldues("hide")) {
      menu.classList.add("hide");
    }
  };
}

export default withRouter(Header);
