import { LOAD_OEUVRES, LOAD_ONE_OEUVRE } from '../actionTypes';

const oeuvres = (state = [], action) => {
  switch (action.type) {
    case LOAD_OEUVRES:
      return [...action.oeuvres];
    case LOAD_ONE_OEUVRE:
      return [action.oeuvre];
    default:
      return state;
  }
};

export default oeuvres;
