import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchOeuvres } from '../store/actions/oeuvres';
import OeuvreListItem from './OeuvreListItem';
import Poemes from './Poemes';

class OeuvresList extends Component {
  componentDidMount() {
    this.props.fetchOeuvres();
  }

  render() {
    const { oeuvres, byTheme } = this.props;

    if(byTheme) {
      if(byTheme === "Poèmes") {
        var poemes = <Poemes />;
      } else {
        var oeuvresListByTheme = oeuvres.filter(oeuvre => oeuvre.theme.includes(byTheme)).map(o => (
          <OeuvreListItem
            key={o._id}
            id={o._id}
            mini={o.image}
            description={o.description}
            theme={byTheme}
            year={o.year}
          />
        ));
      }
    } else {
      var oeuvresList = oeuvres.map(o => (
        <OeuvreListItem
          key={o._id}
          id={o._id}
          mini={o.image}
          description={o.description}
          theme={o.theme}
          year={o.year}
        />
      ));
    }

    return (
      <div className="oeuvres-list">
        {poemes ? poemes : (oeuvresListByTheme ? displayOeuvres(oeuvresListByTheme) : displayOeuvres(oeuvresList))}
      </div>
    );
  }
}

function displayOeuvres(oeuvres) {
  let NUM_COL = null;
  if(window.innerWidth <= 640) {
    NUM_COL = 1;
  } else if(window.innerWidth <= 1024) {
    NUM_COL = 2;
  }
  else if(window.innerWidth <= 1200) {
    NUM_COL = 3;
  } else {
    NUM_COL = 4;
  }

  if(NUM_COL) {
    const NUM_PER_COL = oeuvres.length < NUM_COL ? 1 : Math.floor(oeuvres.length/NUM_COL);
    let cols = [];

    if(NUM_PER_COL) {
      for(let i=0; i < oeuvres.length; i += NUM_PER_COL) {
        let rows = [];
        for(let j=i; j < (i + NUM_PER_COL); j++) {
          rows.push(oeuvres[j]);
        }
        cols.push(<div key={i} className="column">{rows}</div>);
      }
      return cols;
    }
  }
}

function mapStateToProps(state) {
  return {
    oeuvres: state.oeuvres
  };
}

export default connect(mapStateToProps, { fetchOeuvres })(OeuvresList);
