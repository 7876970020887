import React from "react";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import purpleGuy from "../img/yt-bonhomme-v.svg";

const ActuListItem = ({ id, date, title, subtitle, text, images }) => (
  <div className="list-item">
    <div className="item-img">
      <img
        src={
          images
            ? process.env.REACT_APP_API_URL + "/actualites/" + images[0]
            : ""
        }
        alt=""
      />
    </div>
    <div className="item-content">
      <p className="latest-tag">
        <span>DERNIERE NOUVELLE</span>
      </p>
      <h3>{title}</h3>
      <p className="item-date">
        <Moment format="DD / MM / YYYY">{date}</Moment>
      </p>
      <div className="item-text-link">
        <div className="item-text">
          {subtitle ? (
            subtitle
          ) : (
            <div className="trix-text-editor">
              <div dangerouslySetInnerHTML={{ __html: text }} />
            </div>
          )}
        </div>
        <div className="item-link">
          <Link to={`/actualites/${id}`}>
            <img className="purple-guy" src={purpleGuy} alt="" />
            <span>Lire la suite</span>
          </Link>
        </div>
      </div>
    </div>
    <div className="item-link-mob">
      <Link to={`/actualites/${id}`}>
        <i class="fas fa-angle-right" />
      </Link>
    </div>
  </div>
);

export default ActuListItem;
