import { apiCall } from '../../services/api';
import { addError } from './errors';
import { LOAD_THEMES, LOAD_ONE_THEME } from '../actionTypes';

// Action creator to load themes
export const loadThemes = themes => ({
  type: LOAD_THEMES,
  themes
});
// Action creator to load one theme
export const loadTheme = theme => ({
  type: LOAD_ONE_THEME,
  theme
});

export const fetchThemes = () => {
  return dispatch => {
    return apiCall('get', '/api/themes')
      .then(res => {
        dispatch(loadThemes(res));
      })
      .catch(err => {
        dispatch(addError(err.message));
      });
  };
};

export const getTheme = (theme_id) => {
  return dispatch => {
    return apiCall('get', `/api/themes/${theme_id}`)
      .then(res => {
        dispatch(loadTheme(res));
      })
      .catch(err => {
        dispatch(addError(err.message));
      });
  };
};
