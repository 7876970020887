import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import footImg from '../img/restauration-fresque-atelier9.jpg';

class Footer extends Component {
  render() {
    return (
      <footer className="main-footer">
        <div className="footer">
          <div className="foot-bloc foot-quote">
            <div><i className="fas fa-quote-left"></i></div>
            <div>Mes tableaux se veulent des chants joyeux, voire des hymnes à la joie.</div>
            <div className="foot-copy">&copy; Yvon Tailandier, 2018  - <Link to='/mentions-legales'>Mentions Légales</Link></div>
          </div>
          <div className="foot-bloc">
            <h4>Oeuvres</h4>
            <ul>
              <li><Link to='/oeuvres/par-theme/Toiles'>Toiles</Link></li>
              <li><Link to='/oeuvres/par-theme/Dessins'>Dessins</Link></li>
              <li><Link to='/oeuvres/par-theme/Bois%20peints%20-%20Mobiliers'>Bois peints - Mobiliers</Link></li>
              <li><Link to='/oeuvres/par-theme/Céramiques%20-%20Poteries%20-%20Plâtres'>Céramiques - Poteries - Plâtres</Link></li>
              <li><Link to='/expositions'>Expositions</Link></li>
            </ul>
          </div>
          <div className="foot-bloc">
            <h4>A propos</h4>
            <ul>
              <li><Link to='/'>Accueil</Link></li>
              <li><Link to='/biographie'>Biographie</Link></li>
              <li><Link to='/le-peintre'>Le peintre</Link></li>
              <li><Link to='/le-taillandier-land'>le Taillandier-land</Link></li>
              <li><Link to='/la-figuration-liberatrice'>La figuration libératrice</Link></li>
              <li><Link to='/le-critique-et-historien-dart'>Le critique et l'historien d'art</Link></li>
              <li><Link to='/les-rencontres-d-yvon-taillandier'>Les rencontres d'Yvon Taillandier</Link></li>
              <li><Link to='/contact'>Contact</Link></li>
            </ul>
          </div>
          <div className="foot-bloc encemoment">
            <h4>En ce moment</h4>
            <div>Restauration de la fresque murale de l'atelier de Paris 9</div>
            <div className="foot-img"><img src={footImg} alt="" /></div>
            <div>A voir à l'angle des rues Milton et de l'Agent Bailly à Paris 9ème.</div>
          </div>
        </div>
        <div className="foot-copy-mob">&copy; Yvon Tailandier, 2018 - <Link to='/mentions-legales'>Mentions Légales</Link></div>
      </footer>
    );
  }
}

export default Footer;
