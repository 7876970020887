import React from 'react';

const OeuvreListItem = ({id, year, mini, description, theme}) => (
  <div className="oeuvre-item">
    <img src={process.env.REACT_APP_API_URL+'/oeuvres/'+mini} alt="" />
    <div className="oeuvre-infos">
      <p><strong>{year}</strong> | {description}</p>
      <div>{typeof theme === 'object' ? (theme.map((t, id) => <span key={id}>{t}</span>)) : <span>{theme}</span>}</div>
    </div>
  </div>
);

export default OeuvreListItem;
