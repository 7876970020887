import React, { Component } from 'react';
import Footer from './Footer';
import ActusList from './ActusList';

class Actualites extends Component {
  render() {
    return (
      <div className="page">
        <div className="container">
          <div className="list-page">
            <h1>Actualités</h1>
            <ActusList />
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Actualites;
