import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { fetchThemes } from "../store/actions/themes";

class ThemesList extends Component {
  componentDidMount() {
    this.props.fetchThemes();
  }

  render() {
    const { themes } = this.props;

    if (themes) {
      var themesList = themes.map(t => (
        <li key={t._id} className="">
          <Link
            onClick={this.handleThemeClick}
            to={`/oeuvres/par-theme/${t.name}`}
          >
            {t.name}
          </Link>
        </li>
      ));
    }

    return (
      <ul className="hide">
        <li className="active-theme">
          <Link onClick={this.handleThemeClick} to={`/oeuvres`}>
            Toutes les oeuvres
          </Link>
        </li>
        {themesList}
      </ul>
    );
  }

  handleThemeClick = e => {
    document.querySelector(".active-theme").classList.remove("active-theme");
    e.target.parentNode.classList.add("active-theme");
  };
}

function mapStateToProps(state) {
  return {
    themes: state.themes
  };
}

export default connect(
  mapStateToProps,
  { fetchThemes }
)(ThemesList);
