import { LOAD_EXPOS, LOAD_ONE_EXPO } from '../actionTypes';

const expositions = (state = [], action) => {
  switch (action.type) {
    case LOAD_EXPOS:
      return [...action.expositions];
    case LOAD_ONE_EXPO:
      return [action.expo];
    default:
      return state;
  }
};

export default expositions;
