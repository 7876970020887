import React, { Component } from 'react';
import { apiCall } from '../services/api';
import Footer from './Footer';
import illustration from '../img/yt-77.svg';

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      success: false
    };
  }

  render() {
    return (
      <div className='page'>
        <div className='container'>
          <div className='contact'>
            <h1>Contact</h1>
	    <p>
              Ce site est administré par l'association Les Amis d'Yvon
              Taillandier. N'hésitez pas à nous écrire à l'adresse{' '}
              <a href="mailto:contact@yvon-taillandier.com" class="link">
                contact@yvon-taillandier.com
              </a>
              . Nous prendrons soin de vous répondre dans les meilleurs délais.
            </p>
            {/*<p>
              Ce site est administré par l'association Les Amis d'Yvon
              Taillandier. N'hésitez pas à nous envoyer, via le formulaire de
              contact ci-dessous vos messages, questions et remarques relatifs à
              Yvon Taillandier et son œuvre. Nous prendrons soin de vous
              répondre dans les meilleurs délais.
            </p>

            {this.state.error && (
              <p className='contact-error'>
                Oups, votre message n'a pas pu être envoyé :( Veuillez vérifiez
                que vous avez bien renseigné tous les champs et réessayer.
              </p>
            )}
            {this.state.success && (
              <p className='contact-success'>
                Merci pour votre message ! Nous vous répondrons dans les plus
                brefs délais :)
              </p>
            )}

            <form id='contact-form' onSubmit={this.handleSendMail}>
              <div>
                <label htmlFor='contact-name'>Votre nom</label>
                <input
                  type='text'
                  name='contact-name'
                  id='contact-name'
                  placeholder='ex: Yvon Taillandier'
                  onChange={this.handleChange}
                />
              </div>
              <div>
                <label htmlFor='contact-email'>Votre adresse e-mail</label>
                <input
                  type='text'
                  name='contact-email'
                  id='contact-email'
                  placeholder='ex: yvon@taillandier.com'
                  onChange={this.handleChange}
                />
              </div>
              <div>
                <label htmlFor='contact-msg' className='label-msg'>
                  Votre message
                </label>
                <textarea
                  name='contact-msg'
                  id='contact-msg'
                  placeholder='ex: Votre message...'
                  onChange={this.handleChange}
                ></textarea>
              </div>
              <div>
                <label></label>
                <button type='submit'>
                  Envoyer <i className='fas fa-paper-plane'></i>
                </button>
              </div>
            </form>*/}
            <div className='contact-img'>
              <img src={illustration} alt='' />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }

  handleSendMail = e => {
    e.preventDefault();
    const name = document.getElementById('contact-name').value;
    const email = document.getElementById('contact-email').value;
    const message = document.getElementById('contact-msg').value;

    return apiCall('post', '/api/email', {
      data: {
        name: name,
        email: email,
        message: message
      }
    })
      .then(res => {
        window.scrollTo(0, 0);
        window.scrollTop = 0; // for Edge
        if (res.msg === 'success') {
          document.getElementById('contact-form').reset();
          this.setState({ error: false, success: true });
        } else if (res.msg === 'fail') {
          this.setState({ error: true, success: false });
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ error: true, success: false });
      });
  };
}

export default Contact;
