import { apiCall } from '../../services/api';
import { addError } from './errors';
import { LOAD_EXPOS, LOAD_ONE_EXPO } from '../actionTypes';

// Action creator to load expositions
export const loadExpositions = expositions => ({
  type: LOAD_EXPOS,
  expositions
});
// Action creator to load one exposition
export const loadExpo = expo => ({
  type: LOAD_ONE_EXPO,
  expo
});

export const fetchExpositions = () => {
  return dispatch => {
    return apiCall('get', '/api/expositions')
      .then(res => {
        dispatch(loadExpositions(res));
      })
      .catch(err => {
        dispatch(addError(err.message));
      });
  };
};

export const getExpo = (exposition_id) => {
  return dispatch => {
    return apiCall('get', `/api/expositions/${exposition_id}`)
      .then(res => {
        dispatch(loadExpo(res));
      })
      .catch(err => {
        dispatch(addError(err.message));
      });
  };
};
