import React from 'react';

const Poemes = props => (
  <div className="oeuvre-poeme">
    <h2>Le conteur et poète</h2>
    <p>
      Après ses jeunes années à Lyon, Yvon Taillandier est de retour à Paris en 1945. Il a 19 ans. Plutôt attiré par l’écrit, il s'intéresse à la critique d’art, à l’histoire de l’art, écrit des romans et surtout des contes et poèmes.
      <br />
      Sa première publication poétique parait en 1944 dans la revue Confluences avec un texte intitulé : «PAGES ».
      <br />
      Au Salon de Mai en 1951, il participe au mur de la poésie avec « Quelques Iles » ainsi qu’en 1952 avec « Les Maisons transparentes ».
      <br />
      Il consacrera 148 poèmes à sa compagne Jacqueline Selz.
    </p>
    <article>
      <p>La poésie est une musique d'idées - le mystère est dans le chant.</p>
    </article>
    <div>~</div>
    <article>
      <p>Je me finis sans cesse et me recommence-<br />Ne suis-je pas infini ? Je me continue -<br />Ceci est beau qui le reflète.</p>
    </article>
    <div>~</div>
    <article>
      <p>Le passé et le futur, quand ils s'opposent au présent, se ressemblent !</p> 
    </article>
    <div>~</div>
    <article>
      <p>Ce que nous fîmes est le double de ce que nous sommes<br />Cet autre que moi qui m'attrape, c'est moi-même<br />Je reçois mon propre coup.</p>
    </article>
    <div>~</div>
    <article>
      <p>Tel est le malheur des hommes qu'ils ne peuvent concevoir de bonheur sans malheur.<br />Ce n'est pas une autre vérité que le masochisme exprime.</p>
    </article>
    <div>~</div>
    <article>
      <p>
        Dans la plaine, l'humidité du fleuve nourrit d'interminables prairies où vont paître d'innombrables troupeaux. Mais, pendant qu'à l'extrémité de la plaine, le fleuve, à son embouchure, se jette dans la mer - pendant que le fleuve finit, sa source jaillit de la montagne, et le fleuve commence.<br />Le fleuve naît et meurt en même temps. Fleuve éphémère, tu vis moins longtemps que les fleurs qui se multiplient sur tes rives.<br />Fleuve, tu n'existes pas dans le temps.<br />Des milliers de fleurs, des millions d'herbes te doivent la vie<br />Mais ton immensité liquide n'appartient qu'à l'espace.<br />Sitôt né, sitôt mort ! 
      </p>
    </article>
    <div>~</div>
    <article>
      <p>(variante)<br />A midi, la source du fleuve jaillit de la montagne<br />A midi, le fleuve coule dans la vallée<br />A midi, le fleuve se jette dans la mer.</p>
    </article>
    <div>~</div>
    <article>
      <p>J'étais content - Pourquoi ?<br />Mes ennemis avaient eu peur.<br />Pourquoi ?<br />Mon chien s'était mis à rire en les voyant !</p>
    </article>
    <div>~</div>
    <article>
      <p>Je suis ton double bien aimé :<br />le double tranchant de l'épée<br />ou bien te tue, ou me tue.</p>
      <p>Dois-je ma force à mon courage,<br />ou mon courage à ma force ?</p>
      <p>Le double tranchant de ce glaive<br />fait ma perte et mon salut. </p>
    </article>
    <div>~</div>
    <article>
      <h3>Reflets</h3>
      <p>Le reflet de la main droite, c'est la main gauche.<br />L'oeil droit reflète l'oeil gauche ;<br />l'oreille gauche et l'oreille droite se font écho ;<br />et le pied gauche répond au pied droit.</p>
      <p>La terre et l'eau sont des miroirs.<br />Les arbres du bord des lacs se reflètent dans l'eau.<br />Dans la terre des arbres de la plaine se reflètent :<br />les racines est le nom de ces reflets.</p>
      <p>Les fondations sont les reflets des maisons ;</p>
      <p>Les vieillards se voient dans les enfants.<br />Les hommes se dévisagent dans les regards des femmes.</p>
    </article>
    <div>~</div>
    <article>
      <p>La ville est emprisonnée dans les remparts.<br />Entre les remparts les maisons sont prisonnières.<br />Entre les maisons, les prisons sont exemplaires :<br />prisonnières emprisonnant,<br />les prisons sont prisonnières.</p>
      <p>variante:</p>
      <p>Habitants habités</p>
      <p>L'habit est la maison du corps<br />Le corps est la maison de l'esprit<br />l'esprit est la maison du monde.</p>
      <p>Les villes habitent le monde,<br />Les prisons habitent les villes<br />et les prisonniers, les prisons.</p>
      <p>Les prisons sont exemplaires :<br />contenant et contenues,<br />les prisons sont prisonnières.</p>
    </article>
    <div>~</div>
    <article>
      <p>La nuit ne finit pas au premier jour.<br />Autant de jours, autant de nuits.<br />Les nuits séparent les jours.<br />Les jours séparent les nuits.</p>
      <p>Le dernier jour est-il plus court ?<br />Le dernier jour est-il plus long<br />que tous les jours de la vie ?<br />Tous les jours sont plus courts<br />que la dernière nuit.</p>
    </article>
    <div>~</div>
    <article>
      <p>L'ami du voyageur<br />est l'hôtelier<br />l'hôtelier ne fait<br />aucun voyage.<br />Mais l'hôtelier<br />tous les jours<br />salut un nouveau monde</p>
    </article>
    <div>~</div>
    <article>
      <h3>Liberté</h3>
      <p>Le ciel est un lac où les nuages sont enfermés ;<br />Le ciel est enfermé dans le paysage.<br />Le paysage est enfermé dans l'horizon-<br />Mais l'horizon ne retient pas les nuages.</p>
    </article>
    <div>~</div>
    <article>
      <h3>Immobiles et Mobiles</h3>
      <p>Les toits sont immobiles.<br />Au-dessus des toits,<br />les oiseaux volent.</p>
      <p>Au-dessus des nuages qui volent,<br />les nuages bougent.</p>
      <p>Au-dessus des nuages qui bougent,<br />le bleu du ciel est immobile.</p>
      <p>Dans le bleu du ciel<br />le soleil court.</p>
      <p>Au-dessus du soleil qui court,<br />il y a le ciel noir de la nuit.</p>
      <p>Dans le ciel noir de la nuit,<br/>les étoiles passent.</p>
      <p>Mais le ciel noir est immobile.</p>
      <p>Quand l'immobile<br />devient mobile,</p>
      <p>quand le bleu disparait,<br />quand le noir le remplace,<br />quand la nuit descend,</p>
      <p>sous les étoiles qui passent,<br />les toits sont immobiles.</p>
    </article>
    <div>~</div>
    <article>
      <h3>Souvenir de Voyage</h3>
      <p>Ai-je parlé de cette place<br />qui, dans cette ville,<br />est en forme de coquillage ?</p>
      <p>Quand on met l'oreille sur le sol,<br />on entend le bruit de la mer.</p>
    </article>
    <div>~</div>
    <article>
      <h3>Quatre souvenirs et un oubli</h3>
      <p>Le vieillard se souvient de l'adulte.<br />L'adulte se souvient du jeune homme.<br />Le jeune homme se souvient de l'adolescent.<br />L'adolescent se souvient de l'enfant.</p>
      <p>Mais l'enfant ne se souvient de personne.</p>
    </article>
    <div>~</div>
    <article>
      <p>
        Gens qui partez,<br />gens qui restez<br />l'heure a sonné :<br />séparez-vous !<br />Sifflez, sifflez, sirène :<br />on largue les amarres !<br />"Au revoir, disent les uns, au revoir !"<br />"Adieu ! Adieu ! disent les autres".<br />La fumée monte.<br />L'échelle remonte.<br />L'hélice tourne.<br />L'eau se détourne,<br />ni l'eau, ni l'hélice,<br />ni l'échelle, ni la fumée,<br />ni les câbles, ni les machines,<br />ni les mâts, ni les cabines<br />ne disent rien.<br />Les phares de la jetée<br />regardent passer le navire<br />sans rien dire.<br />Tant de gens s'en vont.<br />Tant de gens restent.<br />Si peu de mots le disent.<br />Adieu ! Adieu ! Au revoir !<br />Larguez, larguez les amarres !<br />Marchez, marchez comme le vent,<br />gens de voyage,<br />gens de passage !
      </p>
    </article>
    <div>~</div>
    <article>
      <p>Que préfères-tu ?<br />L'eau trouble<br />où tu pêches<br />ou<br />l'eau claire<br />où tu te noies ?</p>
      <p>Que préfères-tu ?<br />Le feu libre<br />qui te brûle<br />ou<br />le feu prisonnier<br />qui te chauffe ?</p>
      <p>Que préfères-tu ?<br />La bête morte<br />que tu manges<br />ou<br />la bête vivante<br />qui te mange ?</p>
      <p>Que préfères-tu ?<br />tuer les autres<br />pour vivre<br />ou mourir</p>
      <p>pour leur laisser la vie ?</p>
    </article>
  </div>
);

export default Poemes;
