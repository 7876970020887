export const ADD_ERROR = 'ADD_ERROR';
export const REMOVE_ERROR = 'REMOVE_ERROR';

export const LOAD_ACTUS = 'LOAD_ACTUS';
export const LOAD_ONE_ACTU = 'LOAD_ONE_ACTU';

export const LOAD_EXPOS = 'LOAD_EXPOS';
export const LOAD_ONE_EXPO = 'LOAD_ONE_EXPO';

export const LOAD_OEUVRES = 'LOAD_OEUVRES';
export const LOAD_ONE_OEUVRE = 'LOAD_ONE_OEUVRE';

export const LOAD_THEMES = 'LOAD_THEMES';
export const LOAD_ONE_THEME = 'LOAD_ONE_THEME';
