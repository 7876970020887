import React, { Component } from 'react';
import Footer from './Footer';
import ExposList from './ExposList';

class Expositions extends Component {
  render() {
    return (
      <div className="page">
        <div className="container">
          <div className="list-page">
            <h1>Expositions</h1>
            <ExposList />
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Expositions;
