import React from 'react';
import Footer from './Footer';
import Bonhomme from '../img/yt-bonhomme-b.svg';
import JoanMiro from '../img/rencontres/joan_miro.svg';
import Giacometti from '../img/rencontres/giacometti.svg';
import PierreSoulages from '../img/rencontres/pierre_soulages.svg';
import CeresFranco from '../img/rencontres/ceres_franco.svg';
import LaurentChabas from '../img/rencontres/laurent_chabas.svg';
import OlivierOrtega from '../img/rencontres/olivier_ortega.svg';
import PierreRestany from '../img/rencontres/pierre_restany.svg';
import LydiaHarambourg from '../img/rencontres/lydia_harambourg.svg';
import LadislasKijno from '../img/rencontres/ladislas_kijno.svg';
import JeanPierreRaynaud from '../img/rencontres/jean_pierre_raynaud.svg';
import pharaon from '../img/yt-87.svg';

const RencontresDeYT = props => (
  <div className="page">
    <div className="container">
      <div className="rencontres">
        <h1>Les rencontres d'Yvon Taillandier</h1>
        <p>
          Yvon Taillandier s'est imprégné de ses rencontres avec les artistes de son temps, ainsi que des nombreuses personnalités avec lesquels il s'est lié d'amitié : écrivains, poètes, cinéastes, scientifiques, critiques d'art... (je te communiquera dans deux jours une liste d'une vingtaine de personnalités. Par ailleurs je vais t'envoyer les textes de quatre rencontres de plus à ajouter en encart : Miró, Giacometti, Soulages, San Lazzaro).
        </p>
        <div className="bonhomme"><img src={Bonhomme} alt="" /></div>

        <article>
          <div className="rencontre-pic"><img src={JoanMiro} className="frame" alt="" /></div>
          <h3>Joan Miró</h3>
          <div className="rencontre-pic rencontre-pic-mob"><img src={JoanMiro} className="frame" alt="" /></div>
          <p>Comme critique d'art, Yvon Taillandier collabore à la revue XXè siècle de 1958 à 1983. La première mission que lui confit Gualtieri di San Lazarro, Directeur de XXè siècle, est d'aller interviewer Joan Miró.<br />Publié en 1959 sous le titre : « je travaille comme un jardinier », l’interview devenu célèbre fait l’objet, de nos jours encore, de maintes rééditions, en France comme à l’étranger.<br />A l’occasion de cette première rencontre ils se lieront d’amitié.</p>
          <p>Miró me téléphona lui-même pour me donner rendez-vous à son hôtel. Il me reconnut sans m’avoir jamais vu et s’installa à la table où je prenais un café en l’attendant. A peine s’était-il assis que je commençai à l’interviewer (..). Aussitôt je pris des notes, ce qui parut le gêner. « Non, dit-il n’écrivez pas ! ». J’arrêtai ostensiblement l’activité de mon crayon. Nous continuâmes à parler et, subrepticement, je me remis à écrire sans qu’il le remarque. L’entretien dura environ une heure et demie. Miró avait l’air inquiet : « j’espère que nous avons bien travaillé » me dit-il.</p>
          <p>Pendant huit jours, je déchiffrai mes notes et interprétai mes souvenirs, avec toujours dans l’oreille sa voix mordante, sans méchanceté, quelque peu paysanne.<br />Je commençai par une remarque qu’il avait faite au milieu de la conversation : « je suis d’un naturel triste et taciturne, je crois que tout va toujours mal tourner ».</p>
          <p>Mettre cette phrase au début, c’était aller à l’encontre d’une opinion très répandue sur la gaieté de la peinture de Miró, que Michel Ragon comparait à un Walt Disney de la peinture abstraite. Bien sûr j’ai adouci le propos de Miró en lui faisant ajouter que, s’il mettait de l’humour dans sa peinture, c’était inconsciemment pour se « débarrasser » de son opinion de la vie, qui lui paraissait totalement absurde.</p>
          <p>J’ai été surpris des objets qui retenaient son intérêt ; il insistait sur leur immobilité. Le fait qu’ils ne bougent pas suscitait en lui de grands mouvements. Il aimait aussi particulièrement le silence et le vide, il insistait beaucoup sur la puissance d’amplification du vide. Aujourd’hui, je pense que cet exemple aurait pu être d’autant plus dangereux pour moi que je partageais complètement son sentiment.</p>
          <p>Quant il m’a cité comme exemple du pouvoir d’amplification du silence les craquements des roues de bois d’une charrette au petit matin, j’ai cru les entendre. J’ai été enchanté qu’il me confie l’intérêt qu’il portait au climat visuel, aux usines, à ce que l’on voit quand on est dans un avion. « Même un petit chien, on le voit ». A cette époque, les avions ne volaient pas aussi haut qu’aujourd’hui.</p>
          <p>Son affirmation à propos des titres de se tableaux me hante toujours : « quand j’ai trouvé le titre d’un tableau, ce titre devient une réalité cent pour cent ». Cette déclaration de Miró m’a beaucoup aidé à travailler d’imagination. Je me dis, avant de dessiner ou de peindre : « je vais faire un centaure amélioré ou bien un altiste, ou bien un cycle vital, ou bien un hélicoptère anthropocéphalomorphe ; ou bien un avion libellulaire ». Tous ces titres sont pour moi comme disait Miró, des réalités cent pour cent.</p>
        </article>

        <div className="bonhomme"><img src={Bonhomme} alt="" /></div>

        <article>
          <div className="rencontre-pic"><img src={Giacometti} className="frame" alt="" /></div>
          <h3>Giacometti</h3>
          <div className="rencontre-pic rencontre-pic-mob"><img src={Giacometti} className="frame" alt="" /></div>
          <p>Giacometti et moi étions assis autour de la petite table ronde d’un café de la rue des volontaires, à quelques pas de son atelier situé rue Hippolyte-Maindron à Paris . La conversation roula tout de suite sur la maigreur de ses statues : pourquoi étaient-elles si hautes et si minces. Giacometti prononçait avec vivacité des phrases courtes. Après chaque phrase, un silence comme pour laisser aux mots le temps d’atteindre l’interlocuteur.</p>
          <p>« Je ne sais pas où Maillol a vu des femmes comme celles qu’il a sculptées. Non ? ». Un passant s’annonce au bout du trottoir. « Vous le voyez, non ? ». Il me fait constater que la silhouette d’un homme vue de loin offre deux dimensions : une très courte, c’est la largeur, l’autre très longue, c’est la hauteur. Il avait décidé depuis son adolescence de peindre exactement ce qu’il voyait. Or, s’il veut peindre, ou sculpter ce passant tel qu’il le voit, le personnage est non seulement mince, mais encore sa hauteur, à cette distance, qui n’est pas très grande bien sûr, ne dépasse pas deux centimètres. « J’en suis arrivé, me dit Giacometti, à faire des statues si petites que je pouvais les mettre dans une boîte d’allumettes ».</p>
          <p>« Pour faire de grandes statues reproduisant exactement ce que je vois, il faut que le modèle s’approche. Mais alors, je ne peux plus le voir d’un seul coup d’œil ». Je pense qu’à ce moment de mon interview j’ai dû me souvenir de mes propres théories. Le déplacement du regard devient une ligne dont l’épaisseur est nulle. La maigreur suggère cette ligne, semblable à celles dont s’inspire Giacometti. Cela ressemble aux mots dont Miro devait se servir plus tard : « une réalité cent pour cent ». Cette réalité était probablement celle que Giacometti tenait pour ce que l’on voit vraiment. Vu de près, le modèle se réduit à l’épaisseur ou à la largeur presque nulle de la ligne tracée par l’œil pour aller du pied à la taille et de la taille à la tête.</p>
          <p>« Les peintres de la Renaissance ne faisaient pas ce qu’ils voyaient. Ils faisaient ce qu’ils savaient, l’anatomie. D’autre part, ils se fondaient sur les données du toucher, non pas sur les données de la vue ». « Ce n’était pas le cas des byzantins, de Gréco. Eux montraient ce qu’ils voyaient ».</p>
          <p>(..) Un jour, sur l’un de mes dessins, il avisa une table et une chaise : « êtes-vous sûr, me demanda-t-il, que cette chaise était aussi près de la table ? « . Non, je n’en étais pas sûr ; ou plutôt, j’étais sûr du contraire : j’avais serré la chaise contre la table afin que l’un et l’autre figurassent dans mon image. Giacometti désapprouvait ce procédé emprunté aux primitifs. Au contraire des primitifs que je n’ai cessé d’admirer et d’imiter, il attachait plus d’importance au vide qu’au plein, surenchérissant sur Braque, Henri Laurens, Archipenko et Charlie Semser, qui se contentaient d’affirmer leur égale importance.</p>
        </article>

        <div className="bonhomme"><img src={Bonhomme} alt="" /></div>

        <article>
          <div className="rencontre-pic"><img src={PierreSoulages} className="frame" alt="" /></div>
          <h3>Pierre Soulages</h3>
          <div className="rencontre-pic rencontre-pic-mob"><img src={PierreSoulages} className="frame" alt="" /></div>
          <p>Un jour au salon de Mai, j’engageai conversation avec un peintre abstrait qui devait prendre une considérable importance dans l’art français. Je ne sais plus comment nous en vînmes à parler du problème posé par la volonté de d’exprimer le mouvement au moyen de cet objet immobile qu’est le tableau.</p>
          <p>Mon interlocuteur était indigné par les procédés employés par d’autres peintres abstraits pour le résoudre. J’avais vu des tableaux abstraits composés de morceaux de couleurs aux contours approximativement géométriques qui semblaient survolés par une ligne supposée expressive de la mobilité. « Non, non, me disait à peu près mon interlocuteur, cette ligne ne suffit pas. Si elle veut exprimer le mouvement, elle n'y parvient en aucune façon ». Je n'aimais pas non plus cette ligne : non seulement elle n'exprimait pas le mouvement, mais encore elle restait étrangère à la composition. Si j'avais osé, j'aurais employé une expression familière, la considérant « comme un cheveu sur la soupe ». Mais elle aurait risqué de faire croire à ce peintre abstrait, dont je voyais l'œuvre dans la pénombre de la vaste cave du musée, que je ne prenais pas son avis au sérieux. Il était plus grand et plus fort que moi, mais nous avions vraisemblablement le même âge. Dans sa voix, il y avait une chaleur agréable et comme une sorte de bercement.</p>
          <p>J'examinai, tout en parlant, l'aspect de son œuvre : j'y voyais de larges barres sombres se détachant sur un fond parfois assez clair. C'était comme une écriture privilégiant les pleins, ou comme les troncs de plusieurs arbres coupés qui tantôt se croisaient, tantôt se penchaient du même côté. Si chacune de ces barres pouvait être considérée comme un trait, comme une ligne artificielle dont l'artifice ostensible était la largeur, je pouvais penser que chaque barre avait un double contour, ce qui faisait deux lignes. Je pensai à mes parallèles qui, selon moi, étaient à l'origine du voyage de l'œil. « Oui, dis-je, une ligne ne suffit pas : il en faut deux. » Je ne sais pas très exactement ce que mon créateur de larges traits noirs pensa de ma réponse mais je sais que nous devinmes amis. Il| se nommait Pierre Soulages.</p>
        </article>

        <div className="bonhomme"><img src={Bonhomme} alt="" /></div>

        <article>
          <div className="rencontre-pic"><img src={CeresFranco} className="frame" alt="" /></div>
          <h3>Cérès Franco</h3>
          <div className="rencontre-pic rencontre-pic-mob"><img src={CeresFranco} className="frame" alt="" /></div>
          <p>
            « Une très importante rencontre dans ma vie fut celle de Cérès Franco, qui devait défendre ma peinture au point de l'exposer à sept reprises. Cérès, d'origine brésilienne, avait étudié l'histoire de l'art et la littérature à New York et commencé d'exercer, comme moi, le métier de critique d'art dans son pays en publiant notamment un grand nombre d'articles importants sur la vie artistique européenne. Elle a diffusé en France l'art populaire brésilien, en particulier des ex-voto en bois d'une qualité exceptionnelle aux yeux des spécialistes, et organisé des expositions qui la rendirent célèbre. Elle eut notamment l'idée originale de proposer à un grand nombre de jeunes peintres de talent d'exécuter des tableaux ovales, à un moment où, à Paris, dans les milieux d'avant-garde, se posait la question très importante du support de la peinture. En 1972, elle ouvrit la célèbre galerie L'Œil-de-bœuf - première galerie de la rue Quincampoix - où elle devait, vinght-cinq ans durant, manifester son indépendance, sa curiosité, son savoir et sa prescience, en exposant des artistes négligés des grandes galeries et qui devaient cependant devenir fameux par la suite. En achetant pour elle-même des œuvres aux artistes qu'elle présentait, elle a réuni au fil des ans une immense collection - installée dans un très beau village classNameé des Corbières, Lagrasse, dans l'Aude - témoignant de ses goûts, de ses pensées, de sa perspicacité critique et dont elle aime à dire qu'elle représente sa vie.
            <br />Aujourd'hui, au XXIe siècle, on parle beaucoup des singuliers de l'art : cette expression a été employée pour la première fois en 1978 (Arc 2, musée d'Art moderne de la Ville de Paris) et en 1988 par Catherine Huber dans le même musée, pour rendre hommage à l'activité de Cérès Franco. Je participai à cette manifestation avec Corneille, Chaïbia, Da Costa, Guimarès, Jaber, Lattier, Macréau, Mandive, Piciotto et Selassié. Dans le catalogue Naïfs, bruts, singuliers?, Cérès me qualifie de « fabulateur exacerbé ».
            <br />Douée d'une forte personnalité, cette grande découvreuse de talents, apparaît désormais aux côtés de Denise René, Iris Clert, Colette Allendy ou Ileana Sonnabend, comme l'une des plus brillante galeristes de la seconde moitié du XXe siècle. Une grande dame. »
          </p>
        </article>

        <div className="bonhomme"><img src={Bonhomme} alt="" /></div>

        <article>
          <div className="rencontre-pic"><img src={PierreRestany} className="frame" alt="" /></div>
          <h3>Pierre Restany</h3>
          <div className="rencontre-pic rencontre-pic-mob"><img src={PierreRestany} className="frame" alt="" /></div>
          <p>Pierre Restany (1930-2003) historien de l'art et critique d'art engagé, il contribue à fonder en 1960 le groupe des Nouveaux réalistes avec notamment Arman, Jean Tinguely, Jacques Villeglé, César, Niki de Saint Phalle, Cristo. Il n'a cessé d'interroger la nature et le sens de l'art dans la société post-industrielle.</p>
          <h4>Ce qu'il a écrit sur Yvon Taillandier</h4>
          <p>
            Yvon Taillandier, critique d'art, s'intègre totalmeent au climat de réflexion et de visitation
            d'une esthétique agressée par une culture de la barbarie qui vient à peine d'être remise en
            question. Le Salon de Mai sert de tribune à ce courant tumultueux de recherches artistiques
            menées sous le signe de la plus grande liberté individuelle. Les grands artistes consacrés
            avant-guerre viennent y retrouver ceux qui représentent le bilan affectif vis-àvis d'un
            événement aussi déterminant dans leur existence. Yvon Taillandier y observe le passionnant
            spectacle d'un retour à une conscience globale de l'être dans le respect des données médiates et
            immédiates de la nature. Salon après salon, le critique a su enregistrer les progrès de ct
            humanisme retrouvé et en porter témoignage à trvers ses propres investigations dans le domaine
            du surréalisme ou de l'expressionnisme d'après-guerre. Son respect pour l'homme créateur était
            sans failles et sa propre conviction était toute naturelle. Il a toujours voulu aller à l'essentiel
            d'une démarche sans se préoccuper des mouvements et des étiquettes.
          </p>
          <p>Et cette quête continue de l'authenticité de l'identité de l'individu dans le phénomène créateur,
            il l'a menée en cordiale ouverture au monde et snas mesquine rigueur. Cette affectivité humaine de
            base est une constante dans l'oeuvre d'Yvon Taillandier et est toujours présente lorsque l'écrivain,
            ou surtout le peintre, succède au critique. La charge humaniste trouve son écho dans les références
            mythiques. Lorsqu'en 1970 le critique a donné libre cours à sa vocation de peintre, il l'a assumée
            dans le cadre de la bande dessinée et de l'art pauvre, dans les monuments de carton et surtout dans
            la perspective de la « nocrida », cette nouvelle méthode d'analyse visionnaire, créatrice d'une tératologie
            en constante évolution. Les monstres mythiques issus de l'imaginaire de Taillandier incarnent toutes les
            contradictions et les espoirs d'une humanité qui se retrouve devant son propre miroir. Et cette
            mythologie est une mythologie de bon augure, un antidote contre le poison du malheur. Dans cette
            époque où la critique d'art, une fois débarrassée de la nostalgie du passé, a essayé de surmonter
            sa panique du changement et le pressentiment de l'avenir, Yvon Taillandier a su bâtir une mythologie
            dont les nouveaux dieux incarnaient les mythes d'un humanisme toujours remis en question.
          </p>
          <p>
            Et c'est à travers ce « délire tempéré » de l'imagination que se perpétue une véritable aura
            de la bonne fortune. Cette mythologie est porteuse d'une espérance infinie et je suis reconnaissant
            à Yvon Taillandier de nous donner de bonnes raisons de croire en l'homme.
          </p>
          <p className="italic">Pierre Restany, Paris, avril 2003</p>
        </article>

        <div className="bonhomme"><img src={Bonhomme} alt="" /></div>

        <article>
          <div className="rencontre-pic"><img src={LydiaHarambourg} className="frame" alt="" /></div>
          <h3>Lydia Harambourg</h3>
          <div className="rencontre-pic rencontre-pic-mob"><img src={LydiaHarambourg} className="frame" alt="" /></div>
          <p>Lydia Harambourg est historienne, critique d’art et écrivain, spécialiste de la peinture du XIXe et XXe siècle, particulièrement de la seconde Ecole de Paris. Depuis 1998, elle tient la chronique hebdomadaire des expositions dans La Gazette de l’hôtel Drouot.</p>
          <h4>Ce qu'elle a écrit sur Yvon Taillandier</h4>
          <p>
            Contour, mémorialiste, écrivain, et avant tout peintre, Yvon Taillandier est l'ordonnateur d'un
            univers extravagant, généreux, insolite, métaphorique.
          </p>
          <p>
            Unique, son monde, tel un miroir sans tain de la société moderne, est celui de la démesure
            où l'humour a sa part alors que son imagination ludique invente les Taillandier-landais,
            protagonistes d'un mythe personnel qui déborde dla surface de la toile, envahit les objets, prend
            possession de territoires, par le seul recours au délire graphique et à la jubilante couleur.
          </p>
          <p>
            Explorateur du sens, sa propension innée pour la narration entrelace les mots et les images
            dans des pérégrinations hybrides toujours recommencées. Metteur en scène de récits protéiformes
            dans lesquels s'affrontent des personnages en constantes métamorphoses, Yvon Taillandier explore
            le champ utérin et emprunte à l'Histoire ses thèmes inlassablement reconduits, abrités par
            l'implacable action du temps qui passe. A l'hybridation imagée de ses personnages siamois et
            bifaces, répond la syntaxe d'une mise en page qui joue sur les pages blanches, le bourgeonnement
            rythmique engendrant leur propre espace. Celui d'Yvon Taillandier créateur d'une parabole
            libératrice de fantasmes ayant censuré toute logique.
          </p>
          <p>
            Telle s'impose la figuration anthropomorphique. Une mise en abyme de la déformation du corps
            pour une utopie, dont l'expression picturale lui confère une place particulière dans l'histoire
            de la peinture contemporaine.
          </p>
        </article>

        <div className="bonhomme"><img src={Bonhomme} alt="" /></div>

        <article>
          <div className="rencontre-pic"><img src={LadislasKijno} className="frame" alt="" /></div>
          <h3>Ladislas Kijno</h3>
          <div className="rencontre-pic rencontre-pic-mob"><img src={LadislasKijno} className="frame" alt="" /></div>
          <p>Il est une figure majeure du mouvement de la peinture informelle. Ses premières recherches picturales portent sur le rapport du lisse et du froissé. Yvon Taillandier y voit une nouvelle ouverture picturale. Progressivement les formes ovoïdes et sphériques le conduiront vers l’abstraction.</p>
          <h4>Ce qu'il a écrit sur Taillandier</h4>
          <strong>La légende d'Y.T.</strong>
          <p>
            Dussé-je vous étonner, mais je trouve qu'Yvon Taillandier (Y.T.) ressemble à Toutankhamon, et
            quand il dessine sur son mystérieux calepin les structures d'un accrochage du Salon de Mai, avec
            une petite esquisse de chaque tableau et les indications afférentes, comme des petits poèmes,
            il a tout a fait l'allure du Scribe accroupi du musée du Louvre.
            <br />En ce qui concerne sa peinture, plus elle avance dans son indéchiffrable dédale, plus elle
            me fait penser à celle des parois de l'Egypte ancienne, sur craie et sur crépi, telles que j'ai eu
            la chance de les étudier, crayons en mains, dans les hypogées de la Haute Vallée des Rois. Même
            juxtaposition de l'écrit et de l'image. Même démiurgie zoo-anthropomorphique. Mêmes interférences
            entre les quatre éléments si bien psychanalysés par Bachelard : l'eau, la terre, le feu et l'air.
            <br />Au zénith, de cette cosmogonie, le majestueux astre du jour et des ténèbres scintille,
            innondant l'épiphanie de sa lumière de mercure. Oui, il y a, selon moi, toutes ces analogies
            entre la façon de peindre d'Y.T. et celle des créateurs inspirés traçant leurs signes d'éternité
            sur les murs funéraires en face de Karnak. A une différence près, c'est qu'Yvon Taillandier
            remplace les ibis volants par des aéroplanes, les chars de combat des guerriers de l'Empire par
            tanks de la division Leclerc et l'immuable image d'Osiris, à la mitre blanche et à la barbe
            tressée, par celle de Jérsus-Christ, Fils de Dieu, enseignant à l'homme nouveau qu'il est plus
            difficile à un riche d'entrer dans le Paradis qu'à un chameau d'entrer dans le trou d'une
            aiguille. Je ne puis m'empêcher de ressentir chez Y.T. et ses lointains ancêtres nilotes un
            même besoin herméneutique de déifier les sublimes figures des reines longilignes, en même
            temps épouses, mères, amantes et soeurs, étroitement accouplées à des pharaons impassibles à
            tête d'oiseau de proie.
          </p>
          <p>
            Et, au centre de cette saga métaphysique, dans un cartouche gravé, aux lèvres du calcaire,
            l'oeil astral d'Osiris, à quatre dimensions, transperce l'espace des nuits solaires jusqu'à
            l'inquiétant trou noir des mondes inversés où la vie et la mort se rejoignent sans fin.
          </p>
          <p>
            Maintenant, procurez-vous une reproduction d'un tableau d'Y.T. et rendez-vous à la
            section égyptienne du Louvre, comparez cette image avec une peinture de la XVIIIe dynastie,
            vous verrez, comme moi, qu'il y a de troublantes corrélations.
          </p>
          <p>
            Par la même occasion, arrêtez-vous devant une tapisserie copte du VIe siècle après J.-C.
            et vous y découvrirez aussi quelques liens avec Y.T. en n'oubliant surtout pas que l'art copte
            a peut-être puisé ses sources des collines de Thèbes jusqu'au désert de Nubie.
          </p>
          <p>Voilà ce qui me semble l'un des fils conducteurs de l'aventure picturale d'Y.T.</p>
          <div><img className="pharaon" src={pharaon} alt="" /></div>
          <p>
            Nous sommes loin, ici, des autoroutes de l'histoire de l'art où les jugements esthétiquement
            corrects filent à toute vitesse vers des certitudes sans lendemain. Je préfère la légende. Nous
            pourrions en rester là. L'essentiel est dit.
          </p>
          <p>
            J'ai tout de même envie de descendre, à la façon de Cézanne, un peu plus profondément
            encore vers les assises du monde d'Yvon Taillandier et de sa spéléologie mentale. Je me suis, pour
            cela, livré à une petite enquête dont je vais essayer de vous indiquer quelques pistes d'accès.
            Mais j'aimerais, avant, vous éclairer sur ce que furent nos rapports avec Y.T. depuis près de
            cinquante ans. Nous avons mené, Yvon et moi, avec Jacqueline Selz, Gaston Diehl et tous les
            camarades du Salon de Mai, un très difficile combat dans le périlleux maquis de la culture et
            de la politique. J'estime donc le connaître tout particulièrement, d'autant plus qu'une profonde
            amitié nous unit.
          </p>
          <p>
            Y.T. est une véritable encyclopédie : écrivain, critique, poète, peintre, sculpteur,
            essayiste, voyageur infatigable, aventurier de l'âme. Il aurait donc pu subir, par le fait de son
            érudition, et sans s'en apercevoir, quelques influences de la gnose des premières dynasties qui
            auraient pu laisser quelques traces cicatricielles dans son oeuvre. Je crois à une version tout
            à fait différente de son processus alchimique.
          </p>
          <p>
            J'en reviens maintenant à mon enquête. J'ai lu, il n'y a pas très longtemps, un article
            assez curieux concernant les modalités de création et de réalisation des pictogrammes pariétaux
            dans les tombes de la Haute Egypte. J'y ai appris que leurs merveilleux créateurs, artistes et
            artisans, dont certains, paraît-il, auraient eu de liens de parenté avec les Pharaons, donc ces
            artistes et artisans vivaient ensemble dans des petits villages spécialement conçus pour eux et
            leurs familles. Dans ces village, dit-on, une intense activité sociale et culturelle s'était
            établie entre eux, avec un sens profond de la solidarité et de l'organisation : un peu comme
            cela s'est passé, beaucoup plus tard, dans le phalanstère des Salines d'Arc-et-Senans de Nicolas
            Ledoux. Cette solidarité y était même tellement étroite qu'il y eut de grands mouvements
            d'agitation sociale et des grèves à répétition, tout cela poussé à un tel degré de paroxysme qu'un
            certain nombre de ces créateurs décidèrent de foutre le camp des villages et des chantiers de
            travail tellement ils en avaient marre de crever de faim et d'être si peu payés pour leurs
            incomparables icônes d'éternité, pendant que leurs patrons et mécènes, à tête d'or et de saphir,
            bouffaient et partouzaient sans vergogne sur des montagnes de fric. Ils remontèrent jusqu'à
            l'estuaire du Nil et certains s'y installèrent qui devinrent, peut-être, les ancêtre des Coptes.
            D'autre traversèret la Méditerranée jusqu'aux rives de la Gaule avec toute leur smala et leur
            mythologie, sans oublier, bien évidemment, leurs petites flûtes de roseau dont les trilles
            limpides leur rappelaient le chant des rossignols de Louxor. Fin de l'histoire.
          </p>
          <p>
            Quelques irréductibles, épuisés par l'exil, et probablement avides de fraîcheur,
            s'arrêtèrent dans les montagnes d'Auvergne, où ils firent souche près des volcans éteints. Ils
            provoquèrent dans les vallées, malgré leur petit nombre, un incroyable mouvement de métissage
            et d'exotisme qui aurait réjoui Segalen qui a si bien écrit sur le sujet à propos des Marquises
            et de l'Extrême-Orient. Il apparaît alors, le soir à la veillée, prodigieux conteurs, en
            mangeant des châtaignes, dans un état second, monodiaient des stances incantatoires où il était
            question d'un paradis perdu, sur les rives d'un long fleuve fertile, aux portes de l'Afrique.
            <br />Je n'en sais pas plus. Là s'arrête mon enquête.
          </p>
          <p>
            Maintenant, je laisse courir votre imaginaire, en vous donnant enfin le joker essentiel.
            Quand, au début de mon récit, je vous disais qu'Y.T. ressemblait à Toutankhamon et (je crois
            même qu'en vieillissant, il lui ressemble de plus en plus), j'avais oublié de vous préciser
            qu'il était auvergnat comme son grand-père, célèbre conteur d'histoires fantastiques où les
            reines étaient belles comme des déesses brunes et où les rois arrogants avaient des têtes
            d'oiseaux de proie.
          </p>
          <p>
            Je ne suis pas loin de croire que ces personnages de légende, Y.T. et son aïeul, sont
            les derniers descendants de ces artistes et artisans grévistes de la Vallée des Rois. Au temps
            des pharaons.
          </p>
          <p className="italic">Kijno, juin 2005</p>
        </article>

        <div className="bonhomme"><img src={Bonhomme} alt="" /></div>

        <article>
          <div className="rencontre-pic"><img src={JeanPierreRaynaud} className="frame" alt="" /></div>
          <h3>Jean Pierre Raynaud</h3>
          <div className="rencontre-pic rencontre-pic-mob"><img src={JeanPierreRaynaud} className="frame" alt="" /></div>
          <p>Artiste plasticien proche des Nouveaux réalistes, il s’intéresse aux objets dans leur potentiel psychique et intime et choisit des matériaux de rebut. Ses premières œuvres comportent : le sens interdit, les couleurs rouge et blanche ou le pot de fleur bétonné, peint en rouge et marqué du chiffre 3, qui deviendra son objet fétiche.</p>
          <h4>Ce qu'il a écrit sur Taillandier</h4>
          <p>
            Au début des années 60, le jeune artiste que j'étais, recherchait éperduement la confrontation
            avec les maîtres de l'époque et pas n'importe quels maîtres : de Picasso à Yves Klein... C'est
            dans ce contexte au Salon de Mai que j'ai vécu mon baptême du feu.
          </p>
          <p>
            Yvon, à part le fait d'être mon aîné, était surtout en la personne du critique d'art ce qu'un
            artiste espérait et redoutait le plus, c'est-à-dire le « verdict ».
          </p>
          <p>
            On sentait déjà chez lui cet esprit indépendantiste qui ne le quittera plus ; le plus beau
            cadeau qu'il fit à ma démarche naissante fut d'inventer et d'offrir à mes assemblages cliniques
            le terme de <span className="italic">psycho-objet</span> qui accompagne encore aujourd'hui
            mon expression des années 60.
          </p>
          <p>
            Son regard sur les autres allait développer une réflexion personnelle come un prolongement de
            l'écriture en se trouvant au carrefour des signes, un visuel naissant faisant apparaître des
            personnages qui comme les notes d'une partition développaient une symphonie.
          </p>
          <p>
            Ce que j'aime par-dessus tout chez un Matisse, c'est sa capacité dans les papiers découpés de
            nous transmettre cette part d'innocence construite sur l'intelligence, car faire l'enfant,
            il ne suffit pas de le dire.
          </p>
          <p>
            Yvon nous livre, grâce à l'expression de son lagage artistique, une confiance en l'homme dont
            je suis personnellement bien incapable. L'enfance à laquelle nous rêvons tous, heureusement
            n'est jamais loin pour nous rapprocher.
          </p>
          <p className="italic">Jean Pierre Raynaud, 18 janvier 2005</p>
        </article>

        <div className="bonhomme"><img src={Bonhomme} alt="" /></div>

        <article className="to-do">
          <div className="rencontre-pic"><img src={OlivierOrtega} className="frame" alt="" /></div>
          <h3>Olivier Ortega</h3>
          <div className="rencontre-pic rencontre-pic-mob"><img src={OlivierOrtega} className="frame" alt="" /></div>
          <p>
            Texte sur Olivier que Papa va fournir sur l'exposition de l'Abbaye et sur le site de France 3.
          </p>
        </article>

        <div className="bonhomme to-do"><img src={Bonhomme} alt="" /></div>

        <article className="to-do">
          <div className="rencontre-pic"><img src={LaurentChabas} className="frame" alt="" /></div>
          <h3>Laurent Chabas</h3>
          <div className="rencontre-pic rencontre-pic-mob"><img src={LaurentChabas} className="frame" alt="" /></div>
          <p>
            Laurent et Yvon font connaissance en 2008 à l'occasion de toutitouta. Laurent est aujourd'hui
            l'organe principal de l'association des Amis d'Yvon Taillandier. Il est le contact principal des
            personnes s'intéressant à Yvon Taillandier, organise sa présence sur des salons et expositions
            et est en charge du contenu du site officiel d'Yvon Taillandier que vous visitez en ce moment-même.
          </p>
          <p>
            Laurent Chabas a mené en
            2016 <a className="link" href="https://dartagnans.fr/fr/projects/la-fresque-de-l-ambassade-du-taillandier-land/campaign" target="_blank" rel="noopener noreferrer">
            une campagne de crowdfunding sur la plateforme Dartagnans</a> afin de financer la restauration de la
            peinture murale réalisée par Yvon Taillander en 19XXX sur la façade de son atelier rue de l'Agent
            Bailly dans le 9ème arrondissement de Paris.
            <br />Au terme d'une campagne parfaitement exécutée, Laurent aura réussi à mobiliser les donateurs
            et réunir les fonds nécessaire. La façade a pu être restaurer courant octobre 2018 par le
            prestigieux cabinet XXXXXXXXXXXXXXX. Vous trouverez dans la section des actualités le résumé de
            cette restauration en six épisodes dont voici un résumé en vidéo :
          </p>
          <p>METTRE ICI LA VIDEO DE LA RESTAURATION DE LA PEINTURE MURAL DE L'ATELIER D'YT</p>
          <h4>Ce qu'il a écrit sur Yvon Taillandier</h4>
          <p>
            A l'occasion de la publication du numéro spécial du magazine Connaissance des Arts consacré à Yvon
            Taillandier en décembre 2018, Laurent a rédigé une critique de l'oeuvre de Taillandier que nous
            retranscrivons ici.
          </p>
          <p>METTRE ICI LA CRITIQUE REDIGEE PAR LAURENT CHABAS</p>
          <p className="italic">Laurent Chabas, octobre 2018</p>
        </article>
      </div>
    </div>
    <Footer />
  </div>
);

export default RencontresDeYT;
