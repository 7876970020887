import React from 'react';
import Footer from './Footer';
import imgOriginesDuSang from '../img/yt-les-origines-du-sang.svg';
import afficheExpo2005 from '../img/taillandierland/yt-expo-2005.svg';
/* Importing all images from img/taillandierland folder */
function importAll(imgs) {
  let images = {};
  imgs.keys().map((i, index) => {
    return images[i.replace('./', '')] = imgs(i)
  });
  return images;
}
const images = importAll(require.context('../img/taillandierland', false, /\.(png|jpe?g|svg)$/));

const TaillandierLand = props => (
  <div className="page">
    <div className="container">
      <div className="taillandier-land">
        <h1>Voyage au Taillandier-land</h1>
        <p>
          Les historiens taillandier-landais ont entrepris de raconter l'histoire de mon pays imaginaire. Je l'ai
          crée en m'adonnant à la littérature, à la peinture, à la sculpture et à l'architecture peinte sur carton,
          sur bois ou sur ciment.
        </p>
        <p>
          <img className="float-right" src={images['yt-56.svg']} alt="" />
          Ces historiens, qui vivent au Taillandier-land, ont commencé par étudier les variations de la population;
          ils ont noté ses progrès, relevé ses simplifications ou complications et enregistré les grands événements
          du pays. Tout d'abord, ils ont pris en considération l'invention du « Centaure amélioré », lorsque j'ai
          écrit la nouvelle intitulée <span className="italic">Le mille-pattes</span>. Je reconnais que certains de mes
          personnages ont des propriétés dont ils ne sont pas les seuls au monde à bénéficier. Par exemple, ils
          poussent comme des plantes. D'autre part, certains savants disent que l'atmosphère du Taillandier-land
          est nourrissante et qu'il suffit aux gens de respirer pour grandir, ce qui est plus étonnant mais il est
          de mon devoir de me donner des libertés.
        </p>
        <p>
          Dans certains cas, mes créatures se nourrissent du lait de géantes, que je nomme les « grandes déesses ».
          Celles-ci sont « polymastes ». Je dois la connaissance de cet adjectif à Robert Couturier, l'un des
          sculpteurs caligraphes ou « maigres » des années 40 et 50, avec Giacometti et Germaine Richier. Ce bel
          artiste, homme lettré, m'a instruit de telle sorte que je sais maintenant ce que sont les Vénus
          « polymastes ». Celles-ci ont plus de seins que la nature n'en donne ordinairement aux personnes du sexe
          féminin.
          <br />La plus célèbre Vénus polymaste ne se nomme pas Vénus. Elle se nomme Diane et se situe à
          Ephèse. Son corps est couvert de seins. Il en existe plusieurs versions sculptées. L'une d'elles est
          un cône entièrement couvert de mamelles et son aspect humain a complètement disparu. Ce qui ne m'a
          pas empêcher d'engager une conversation avec elle : « Diane, lui ai-je dit, Diane, qui devriez vous
          appeler Vénus, d'où vient que vous ne portiez pas ce nom ?
          <br />- Monsieur, me répondit-elle, cela vient de ce que je suis à l'origine de toutes les choses et
          de tous les êtres. En qualité de Diane, je suis vierge, si l'on suit une certaine tradition. Ma virginité
          signifie que je n'ai eu besoin d'aucun homme pour concevoir et accoucher du monde. Je ne suis pas une
          créature, comme votre population taillandier-landaise. Vous-même me devez la vie, car je suis la grande,
          l'immense créatrice de tout. »
        </p>
        <div className="img-center"><img src={images['yt-128.svg']} alt="" /></div>
        <p>
          J'ai rapporté aux historiens ma conversation avec la Diane d'Ephèse. « Les maristes, me dirent-ils,
          attribuent à la vierge Marie ce dont votre Diane d'Ephèse se prévaut.
          <br />- Bien, dis-je, passons à un autre aspect des rapports de certains taillandier-landais avec
          les grandes déesses. » Nous abordâmes alors les conséquences du bon sein et du mauvais sein.
        </p>
        <p>
          Les historiens admirent sans difficulté qu'elles avaient joué un rôle important dans mon pays imaginaire,
          qu'il existe plusieurs tableaux où l'on voit une grande déesse dont le sein produit du lait qui se durcit
          et devient un petit personnage. Celui-ci grandit et, à peine a-t-il fait quelques pas qu'il est amené à se
          nourrir du lait d'un autre sein de la grande déesse. Cette mamelle est la mauvaise mamelle. Le lait
          qu'elle fournit détruit. Les personnages qui l'ont bu tombent en morceaux qui sont avalés par une tête
          supplémentaire de la divinité. Que deviennent ces morceaux ? Sans doute sont-ils digérés et deviennent-ils
          du lait qui, selon que ce soit le bon sein ou le mauvais qui le produit, est ou bien créateur ou bien
          destructeur.
        </p>
        <p>
          « Oui, dis-je, je vous remercie, messieurs : si l'on me dit ici, dans l'Alterorbe, l'autre monde, pour vous,
          taillandier-landais, que notre nation est invraisemblable, parce que nous ne connaissons pas le mal, je
          parlerai de ces grandes déesses bonnes ou mauvaises.
          <br />-Vous auriez raison, me répondirent les historiens, car ces déesses sont justement des personnes
          dont les qualités et les défauts sont actuellement pour nous des objets de réflexion et aussi de
          discussion. »
        </p>
        <img className="float-right img151" src={images['yt-151.svg']} alt="" />
        <p>
          Je hochai la tête, pendant qu'en temps utile, j'y réfléchirais et en discuterais avec moi-même. En
          attendant, les historiens et moi, nous reprimes notre conversation sur les centaures et les centaures
          améliorés. Ils se sont mis à parler d'un certain dessin fait la veille ou peut-être l'avant-veille du jour
          où je me suis remis à dessiner et à peindre. J'avais pris une grande feuille de papier format raison (50
          cm de large sur 65 cm de haut), sur laquelle j'ai commencé avec un crayon à tracer la silhouette d'un
          centaure très amélioré comptant de très nombreuses jambes. Les historiens pensent que ce geste fut l'acte
          initial, voire initiatique. Je plaçai ce premier centaure très amélioré en bas de la page, car j'avais mon
          idée. Je traçai un second centaure un peu moins amélioré, car il possédait un peu moins de jambes que le
          premier. Je le plaçai de telle manière que ses pieds reposent sur le dos du premier centaure. J'entrepris
          ensuite de faire le portrait d'un troisième centaure, encore moins amélioré que le second, posé sur le dos
          de son prédécesseur; je commençais ainsi une pyramide qui, à son avant-dernier niveau, ne présentait plus
          qu'un centaure pauvre pourvu de seulement quatre jambes. Sur ce quadrupède, je posai un trocébibi.
        </p>
        <p>
          Sans les historiens de l'Académie des belles-lettres et des sciences du passé de Midiville, capitale du
          Taillandier-land, j'aurais oublié ce premier dessin que j'ai achevé en traçant les contours d'un
          trocébibi, au sommet d'une pyramide centaurique. En effet, aussitôt après l'avoir facilement exécuté,
          je dis des dessins beaucoup plus petits où j'entrepris de raconter mes histoires pyramidales ou
          triangulaires, les deux qualificatifs convenant aussi bien l'un que l'autre, car la même idée
          d'agrandissement ou de rétrécissement progressifs est aussi bien visualisée par un triangle que par
          une pyramide.
        </p>
        <p>
          Il y a quelques pyramides au Taillandier-land. Sur chacune de leurs faces, on peut voir une suite de
          dessins. En haut, vers la pointe, une forme solitaire, le ventre par exemple; un peu plus bas, le même
          ventre et, à côté, une poitrine sans bras. Plus bas, on aperçoit un ventre, la poitrine sans bras et,
          plus loin, les deux bras qui devraient la compléter. Et ainsi de suite jusqu'en bas, où l'on voit un
          trocébibi coupé en morceaux qui, alignés, constituent la plus longue ligne de la décoration du
          monument.
        </p>
        <div className="float-left">
          <img src={images['yt-152.svg']} alt="" />
          <p>
            Pourtant, ni les trocébibis, ni les centaures, ni les centaures améliorés, ne m'ont suffit pour peupler
            mon pays imaginaire. Très vite viennent les amoureux de la hauteur, nommés successivement Amedelaho et
            Altistes. Perfectionnement du centaure amélioré, l'horizontaliste. Pour sortir du combat de la verticale
            et de l'horizontale, apparaissent les personnages dichotomiques qui fournissent des obliques; peu après,
            se multiplient les personnages associés par un tube associatif partant d'un des personnages et
            aboutissant à un autre. Parfois, le tube mène plus loin son pouvoir associateur et sa puissance de liaison
            unit toute une assemblée. Le tube associatif a une histoire en trois chapitres. Premier chapitre : né
            d'une chaîne de têtes, il est très gros et rigide; deuxième chapitre : il perd sa grosseur et sa rigidité;
            il est comparable à un fil souple qui sinue de haut en bas, ou de gauche à droite, ou obliquement. Bien que
            s'appuyant sur le cadre et sur les diagonales, tous ces personnages risquent de produire ce que j'ai
            d'abord appelé un morcellement, ce que je nomme aujourd'hui une fragmentation et un désordre. Pour
            rétablir l'ordre par le contraste, j'ai brièvement fait appel aux éléphants et notamment aux
            éléphants améliorés qui ont plus de quatre pattes et possèdent plusieurs troncs. Comment le
            Taillandier-land n'abrite pratiquement pas d'animaux, j'ai remplacé les éléphants par de grosses têtes
            sans corps, portées toutefois par deux jambes. Ce sont les capitipèdes ou têtes à pieds. Je ne me suis
            pas contenté de gros capitipèdes; j'en ai fait aussi de petits qui se logent dans des espaces réduits.
          </p>
        </div>
        <p>
          J'en reviens au dernier chapitre dans l'histoire des tubes associatifs. Celui-ci est récent. Les tubes
          ne servent pas toujours ou pas seulement à associer : on les utilise aussi comme porteurs, les tubes
          passant sous les personnages et devenant des tapis volants. Les automobiles anthropomorphisées tirent
          la langue; cette langue peut remplir la fonction de tubes porteurs, se glisser sous les roues et,
          devenue elle aussi tapis volant, conduire l'automobiliste où il veut sans qu'il ait à mettre en marche
          son moteur. Les tubes ont aussi un effet, enveloppant, ils peuvent faire le tour de la composition et la
          protéger des agressions du monde extérieur.
        </p>
        <div className="img-center"><img className="img76" src={images['yt-76.svg']} alt="" /></div>
        <p>
          Au début, la population défilait sur une route parfaitement plate surmontée par un paysage. Maintenant, la
          route et le paysage ont disparu : tout vole.
        </p>
        <p>
          Au Taillandier-land, il y a de nombreuses religions dont une qui s'appelle le « marisme » : elle est
          assez récente mais était annoncée depuis longtemps par les grandes déesses qui créaient le monde avec
          leur lait. Le marisme ou culte de Marie créatrice du monde est avant tout une théorie féministe.
          Il n'en est pas de même du Christ taillandiérisé qui a donné naissance à une série de tableaux que l'on
          peut grouper sous le titre de « Vie du Christ dans le Taillandier-land ». Il est intéressant pour moi
          de me comparer à la multitude des grands anciens, de me rapprocher d'eux en traitant les mêmes sujets.
          J'entrepris donc d'illustrer les évangiles, du moins la partie des évangiles dont j'avais gardé
          mémoire : la multiplication des pains, la résurrection de Lazare, la crucifixion, la salutation de l'ange
          Gabriel, le sermon sur la montagne : « Heureux les pauvres d'esprit », l'entrée à Jérusalem. A propos de
          celle-ci, je dois dire que j'ai hésité entre deux solutions. Tout d'abord, j'ai fait entrer le
          Christ sur un âne, comme le veut la tradition, ensuite j'ai pensé qu'il ne suffisait pas de dire que
          cette arrivée s'est produite il y a vingt siècles. L'âne nous renvoie à un lointain passé. Or le Christ
          est éternel. Il est aussi pleinement aujourd'hui ce qu'il a été autrefois. Dans ma religion, il est là
          maintenant, ici-même. Pour indiquer cette présence, autour de nous, j'ai remplacé le Christ sur un âne
          par un Christ conduisant une modeste 2-chevaux et j'ai montré les saintes femmes juchées sur des souliers
          à talons aiguilles, vêtues de minijupes et de corsages ne dissimulant nullement l'importance de leur
          poitrine.
        </p>
        <p>
          Pour la résurrection de Lazare, j'ai inventé le Christ au bras long. Saint-Lazare est déjà enterré dans
          une montagne. Le bras du Christ se crée un chemin à l'intérieur de celle-ci qui affecte la forme d'un
          grand triangle. Dans une sorte de caverne, on voit les morts anciens réduits à l'état de squelettes et
          Lazare possédant encore sa peau et ses vêtements. Il est à genoux, les mains jointes, et semble en
          prière. La main du Christ le saisit et l'on doit comprendre qu'il va le retirer des ténèbres et lui
          permettre de rouvrir les yeux pour voir le jour.
        </p>
        <p>
          Une autre innovation de <span className="italic">La vie du Christ dans le Taillandier-land</span> est
          l'avion de la sainte Trinité qui est piloté par un personnage à trois têtes : une tête pour Dieu le
          Père, une tête pour le Saint-Esprit et une tête pour le Christ. Celui-ci, possède deux corps et
          une autre tête lui permettant d'être à la fois dans l'avion et sur la terre. Un tube associatif relie
          sa tête avionnée à sa tête terrestre.
        </p>
        <img src={images['yt-153.svg']} className="float-right" alt="" />
        <p>
          C'est ainsi que j'ai imaginé le Credo. D'autres tableaux montrent le dieu aviateur tricéphale tenant
          une paire de ciseaux dont il se sert pour couper le cordon associatif qui le relie au personnage de
          Jésus-Christ assumant sa vie et sa mort sur Terre. Le coup de ciseaux correspond au « Pourquoi
          m'as-tu abandonné ? » que le Fils de Dieu adresse à son Père dans les évangiles :
          <span className="italic">Lama, lama, sabachtani</span>, seule phrase d'araméen, langue parlée par
          Jésus et dont nulle autre trace ne se trouve dans les textes établis par les quatre évangélistes.
        </p>
        <p>
          Ni ceux-ci, ni le Christ, si grand soit-il en tant que Personne de la saint Trinité, n'ont envahi le
          Taillandier-land définitivement. Ils n'en sont pas exclus et probablement y reviendront-ils. Toutefois,
          ont été plus tenaces des envahisseurs qui, vers 1980, se sont présentés comme des cubistes et des
          robots. Des cubistes, parce que leurs corps était composés d'une association de cubes. Leurs
          adversaires, la population ordinaire du Taillandier-land, pour s'opposer à ces cubistes qui n'avaient
          que peu de choses en commun avec les peintres de ce nom, prirent la dénomination de boulistes bien
          qu'ils ne prétendirent pas ressembler aux joueurs de boules. Pour que finisse la guerre entre
          boulistes et les cubistes, j'ai aidé les boulistes à construire des robots boulistes leur permettant
          de résister aux robots cubistes. Ce que voyant, les cubistes engagèrent des pourparlers de paix.
        </p>
        <p>
          J'ai congédié tous les personnages composés de figures géométriques sauf quelques-uns auxquels j'ai
          demandé de renoncer à leur aspect gigantesque.
        </p>
        <p>
          Ces privilegiés, avec leur accord, j'en fis des pierres. Cette métamorphose aurait pu leur paraître
          humiliante mais je les situai parmi celles qui constituent la voûte céleste du Taillandier-land.
          De plus, ces pierres de la voûte céleste se changent progressivement en avions, comme on peut le voir
          dans la composition intitulée : <span className="italic">Les origines du sang au Taillandier-land</span>.
          Ces avions volment dans le tableau dans un espace de quelques centimètres puis se précipitent dans la
          bouche ouverte d'un géant qu'en langue taillandier-landaise on nomme un sanguin.
        </p>
        <div className="img-center origines-sang">
          <img src={imgOriginesDuSang} alt="" />
          <p>
            <strong>Les origines du sang</strong>, 1990-1991, <span className="italic">acrylique sur toile, 114 x 146 cm</span>.
            <br />Pour le savants du Taillandier-land, il n'y a pas de coeur et les globules sont jambés et
            même motorisés.
          </p>
        </div>
        <p>
          En fait, les sanguins taillandier-landais sont les seuls qui aient du sang; les autres, femmes ou
          hommes, n'ont pas besoin de sang ou, s'ils en ont, c'est parce qu'ils sont snobs et qu'ils veulent
          faire croire qu'ils sont comme nous qui sentons battre notre coeur.
        </p>
        <p>
          D'ailleurs, les sanguins - je m'en suis aperçu récemment - n'ont pas de coeur, ce qui ne veut pas
          dire qu'ils soient méchants.
        </p>
        <p>
          A propos de la circulation du sang, j'aime à penser qu'il existe à ce sujet trois théories : la
          théorie cartésienne selon laquelle le sang chauffe, ce qui le fait tourner dans nos artères et
          nos veines; la théorie de Harvey selon laquelle notre sang circule par suite de la contractilité
          de notre coeur qui agit comme une pompe; et enfn la théorie des cardiologues taillandier-landais.
          Cette théorie, déjà ancienne, remonte comme les théories de Harvey et de Descartes au XVIIe siècle.
        </p>
        <div className="float-right voyageoeil"><img src={images['yt-154.svg']} alt="" /></div>
        <p>
          A cette époque, les physiologies taillandier-landais disaient que si le sang des sanguins circulait,
          c'était que les globules du sang étaient jambés et qu'ils couraient dans les canaux sanguins sans
          jamais se lasser, aimant cet exercice au-delà de toute expression. Depuis ce temps, la théorie a
          changé, parce que les choses taillandier-landaises ont changé : sont apparus à la fin du XVIIIe
          siècle les vélocipèdes, puis un peu plus tard les bicyclettes et les tandems plus ou moins améliorés;
          au XIXe siècle, les locomotives et les chemins de fer ont effrayés les poètes; au XXe, ils les ont
          enchantés, comme le prouve Blaise Cendrars avec sa « Prose du Transsibérien ».
        </p>
        <p>
          Tenant compte de toutes ces nouveautés, les physiologistes taillandier-landais ont affiné leurs
          observations; en outre, ils ont bénéficié des données de la radiologie. Ils ont constaté que,
          si certains globules sanguins continuaient de courir dans les artères et les veines, il y en
          avait d'autres qui allaient à bicyclette. Ils se demandèrent s'il y aurait des chemins de fer
          dont les wagons transporteraient des globules sanguins assumant la condition de voyageurs,
          de contrôleurs et de mécaniciens. Ils ne découvrirent rien de tel. Toutefois, peu de temps plus
          tard, ils aperçurent des globules sanguins conduisant des automobiles. D'abord, ce furent  des
          autos particulières, puis on découvrit des autocars, des camions. Passa encore un peu de temps
          et se manifestèrent aux yeux des observateurs physiologistes des aéroplanes, des dirigeables et
          des hélicoptères. Je n'aipas entendu parler de fusées. Mais il n'y a plus de doute : les
          globules sanguins sont motorisés et parfois volants. Sans doute la circulation du sang en
          est-elle améliorée.
        </p>
        <p>
          Les snobs en tirent argument pour justifier leur snobisme. « Vous voyez, disent-ils, notre
          snobisme a parfois du bon. - Sans doute, leur répondent leurs adversaires, mais vous causez
          aussi beaucoup de désordre. » En effet, alors qu'au Taillandier-land, on ne connaîtrait ni
          la mort, ni la maladie, ni les accidents, les snobs font, par snobisme, venir des apothicaires,
          des médecins, des croque-morts, construire des cimetières et des hôpitaux qui n'auraient aucune
          nécessité sans leur exigence, certains disent leur folie.
        </p>
        <div><img className="float-right" src={images['yt-155.svg']} alt="" /></div>
        <p>
          Je me serais bien contenté d'améliorer l'anatomie des Taillandier-landais. Mais ces fous de
          snobs me firent entendre raison. L'univers n'est pas seulement peuplé d'humains et
          d'animaux; il accueille aussi une grande quantité de machines. Pour cetains, comme pour les
          habitants du pays que Samuel Butler a imaginé, Erewhon, les machines sont redoutables et se
          préparent patiemment à nous supplanter. Je ne partage pas cette crainte. Les snobs me l'ont
          rappelé. Ils m'ont faut souvenir que je suis l'auteur de l'enquête sur le climat visuel.
          « Cette enquête avait pour but, me disent-ils, de faire en sorte que les artistes prennent
          conscience de l'originalité de l'environnement moderne. Vous devez nous donner raison si nous
          vous demandons d'introduire en quantité au Taillandier-land des automobiles, des aéroplanes,
          des hélicoptères, des bicyclettes, des tandems, pour vos centaures améliorés. Nous avons vu
          avec plaisir que vous avez attribué à un centaure amélioré un tandem muni de cinq pédaliers
          permettant aux dix jambes de celui-ci de s'activer avec allégresse !! Bravo, Tailleprin,
          bravo ! Continuez ! ». C'est ainsi que j'ai humanisé les machines. Lors de l'une de mes
          dernières visites à Picasso, celui-ci se plaignit des communistes de Moscou qui lui
          reprochaient de mécaniser la personne humaine. Je lui répondit qu'il devait leur répliquer
          qu'il humanisait les machines. Il sourit et nous nous serrâmes la main. Le conseil que je lui
          ai donné, c'est moi qui l'ai suivi.
        </p>
        <p>
          Les snobs taillandier-landais, en m'appelant Tailleprin, un des titres au moyen desquels on
          m'honore au Taillandier-land, me flattèrent et m'encouragèrent à peindre des automobiles. J'en
          peignis, j'en peins encore et je pense en peindre beaucoup, car j'y prends grand plaisir.
          J'étudie même en ce moment, dans le secret de mes laboratoires de recherche, la possibilité
          de peindre des véhicules pour n'aller nulle part...
        </p>
        <h3>La grande bataille de la ligne et de la couleur (ou de l'hostilité à l'association)</h3>
        <p>
          La grande bataille de la ligne et de la couleur est considérée par certains commentateurs
          taillandier-landais et par certains critiques d'art de Midiville, qui écrivent dans les grands
          journaux de la capitale de mon pays imaginaire - <span className="italic">Midiville Presse,
          Midiville Soir, Nouvelles du Taillandier-land</span> - comme une bataille de symboles.
        </p>
        <p>
          Certaines lignes très fines et très dangereuses, écrivent-ils, symbolisent la mort; les couleurs
          au contraire, symbolisent la vie et l'Eden. Ils s'expliquent longuement sur ce sujet. Certains
          des critiques s'attardent sur la définition de la mort. En elle-même, elle n'est pas vraiment
          distincte de la naissance : celle-ci est notre première mort, la mort du foetus que nous avons
          été. J'ai suggéré aux théoriciens du graphico-chromisme taillandier-landais de citer Ernst
          Junger : « La mort est notre plus ancien souvenir. » Et, en effet, la mort n'est pas ce que voulait
          Confucius - le dernier événement de notre vie - mais le premier et peut-être le plus terrible.
          Or, remarquent avec inquiétude ces théoriciens, la ligne fine tracée avec un instrument très
          pointu, une plume bien taillée, ou la ligne naturelle qui résulte de la rencontre de deux surfaces
          de couleurs ou de valeurs différentes, et n'a qu'une trajectoire et pas de largeur, symbolise la
          mort ou la naissance de la surface qu'elle entoure. Si l'attention du regardeur se déplace de
          l'extérieur de cette surface vers l'intérieur en traversant la ligne, celle-ci symbolise la
          naissance de la forme; si, au contraire, le regard vient de l'intérieur de la forme, elle en
          symbolise la mort. <br />J'aimerais que, dans ma peinture, il ne soit pas question du triomphe de la
          mort, ni de la vie comme perpétuation de la douleur de naître. Et, néanmoins, je ne veux pas
          cesser de mettre mon espoir dans le dessin et je voudrais que celui-ci ne soit pas un messager
          de la douleur. Mais comment y parvenir ?
        </p>
        <div className="img-center"><img src={images['yt-156.svg']} alt="" /></div>
        <p>
          Je pense y être arrivé, grâce à mon analyse du graphisme, à la leçon que m'a donée l'histoire de
          la ligne, et enfin aux exemples fournis par le rapprochement des peintures moderne et romane.
          Une des caractéristiques de la peinture romane est l'importance du cerne que la peinture
          classique élimine presque toujours. Gauguin, avec ce que l'on a nommé le cloisonnisme, fait
          réapparaître le cerne. <br />J'avais remarqué le rôle très important qu'il joue dans la peinture
          de Suzanne Valadon. Je l'ai retrouvé, comme un nimbe obscur, dans les tableaux de Rouault. Le
          cerne est une ligne artificielle, un trait qui généralement confirme le contour d'une forme.
          D'aussi grosses lignes, j'en trouvai dans un autoportrait de Miró. J'en découvris aussi dans
          plusieurs des innombrables essais de Paul Klee. Bien sûr, Picasso, lui non plus, n'ignorait pas
          la ligne artificielle hypertrophiée. Pourquoi, dans tous les cas, me séduisait-elle ?
        </p>
        <p>
          Une des raisons pour lesquelles j'ai recommencé à dessiner est que les crayons-feutres, dont la
          commercialisation en 1970 était patente et qui me permettaient de tracer des lignes larges,
          m'encouragèrent. Francastel avait raison, les modifications des moyens matériels ont une grande
          importance dans l'histoire de l'art. Mais il y avait surtout le plaisir que je ressentais à faire
          de « grosses » lignes : la ligne coupe, sépare, et aussi bien, mais à condition de la traiter
          autremenet, conduit, relie, attache. La contradiction de ces caractères s'annule si l'on traite
          convenablement de la ligne. Par exemple, mes tubes associatifs dont j'ai déjà parlé, loin de
          diviser, unissent et rassemblent de sorte qu'ils permettent à un seul personnage d'être présenté
          à divers moments de sa vie. Or, ces liens associatifs, que sont-ils ? D'où viennent-ils ? Ce
          sont des lignes artificielles qui sont le résultat d'une aventure vécue par moi en Inde : la
          découverte d'une miniature donnant une image du char solaire, vu de profil et traîné par dix
          chevaux dont un seul possédait un corps, les neuf autres étant réduits à la tête vue de profil.
          J'ai fait tourner l'image, l'obligeant à passer du profile à la vue de face; toutefois, j'ai
          remplacé les têtes chevalines par des têtes humaines et j'ai remarqué que cette succession de
          têtes format une grosse ligne prenant le caractère d'une route, d'un chemin, d'une surface.
        </p>
        <p>
          Dès lors, la ligne cesse de s'opposer aux surfaces de couleur, d'être avec elles comme l'enfer
          par rapport au paradis. La ligne contribue à la vérité de la douce et émouvante parole de
          Gisèle Prassinos : « Au Taillandier-land, on n'est jamais seul. »
        </p>
        <h3>La trajectoire de la ligne</h3>
        <p>
          J'ai déjà parlé de la trajectoire que suivent mes lignes fines et les lignes sans largeur que sont
          mes lignes naturelles. Il y a une règle fondamentale qui impose de prendre garde aux angles :
          autant que possible, on les évite. Cette exclusion ou cet arrondissement des angles que Fernand
          Léger a pratiqués, ont fait de lui un cubiste particulier que l'on s'est complu à singulariser
          au moyen du mot « tubiste ». Ce nom me plaît. Je soupçonne qu'il est pour quelque chose dans le fait
          qu'il m'arrive de désigner mes lignes associatives au moyen du mot tube : mes
          <span className="italic">tubes associatifs</span>.
        </p>
        <p>
          Une autre règle est la continuité des lignes, de sorte qu'elles enveloppent les couleurs, quelles
          qu'elles soient. A ce propos, je dois parler des rebords, qui ne sont pas rectangulaires, dans
          mes monuments de carton ou de bois.
        </p>
        <img className="float-right" src={images['yt-157.svg']} alt="" />
        <p>
          J'aime, par exemple, faire des chaises; elles se composent d'un cube dont un des côtés sert de
          siège, un autre côté, plus grand, plus haut que les trois autres, sert de dossier; le haut de
          ce dossier est découpé de manière à tracer dans l'espace une ligne sinueuse, appartenant au
          style de ligne qu'Hogarth appelait « ligne de beauté ». Etant un ami de la beauté, cette ligne
          de la partie supérieure de mes chaises m'est précieuse. Or, elle est menacée. Les surfaces
          présentées par le cube et par le dossier, décorées recto verso sont, le plus souvent, peintes
          en clair sur un fond sombre; or, si la chaise est placée, elle aussi, sur un fond sombre, les
          deux ténèbres se confondront et la ligne du contour que j'aime va disparaître. Que faire pour
          la sauver ? Doubler la ligne de ce contour au moyen d'une grosse ligne claire. La découpe
          imposée à la planche, découpe qui me plaît et que je veux conserver, disparaîtra; toutefois
          son double, peint en blanc, en jaune citron ou en rose, la maintiendra, sans doute avec un
          petit décalage, mais qui n'affectera pas sensiblement la forme.
        </p>
        <p>
          Problème résolu. Cette solution montre que la décoration peut ne pas nuire à la forme qui la
          supporte. Et même, au contraire, la servir. Le temps des murs nus est fini, ou s'apprête à
          finir : les tags le démontrent. La nature sociale, comme la nature des scolastiques, a horreur
          du vide.
        </p>
        <p>
          Mais il est temps de revenir à l'énoncé des autres caractéristiques des lignes taillandier-landaises,
          naturelles, artificielles, fines ou grosses, avec ou sans largeur. j'ai dit plus haut que la
          trajectoire des lignes doit comporter le moins d'angles possibles, ce qui m'apparente à Fernand
          Léger. Mais je me distingue de celui-ci à cause des cernes. Les siens, que le tableau soit
          polychrome ou, au contraire, en camaïeu, sont noirs. Les miens ne sont noirs que dans mes
          camaïeux. Ailleurs, blancs ou teintés, mais jamais noirs. Dans les tableaux polychromes, l'on
          doit imiter les impressionnistes et remplacer cette anti-couleur par du violet. Toutefois, les
          lois du chroma autorisent l'emploi d'une couleur dans une composition en noir et blanc, à
          condition que cette couleur soit seule; j'ai fait beaucoup de dessins ou de tableaux en noir,
          blanc et vert, ou bien en noir, blanc et bleu, ou encore en noir, blanc et jaune; et ainsi de
          suite. J'ajoute une couleur et, malgré les tentations, aucune autre.
          <img className="float-left" src={images['yt-158.svg']} alt="" />Ces tentations, quelles
          sont-elles ? Il y a d'abord celle d'adjoindre au jeu du noir et blanc un jeu de complémentaires
          et de provoquer, à côté du contraste du noir et du blanc, un autre contraste : celui de la nuit
          et du jour, celui de la verdure et des toits de tuiles. Pourtant, il faut repousser résolument
          pareille séduction. Certains cèdent à sa voix de sirène : leurs oeuvres sont grossières, vulgaires,
          et leur esprit d'invention s'éteint.
        </p>
        <p>
          La grande opposition du noir aux couleurs donne une satisfaction primaire, brutale et beaucoup
          d'artistes ou d'amateurs n'en demandent pas davantage. Si, au contraire, les contrastes optiques
          sont adoucis, pour que le regardeur soit alerté, intéressé, il faut avoir recours au
          fantastique, au rarement vu, à l'inventé, au surprenant dans la composiiton ou à des harmonies
          très fines dans le graphisme. Je me soucie beaucoup de celles-ci quand je dessine. J'essaie de
          me garder de la complication dans la trajectoire des lignes et du trop grand nombre
          d'« accidents ». Il faut que, dans mon dessin, on sente ma main, mais il faut éviter de faire
          éclater sa maladresse, sa lourdeur, son tremblement. Il est nécessaire que tout cela, l'ensemble
          de ces défauts, ne soit pas complètement exclu afin que le tracé semble naturel et que le
          spectateur s'en croie quelque peu complice. Enfin, pour dire les mêmes choses en d'autres termes,
          il faut que le tracé soit obtenu spontanément et exécuté assez vite.
        </p>
        <h3>Les lois du chroma ou les lois de la couleur dans le Taillandier-land</h3>
        <blockquote>La couleur nécessité vitale. - Fernand Léger</blockquote>
        <p>
          Il y a des couchers de soleil où le ciel devient rouge et qui sont surprenants et magnifiques.
          Je peux les faire entrer sans difficulté dans mon pays imaginaire. L'important étant pour moi
          de respecter les lois du chroma.
        </p>
        <p>
          Il résulte de celles-ci une cohérence et une solidité pour ma peinture, et certaines commodités
          pour moi-même. Car, grâce à elles, je n'ai pas à m'éterniser sur les lois des couleurs : je
          respecte les lois du chroma comme ailleurs on respecte les lois de Manou. Ces lois me font
          gagner du temps et économiser de la force, ce qui me permet de donner plus de soin au dessin
          et à la composition.
        </p>
        <p>
          Le principe fondamental des lois du chroma est qu'une couleur ne peut être rapprochée d'une
          autre couleur que si cette autre couleur est sa complémentaire ou sa voisine sur le prisme.
          Par exemple, pour reparler des ciels rouges, je peux les rapprocher des collines vertes,
          parce que le vert est complémentaire du rouge. Mais ces collines, je pourrais aussi leur
          attribuer la couleur violette parce que le rouge est contenu dans le violet et qu'à ce titre
          il voisine avec lui dans le prisme.
        </p>
        <div className="img-expo-2005">
          <img src={afficheExpo2005} alt="" />
          <p>Affiche pour l'exposition personnelle, Bédarieux, 2005</p>
        </div>
        <p>
          J'emploie volontiers le violet pour colorer de petits espaces fermés, notamment quand ils
          sont au milieu de la composition. C'est de cette façon que j'ai utilisé dans une affiche
          dont j'ai fait la maquette et qui annonçait mon exposition de peintures à Bédarieux, près
          de Montpellier et non loin d'Avignon, en juin 2005. Mon affiche présente au public une
          automobile de marque taillandier-landaise. On s'en aperçoit aisément car son radiateur
          a été transformé par mes soins en une bouche laissant voir les dents de la mâchoire
          supérieure. Cette automobile tire une langue très longue et très grosse qui lui sert de
          tapis volant, de sorte que l'automobiliste n'a pas à s'occuper du moteur. D'ailleurs,
          celui-ci existe-t-il ?
        </p>
        <p>
          J'ai interrogé les responsable de la construction automobile au Taillandier-land où sont
          construites toutes les voitures de marques Taillauto, excellentes machines roulantes dont
          la carrosserie évoque la belle forme des Citroën qui peuplaient les routes de l'alterorbe
          dans les années 1920. Si les Taillauto peuvent, grâce à leur langue, voler comme des
          avions, elles peuvent aussi, quand elles rentrent leur langue, se déplacer à une vitesse
          plus ou moins grande sur les autoroutes, les routes nationales, les routes départementales
          et les chemins vicinaux de la mère-patrie des Taillandier-landais. Telle a été la réponse
          des responsables e la publicité et de la fabrication des Taillauto. Je n'ai donc pas à me
          préoccuper des gestes de conducteur que fait l'automobiliste de mon affiche.
        </p>
        <div className="float-left img159"><img src={images['yt-159.svg']} alt="" /></div>
        <p>
          Aussi, je n'hésite pas à les mettre en évidence. Le gesticulateur tient le volant de sa
          main droite et repose sa main gauche sur la manette de changement de vitesse. Sa tête,
          sa poitrine, ses mains et ses bras se détachent avec force sur un fond violet sur lequel
          se détachent aussi une partie de la carrosserie, un pare-brise protégeant le volant et un
          bouchon de radiateur hypertrophié. Ce violet borde u drapeau jaune qui contient en majuscules
          violettes le mot « Bédarieux ». Or, si j'ai écrit en violet sur jaune, c'est que le violet et
          le jaune sont ce que les coloristes nomment des couleurs complémentaires. La bouche de la
          Taillauto tireuse de langue a des lèvres blanches comme ses dents; l'intérieur obscur est
          peint en violet car la langue-tapis volant est jaune : jaune-violet, encore le jeu des
          couleurs complémentaires.
        </p>
        <p>
          Toutefois, quand la langue devient tapis volant, elle se détache sur le fond général de
          l'affiche qui est rouge. Or, le rouge n'est ni une couleur voisine du jaune (le jaune a
          deux couleurs voisines sur le spectre : le vert et l'orangé), ni une couleur complémentaire.
          On le sait, la complémentaire du jaune est le violet. Que faire pour se conformer aux lois
          du chroma et à ma volonté de conférer une teinte rouge au fond qui entoure les formes jaunes,
          non seulement de la langue, mais des drapeaux sur lesquels j'ai inscrit mon prénom et mon nom
          en vert, car le vert, comme on le sait déjà, est une des vouleurs voisines du jaune sur le
          prisme ? La solution à mon faux dilemme est le cerne et sa couleur. Toutes les parties qui
          sont jaunes, je les ai soulignées par un trait vert, une large et ostensible ligne artificielle
          verte. Cette teinte est d'autant lpus recommandée que, du côté du jaune, elle est une couleur
          voisine et que, l'autre côté, du côté du rouge, elle est une couleur complémentaire.
        </p>
        <div className="float-right"><img src={images['yt-160.svg']} alt="" /></div>
        <p>
          « Très bien, disent les historiens coloristes du Taillandier-land. Vous avez résolu le problème
          que vous posent nos lois, lois que vous avez vous-mêmes conçues, pour vous conformer à
          l'esprit des peintres que vous avez aimés à la fin des années quarante et au début des années
          cinquante. Comme eux, vous voulez être libre, mais non sans lois. Toutefois, les lois auxquelles
          nous devons notre existence, nous en sommes fiers, ces lois sont les vôtres, du moins celles
          auxquelles vous avez décidé de vous soumettre, et nons pas des lois que d'autres, ailleurs, vous
          auraient imposées. Nous ne sommes pas des anarchistes, disaient les peintres que vous admiriez
          et que vous admirez encore. Nous ne sommes pas des sans-loi, mais les lois que nous observons,
          nous les avons choisies, voire créées. »
        </p>
        <div className="fabuliste">
          <div>
            <h3>Serais-je un fabuliste ?</h3>
            <p>
              Hier, j'ai beaucoup travaillé à mon catalogue-dictionnaire illustré du Taillandier-land.
              Ce matin, je me suis levé tôt pour y travailler. Je n'ai pas encore fini la lettre A !
              Je ne suis pas en avance. Je devrais me hâter, me presser, mais j'ai peur - faut-il
              éprouver de la honte à l'avouer ? - j'ai peur de me faire une foulure mentale. Dans le
              langage du peuple, je suis tout de suite condamné : « je ne me foule pas ! ». Donc, je
              paresse. Voire ! On joue au fier-à-bras et on aboutit à certaines luxations qui causent
              plus de dommages qu'une attitude moins immodeste, moins prétentieuse. C'est la fable du
              lièvre et de la tortue. Mon dictionnaire m'incite à me poser cette question : ne
              serais-je pas une espèce de fabuliste ?
            </p>
            <p>Ibiza, 9 octobre 1989</p>
          </div>
          <img src={images['yt-161.svg']} alt="" />
        </div>

        <h2>Petit lexique du Taillandier-land</h2>
        <div className="main-img"><img src={images['yt-lex-0.png']} alt="" /></div>

        <article className="lexique right">
          <div className="lexique-text">
            <h3>Acéphalie</h3>
            <p>
              Dans mes premiers dessins de nouvelle critique d'art, je me suis servi de l'acéphalie de deux personnages célèbres dans l'esthéticland : la fermière peinte par Chagall sur la toile qu'il a intitulé « A la Russie, aux ânes et aux autres » et la sculpture de Rodin nommé « L'homme qui marche ». La sculpture de Rodin n'a ni bras ni tête, la fermière de Chagall a des bras, mais sa tête flotte au-dessus de son cou dont elle s'est détachée pour aller occuper l'espace vide du ciel. Le russe tout génial qu'l fut ne savait pas comment le remplir, m'a-t-il dit. A ces deux acéphalies prestigieuses j'en ajoutai une troisième que je voulais rendre célèbre elle aussi : c'était une sculpture de mon ami Charles Semser.  Il s'agissait d'une femme dont la tête était séparée du cou, comme celle de la fermière, mais elle n'était pas libre dans l'espace. Les deux mains de la Semsérienne la retenaient et l'on ne savait pas
            </p>
          </div>
          <div className="lexique-img"><img src={images['yt-lex-1.png']} alt="" /></div>
        </article>

        <article className="lexique left">
          <div className="lexique-img"><img src={images['yt-lex-2.png']} alt="" /></div>
          <div className="lexique-text">
            <h3>Acéphalie (suite)</h3>
            <p>
              à quoi s'en tenir : du cou, les mains cherchaient-elles à la rapporcher, ou, au contraire, l'avaient-elles décolée, comme un chapeau, dans un salut se sépare du crâne qui le portait jusque-là.
              <br />
              Pourquoi le thème de l'acéphalie me plaisait-il ? Dans le cas cité, je voyais une progression, figure rhétorique qui suscite toujours mon enthousiasme. Mais je pensais aussi au conseil surréaliste qui veut que la raison symbolisée par la tête soit réduite au minimum et peut-être même supprimée.
              <br />
              Antonio Saura m'a raconté un souvenir d'enfance : il a vu pendant la guerre civile un passant décapité par un éclat d'obus continuer à marcher dans la rue sur laquelle donnait sa fenêtre. Peut-être a-t-il, bien des années plus tard, voulu effacer ce souvenir en peignant des foules, c'est-à-dire des têtes innombrables.
              <br />
              Pour moi, j'ai pris mes distances à l'égard d'un surréalisme sommaire en abandonnant mon éloge de l'acéphalie et en m'adonnant à la création de taillandier-landais multi-céphales.
              <br />
              J'ai été confirmé dans ce parti tout récemment en me souvenant d'une exclamation de Gauguin : « Un kilo de rouge fait plus rouge qu'un gramme de rouge. » J'ai traduit cette assertion à mon usage et j'ai décidé d'affirmer : « Dix têtes font plus tête qu'une seule tête. »
            </p>
          </div>
        </article>

        <article className="lexique right">
          <div className="lexique-text">
            <h3>Alcibiadisme</h3>
            <p>
              Faculté d'adaptation semblable à cette attribuée à Alcibiade - J'emploie ce mot dans « Le Voyage de l'Oeil » pour désigner la capacité que l'amateur doit pouvoir acquérir, se rendant capable d'apprécier une oeuvre de style géométrique aussi bien que de style tachiste, chaque style étant comme un pays aux moeurs duquel l'alcibiadiste sait se
              conformer.
              <br />
              Dans le Taillandier-land littéraire ou pictural, l'humanisation  est un exemple d'alcibiadisme réciproque : la forme humaine s'adapte à la machine (se mécanise), la forme mécanique s'adapte à l'humain.
              <br />
              Exemples parmi beaucoup d'autres : les autos qui tirent la langue, les hélicoptères qui ont la forme d'une tête.
            </p>
            <h3>Alterorbe</h3>
            <p>
              L'autre monde pour les taillandier-landais, c'est-à-dire notre monde à nous quand nous ne sommes pas dans le Taillandier-land où de nombreux témoignages de l'alterorbe sont conservés. Par exemple, les trocébibis, les paysages auxquels pensent les taillandier-landais, de nombreuses machines comme les autos, avions, bicyclettes, hélicoptères, barques à rames, bateaux à voiles, bateaux à vapeur.
              <br />
              C'est que le Taillandier-land que l'on désigne parfois par les initiales T.L. n'est pas le contraire de l'alterorbe, mais comporte de nombreux objets alterorbiens modifiés, soit pour se conformer à mon imagination, soit pour symboliser dans un climat fantastique ou fantaisiste l'aventure de la création (revivre sa gestation et sa naissance), soit pour rendre le monstrueux supportable. A cette fin, je supprime le modelé et je traite les corps humains en à-plats, ayant remarqué que ceux-ci créent une distance, les plus hautes montagnes, les plus volumineuses paraissant plates vues de loin.
              <br />
              Enfin, les rapports de l'Alterorbe et du T.L. m'ont permis d'inventer les Taillansnobs.
            </p>
          </div>
          <div className="lexique-img"><img src={images['yt-lex-3.png']} alt="" /></div>
        </article>

        <article className="lexique left">
          <div className="lexique-img"><img src={images['yt-lex-4.png']} alt="" /></div>
          <div className="lexique-text">
            <h3>A. Altiste</h3>
            <p>
              Les altistes ont deux noms. On les a d'abord nommés les AMDELAOS, c'est-à-dire AMoureux DE LA hauteur. On a représenté le mot hauteur par la seule lettre O. De nos jours, le mot altiste prévaut le plus souvent. Il a été fabriqué en s'inspirant pour le sens et la forme du mot altitude. Altiste signifie personnage se développant en hauteur. Contrairement aux centaures et aux horizontalistes. Ces derniers rampent sur le sol, s'ils en trouvent. De toutes façons, ils s'oppposent radicalement aux altistes, ce qui n'est pas le cas des centaures qui s'approprient volontiers le caractères des amoureux de la hauteur et deviennent des ALTICENTAMES.
            </p>
          </div>
        </article>

        <article className="lexique right">
          <div className="lexique-text">
            <h3>A. Arbre</h3>
            <p>
              Depuis quelques temps, je peins des arbres. Mon ami Alain Benoit voyant ceux que j'ai dessinés d'après nature m'a encouragé à continuer et à enrichir le T.L. au moyen de bosquets, de bois et même de forêts. Toutefois, j'abandonne la nature à elle-même et peins mes arbres d'imagination. Mes arbres imaginaires ont de nombreuses branches, mais chaque branche n'a qu'une feuille. J'ai vu avec plaisir que mon invention n'était pas absurde; Diego Giacometti l'ayant fait avant moi. D'autre part mes arbres imaginaires portent des fruits qui racontent la belibo ou la belité.
            </p>
          </div>
          <div className="lexique-img"><img src={images['yt-lex-5.png']} alt="" /></div>
        </article>

        <article className="lexique left">
          <div className="lexique-img"><img src={images['yt-lex-6.png']} alt="" /></div>
          <div className="lexique-text">
            <h3>A. Autotaille</h3>
            <p>
              La principale marque de fabrication automobile du Taillandier-land. La plupart des autotailles ont pour modèles les autos citroëns des années 1920. Les premières autotailles étaient vues de profil; mais, bientôt une représentation inspirée du cubisme l'emporta. Aujourd'hui, on voit seulement le profil de la voiture, mais aussi l'avant et l'arrière - parfois même on voit en partie le dessous et le dessus. En outre, les autotailles sont humanisées et mille-et-une-nuitisées. Leur radiateur est changé en bouche d'où on voit sortir une langue très longue et large qui passe sous les roues de la voiture. En fait, c'est un tapis volant, qui rend le véhicule capable de rivaliser avec les aéroplanes et qui peut se passer de route. Enfin, les phares deviennent des yeux et le bouchon de radiateur devient un nez qui s'achève par des sourcils surplombant les phares. La perspective est renversée.
            </p>
          </div>
        </article>

        <article className="lexique right">
          <div className="lexique-text">
            <h3>B. Bas historiés</h3>
            <p>
              Sont des exemples de l'utilisation des bélis à des fins apparemment décoratives. En fait, ce sont les porteurs qui ont un caractère occasionnel et contingent : ils sont les serviteurs des belles histoires; celles-ci, en paraissant les embellir, ne font que saisir une occasion de se montrer. C'est la raison pour laquelle le gouvernement du Taillandier-land s'apprête à encourager les centaures de sexe féminin à porter ces bas historiés que certains écrivains taillandier-landais nomment des bas bélisés.
            </p>
          </div>
          <div className="lexique-img"><img src={images['yt-lex-7.png']} alt="" /></div>
        </article>

        <article className="lexique left">
          <div className="lexique-img"><img src={images['yt-lex-8.png']} alt="" /></div>
          <div className="lexique-text">
            <h3>B. Bateaux-gigogne</h3>
            <p>
              Le bateau-gigogne transporte de l'eau où navigue un autre bateau qui lui aussi transporte de l'eau à l'intention d'un troisième bateau transporteur d'une eau sur laquelle flotte un quatrième vaisseau; et ainsi de suite. Les bateaux-gigogne ouvrent une porte donnant sur l'infini.
            </p>
          </div>
        </article>

        <article className="lexique right">
          <div className="lexique-text">
            <h3>B. Bélibo</h3>
            <p>
              Belle histoire de la boule. Bélibo, ce nom a été fabriqué en choisissant des lettres dans la périphrase : BELle histoire de la BOule. Cette boule est donnée comme un ventre mis au monde par une taillandier-landaise qui, lors de sa naissance, dut constater que son enfant n'avait ni tête, ni poitrine, ni bras, ni jambe, et se réduisait à un ventre. Mais peu à peu, il devint un trocébibi.
              <br />Dans presque tous mes tableaux on trouve la bélibo, ou la bélité. Toutefois, ces histoires sont racontées d'une façon plus simple et prenennt un caractère ornemental.
              <br />Il faut ajouter que la bélibo est un conte cônique ou triangulaire. Pour faire comprendre son caractère cônique ou triangulaire, j'ai repris en 1969 et 1970, mes crayons et mes pinceaux. Au début, la boule est une telle simplification qu'on peut la loger dans le sommet de la pyramide. Puis, en énumérant les parties du corps humain qui, de plus en plus nombreuses s'ajoutent à la boule, on peut remplir la surface croissante des triangles dont la pyramide est formée.
            </p>
          </div>
          <div className="lexique-img"><img src={images['yt-lex-9.png']} alt="" /></div>
        </article>

        <article className="lexique left">
          <div className="lexique-img"><img src={images['yt-lex-10.png']} alt="" /></div>
          <div className="lexique-text">
            <h3>B. Bélibo (suite)</h3>
            <p>
              On arrive assez vite au trocébibi (voir ce mot). Mais on ne s'arrête pas là. Mon personnage constate que ses fesses grossissent. Elles deviennent si importantes qu'on doit en convenir : elles constituent un second tronc, une poitrine et un ventre posés horizontalement. Pour porter ce tronc horizontal apparaissent une troisième et une quatrième jambe. Dès lors, la boule d'autrefois est devenue semblable à un centaure.
              <br />Toutefois, cette apparition du centaure ne me suffit pas. Je raconte que le centaure taillandier-landais possédant, lui aussi, des fesses, les sent grossir. Et bientôt on doit constater l'existence d'un troisième tronc, qui est le second tronc horizontal de ce personnage dont la physiologie s'enrichit bientôt d'une septième et d'une huitième jambes. C'est alors qu'on parle de lui, en langue taillandier-landaise, comme d'un centaure amélioré ou, plus brièvement un centâme.
            </p>
          </div>
        </article>

        <article className="lexique right">
          <div className="lexique-text">
            <h3>B. Béli</h3>
            <p>
              Les bélis sont ce que l'on nomme aussi au Taillandier-land, les BELles HIStoires, l'adjectif s'accompagnant d'un sourire. Sont-elles belles en effet ? Je n'en sais rien, mais que toutes sont progressives, c'est certain. Longtemps, il n'y en eut que deux : la bélibo et la bélité (voir ces mots). Aujourd'hui, de nombreuses bélis se sont ajoutées ayant pour point de départ une partie du corps humain : la BELIPI ou belle histoire du PIed; la BELIJA ou belle histoire de la jambe; BELIGEU ou belle histoire du GEnou; la beliCUI ou belle histoire de la CUIsse, etc... Prenons pour exemple la BELIPI.
            </p>
          </div>
          <div className="lexique-img"><img src={images['yt-lex-11.png']} alt="" /></div>
        </article>

        <article className="lexique left">
          <div className="lexique-img"><img src={images['yt-lex-12.png']} alt="" /></div>
          <div className="lexique-text">
            <h3>B. Bouture</h3>
            <p>
              Le premier critique d'art qui, à propos du Taillandier-land et de la génération des habitants de mon pyas pictural imaginaire, ait parlé de bouture est Raoul-Jean Moulin. Mes personnages (selon lui et je l'approuve) sont comme des arbres auxquels on ajoute une branche d'une autre essence, de manière à créer une nouvelle plante. Pour cette opération, j'utilise souvent un tube associatif. Celui-ci peut en outre me servir à réunir toutes les phases d'une béli que je greffe sur ce tube comme à un tronc. Me livrant à cette sorte de jardinage, je me rapproche de mon ami Miró qui, lors d'un entretien, me disait de lui-même et pour décrire son comportement créateur : « Je travaille comme un jardinier. »
            </p>
          </div>
        </article>

        <article className="lexique right">
          <div className="lexique-text">
            <h3>B. Bélité</h3>
            <p>
              Belle histoire d'une tête. Histoire d'un enfant qui, à sa naissance n'était qu'une tête. Dans les jours, les semaines, les mois, les années qui suivirent, cette tête acquit un corps, un corps de trocébibi qui, bientôt, devint un centaure amélioré.
            </p>
          </div>
          <div className="lexique-img"><img src={images['yt-lex-13.png']} alt="" /></div>
        </article>

        <article className="lexique left">
          <div className="lexique-img"><img src={images['yt-lex-14.png']} alt="" /></div>
          <div className="lexique-text">
            <h3>C. Capitipède</h3>
            <p>
              De haute origine (conversation)
              <br />« Un de mes ancêtres était un haut personnage », dit un capitipède bien caractérisé. Et il ajouta : « C'était un roi. »
              <br />-Vous voulez rire, répliqua le centaure sexupède avec lequel il entretenait une conversation animée. Vous n'avez pas de bras, vous n'avez pas de tronc, vous n'avez qu'une tête et deux jambes. Vous ne me ferez jamais croire que vous descendez d'un roi.
              <br />- Si, répliqua vivement le capitipède, je descends d'un roi.
              <br />- Roi de quoi ?
              <br />Roi d'un bout de ligne.
              <br />Et en effet, c'est au bout d'une ligne d'un manuscrit à miniature du XIIe siècle, reproduit dans un ouvrage de Jurgis Baltrusaïtis, que j'ai trouvé un roi sans tronc ni bras, seulement constitué de deux pieds et d'une tête portat couronne - et, si l'on excepte cette couronne, parfaite définition du capitipède, néologisme dont je suis fier, formé de deux mots latins : caput (capitis) tête et pes (pedis) pied.
            </p>
          </div>
        </article>

        <article className="lexique right">
          <div className="lexique-text">
            <h3>C. Capitipède féminin</h3>
            <p>
              Ou capitipède féminine, comme l'écrivent certains auteurs qui estiment que le mot capitipède peut cahnger de genre selon le contexte.
            </p>
            <h3>C. Centame</h3>
            <p>
              Le centame est un centaure taillandier-landais qui a bénéficié d'une amélioration considérable : il possède plus de quatre jambes. Les centaures superposés produisent des triangles fort appréciés par le TAILLEPRIN. Mais, pour obtenir ces triangles, il faut ajouter au sommet un centaure simple et TROCEBIBI.
            </p>
          </div>
          <div className="lexique-img"><img src={images['yt-lex-15.png']} alt="" /></div>
        </article>

        <article className="lexique left">
          <div className="lexique-img"><img src={images['yt-lex-16.png']} alt="" /></div>
          <div className="lexique-text">
            <h3>C. Chaîne céphalique</h3>
            <p>
              La première idée de la chaîne céphalique ou chaîne de têtes m'a été fournie par une miniature indienne représentant les chevaux du soleil vus de profil. Ont renforcé cette idée les dernières oeuvres de Picasso montrant de nombreuses ombres portées d'un seul visage vu de profil lui aussi.
              <br />J'ai remplacé les visages vus de profil par des visages vus de face et empiétant les uns sur les autres, trois yeux suffisant pour deux visages (économie d'un oeil).
              <br />Ici, deux capitipède reliés par une chaîne de dix visages. Comme dans la précédente image, on économise les yeux : au lieu de vingt globes occulaires, onze suffisent. D'autre part, tous les visages ont la même grandeur et à peu près la même expression.
            </p>
          </div>
        </article>

        <article className="lexique right">
          <div className="lexique-text">
            <h3>C. Chroma</h3>
            <p>
              (lois régissant les couleurs dites lois du chroma)
              <br />La commission taillandier-landaise concernant l'emploi des couleurs a édicté plusieurs interdictions : celle d'utiliser le noir dans les compositions polychromes. En revanche on peut utiliser une couleur, mais une seule couleur dans les compositions en camaïeu (noir et blanc). On peut, dans une composition comportant du noir, introduire une couleur : un rouge, par exemple, mais rien que cette couleur. Seconde interdiction : ne jamais juxtaposer les primaires (jaune, rouge, bleu). Troisième interdiction : ne jamais juxtaposer les binaires (vert, violet, orangé). En revanche on peut juxtaposer une couleur et sa complémentaire ou cette couleur et sa voisine sur le prisme. On peut par conséquent faire voisiner le vert et le rouge, le bleu et l'orangé, le jaune et le violet. Ceci pour les complémentaires. Pour les voisines, il faut se rappeler que chaque couleur a deux voisines. Par exemple, le jaune a comme voisins le vert et, d'autre part, l'orangé.
            </p>
            <h3>C. Cycle pour centame</h3>
            <p>
              Au début du vingtième siècle les fabricants de bicyclette se sont intéressés à des tandems pour plus de deux personnes. Informée de cette initiative, la Manufacture d'armes et cycles de Midiville, capitale du Taillandier-land a décidé la mise sur le marché taillandier-landais de tandems améliorés pour le bonheur de centames.
            </p>
          </div>
          <div className="lexique-img"><img src={images['yt-lex-17.png']} alt="" /></div>
        </article>

        <article className="lexique left">
          <div className="lexique-img"><img src={images['yt-lex-18.png']} alt="" /></div>
          <div className="lexique-text">
            <h3>C. Cycles vitaux</h3>
            <p>
              Ce sont des occasions pour associer les taillandier-landaises et les taillandier-landais, tout en évitant, malgré l'apparence, de parler de copulation. Je préfère évoquer les mouvements cycliques de la nature : le cycle annuel, le cycle lunaire, le cycle des générations. Pourtant mes cycles vitaux ne produisent rien. De la bouche ouverte de la femme sortent de petits personnages qui profitent de la bouche ouverte de l'homme pour s'introduire dans son corps où ils se dissocient en descendant jusqu'aux testicules et au pénis. Celui-ci les réintroduit dans le corps de la femme où ils se reconstituent et remontent vers la bouche d'où ils sortent, ce qui leur serait fatal, sans la taillandier-landaise reconstructrice.
            </p>
          </div>
        </article>

        <article className="lexique right">
          <div className="lexique-text">
            <h3>E. Egyptiens</h3>
            <p>
              Nous sommes tous des égyptiens, si tant est que nous soyons monothéistes. Les égyptiens, qui avaient de nombreux dieux, mais aussi de très ingénieux théologiens, ont conçu le monothéisme dans la nuit des temps, mais ne se sont passionné pour lui qu'à l'époque d'Ankénaton. Ce pharaon a voulu l'imposer à toute l'Egypte, faillit y réussir, mais fut tué par un général auquel il avait fait imprudemment confiance. Il avait en outre crée une capitale n'abritant que les fervents d'un Dieu unique. Capitale très riche et très heureuse qui est devenue dans la Bible le paradis terrestre. Le général félon étant parvenu à la position suprème, en cette qualité et selon les moeurs politiques égyptiennes, devint Dieu. C'est pourquoi les rédacteurs de la Bible purent dire que Dieu chassa de l'Eden Adam et Eve, c'est-à-dire toute la population monothéiste privilégiée par Ankénaton et réduite à un seul couple d'humains. Si ces rédacteurs de la Bible traverstirent ainsi la vérité, c'est que les monothéistes avaient dû se réfugier dans une nation férocement énnemie de l'Egypte et que pour n'être pas victimes de sa haine, il leur fallait dissimuler qu'ils étaient égyptiens.
            </p>
            <h3>E. Eloge du rectangle</h3>
            <p>
              et pluralité des valeurs symboliques. Les altistes, les centames ne symbolisent pas seulement l'extraordinaire croissance du foetus. Ils font aussi l'éloge du rectangle dont on omet de penser que, pour l'inventer, il a fallu des siècles. Centames et horizontalistes font l'éloge de la base de cette figure; les altistes approuvent ou soulignent sa hauteur. Une association céphalique confirme la largeur, mais en haut. Enfin, le caractère oblique des diagonales est évoqué respectueusement par les dispositions quatre-points-cardinalices et les compositions régies par la croix de Saint André ou X.
            </p>
          </div>
          <div className="lexique-img"><img src={images['yt-lex-19.png']} alt="" /></div>
        </article>

        <article className="lexique left">
          <div className="lexique-img"><img src={images['yt-lex-20.png']} alt="" /></div>
          <div className="lexique-text">
            <h3>E. Eponge à mouvement</h3>
            <p>
              Certains historiens du Taillandier-land prétendent que l'éponge à mouvement a été introduitedans mon pays imaginaire à l'occasion d'un hommage rendu par moi à Callipidès, acteur de théâtre qui donnait l'impression de la fuite en courant sans se déplacer. L'éponge à mouvement permet le même effet. Non seulement les piétons l'utilisent pour imité l'acteur grec, mais les maîtres d'autres véhicules, comme les conducteurs de Taillautos. En outre, il arrive que le mouvement communiqué à l'éponge soit utilisé.
            </p>
          </div>
        </article>

        <article className="lexique right">
          <div className="lexique-text">
            <h3>F. Formes molles</h3>
            <p>
              Les formes molles sont intermédiaires entre la froideur des formes géométriques et le désordre des taches, ce qu'elles ont d'impossible à maîtriser. Si l'on utilise les formes molles, on s'aperçoit qu'y prédomine ce que l'anglais Hogarth nommait la ligne de beauté, c'est-à-dire l'empreinte laissée par une trajectoire sinueuse du pinceau ou de tout autre intrument graphique. Cette ligne sinueuse fréquente chez certains expressionnistes n'est pas étrangère aux classiques. J4ai coutume de dire qu'en mettant en évidence les gonglements, les arrondis résultant de la musculature de ses personnages, Michel-Ange pratique les formes molles. Mon admiration pour lui m'incite à l'imiter mais par d'autres moyens.
            </p>
          </div>
          <div className="lexique-img"><img src={images['yt-lex-21.png']} alt="" /></div>
        </article>

        <article className="lexique left">
          <div className="lexique-img"><img src={images['yt-lex-22.png']} alt="" /></div>
          <div className="lexique-text">
            <h3>H. Horizontalistes</h3>
            <p>
              Les horizontalistes remplissent à peu près la même fonction que les centaures très améliorés : ils confirment l'horizontalité du support (toile ou feuille de papier). Toutefois, cette fonction est plus radicale. Contrairement aux centames, aucun de
              leur nombreux troncs n'est vertical.
              <br />Non seulement les horizontalistes n'ont pas de tronc vertical, mais ils ne possèdent pas de bras. Leur contraire ou antonyme est l'altiste dont les troncs multiples sont verticaux et qui possède autnat de fois deux bras qu'il a de troncs. Mais il n'a que deux jambes.
              <br />Les horizontalistes et les altistes doivent l'existence à ma très ancienne conviction selon laquelle l'image doit s'accorder à son environnement en en répétant une partie. Or, dans la grande majorité des cas, un des premiers environnements est le rectangle.
            </p>
          </div>
        </article>

        <article className="lexique right">
          <div className="lexique-text">
            <h3>L. Libellulaire</h3>
            <p>
              et libellulaire amélioré. Ces qualifications s'appliquent jusqu'ici à des avions qui possèdent quatre ailes sur le même plan et de ce fait ressemblent à des libellules géantes. Ce n'est pas moi qui ai inventé cette dispoition des ailes d'un avion, mais l'auteur d'un gros livre que je lisais dans mon enfance. J'ai amélioré sa libellule en ajoutant sur le même plan de l'appareil des ailes en plus grand nombre.
            </p>
            <h3>M. Maisons</h3>
            <p>
              On qualifie quelques fois les maisons du Taillandier-land de génitrices, car leurs fenêtres enfantent les êtres qui habitent leurs appartements. Ces maisons s'entendent très bien avec les arbres auxquels elles tiennent compagnie. Mais le plus intéressant à mon avis, c'est que ce ne sont pas les taillandier-landais qui bâtissent, qui créent les maison, mais les maisons qui créent ceux auxquels elles devraient devoir l'existence, les taillandier-landaises ou les taillandier-landais qui devraient être leurs architectes et leurs maçons.
            </p>
          </div>
          <div className="lexique-img"><img src={images['yt-lex-23.png']} alt="" /></div>
        </article>

        <article className="lexique left">
          <div className="lexique-img"><img src={images['yt-lex-24.png']} alt="" /></div>
          <div className="lexique-text">
            <h3>P. Pérabécos</h3>
            <p>
              Les pérabécos sont des personnages à belle contenance. Pour qu'on puisse jouir de la vue de ce qu'ils contiennent ils sont partiellement transparents. J'ai joué sur les deux sens du mot contenance : ce qui est à l'intérieur de quelque chose (le contenant) et la façon de se tenir, de se présenter, de résister : avoir une belle contenance, un bel aspect. J'imagine que mes personnages que j'ai modifiés à cette intention ont un aspect agréable. Mais le plus important est ce qu'il y a en eux : d'autres personnages, des paysages, des véhicules ou d'autres machines. Aujourd'hui, le plus souvent mes pérabécos sont des aviateurs munis d'une énorme tête dont la transparence partielle permet de voir leurs pensées, leurs rêves.
            </p>
          </div>
        </article>

        <article className="lexique right">
          <div className="lexique-text">
            <h3>P. Personnages associés</h3>
            <p>
              Ils ont une grande et primordiale importance dans l'histoire du Taillandier-land. Leur origine est le soleil dans une représentation mythologique indienne : un char traîné par dix chevaux dont un seul est montré entièrement, les autres étant réduits à leurs têtes vues de profil. Comme il y a peu d'animaux dans le Taillandier-land, je remplaçai les chevaux par des humains et je redressai l'image mettant de face toutes les figures de profil.
            </p>
          </div>
          <div className="lexique-img"><img src={images['yt-lex-25.png']} alt="" /></div>
        </article>

        <article className="lexique left">
          <div className="lexique-img"><img src={images['yt-lex-26.png']} alt="" /></div>
          <div className="lexique-text">
            <h3>P. Personnages virtuels</h3>
            <p>
              Ce sont des personnages qui résultent de la trajectoire de certains cordons associatifs. Ceux-ci ne se contentent pas d'associer mais dessinent dans le vide le contour de personnages qui sont dits virtuels parce que leur corps se réduit à une ligne et n'a pas d'autre consistance.
            </p>
          </div>
        </article>

        <article className="lexique right">
          <div className="lexique-text">
            <h3>P. Perspective inversée</h3>
            <p>
              Très employée au Taillandier-land où il arrive souvent que perspective inversée et perspective normale soient employées simultanément. Pourtant, la préférence va à l'inversée selon laquelle l'éloignement agrandit au lieu d'amenuiser. Par exemple, pour les autotailles les roues les plus proches du regard sont les plus petites; les plus éloignées sont les plus grandes.
            </p>
          </div>
          <div className="lexique-img"><img src={images['yt-lex-27.png']} alt="" /></div>
        </article>

        <article className="lexique left">
          <div className="lexique-img"><img src={images['yt-lex-28.png']} alt="" /></div>
          <div className="lexique-text">
            <h3>P. Picasso</h3>
            <p>
              Je respecte et j'admire Picasso. Ce qui ne m'empêche pas de penser que, si l'on oublie la supériorité qualitative qui appartient au génial espagnol, le Taillandier-land se distingue et même s'oppose au Picasso-land. Dans le pays formé par les images qu'il crée, le natif de Malaga concentre, alors que pour ma part je développe. Adaptant à mon cas le principe émis par Paul Gauguin - « Un kilo de rouge fait plus rouge qu'un gramme de rouge » - je dis que dix têtes font plus tête qu'une seule tête. Picasso lui avec dix têtes fait une seule tête; il concentre, en une seule tête, dix têtes : une tête de face, une tête de profil, une tête vue de dessous, une tête surplombée, une tête vue de derrière, toutes ces têtes vues de trois quarts, en les réduisant à des détails.
            </p>
            <h3>Q. Quatre-points-cardinalice</h3>
            <p>
              Ce long adjectif désigne les véhicules qui vont simultanément vers les quatre points cardinaux. Une visiteuse de mon atelier a remarqué que ces véhicules étaient excellents pour n'aller nulle part. Jusque là, j'avais pensé qu'ils exprimaient le désir d'aller partout, mais que, étant seulement des images, il leur était épargné l'éclatement ou le déchirement. J'ai bâti aussi en image des habitations quatre-points-cardinalices. Même dans l'alterorbe, de telles architectures sont possibles. Il n'en va pas de même des piétons, cyclistes, automobilistes, avions quatre-points-cardinalices.
            </p>
          </div>
        </article>

        <article className="lexique right">
          <div className="lexique-text">
            <h3>T. Tailleprin</h3>
            <p>
              Le Taillandier-landais principal. Je passe pour régner sur le Taillandier-land en qualité d'empereur, de roi, de président de la République ou, tout au bas de l'échelle sociale, parce que je suis l'unique et tout puissant travailleur du pays, le seul ouvrier productif et actif. J'ai des partisans fervents, voire des adorateurs. On les nomme les Taillandierolâtres qui sont persuadés que je suis le dieu du Taillandier-land. Les Taillandierophiles se contentent d'entretenir avec moi des relations amicales. En revanche, les Taillandierophobes prétendent que je n'existe pas. Quand je serai mort ils auront raison. En ce moment ils ont tort; leurs adversaires leur disent que sans moi ils ne seraient rien. Eux rétorquent qu'ils sont les fils des grandes déesses, de leur lait dont sont faites toutes choses au Taillandier-land et partout. Pour essayer de les persuader de mon existence, ce livre est composé.
            </p>
            <h3>T. Trocébibi</h3>
            <p>
              Nom et adjectif : une trocébibie, un trocébibi. Tronc céphalisé bipède bibrachien. Tronc porteur d'une tête et possédant deux jambes et deux bras.
              <br />Adjectif : on utilise trocébibi avec ou sans e pour préciser le sexe des taillandier-landais mis en scène. On dit, par exemple, une conductrice trocébibie, une aviatrice trocébibie, un passant trocébibi, un cycliste trocébibi.
            </p>
          </div>
          <div className="lexique-img"><img src={images['yt-lex-29.png']} alt="" /></div>
        </article>

        <article className="lexique left">
          <div className="lexique-img"><img src={images['yt-lex-30.png']} alt="" /></div>
          <div className="lexique-text">
            <h3>T. Tube associatif</h3>
            <p>
              Les tubes associatifs ont commencé par être très gros. Toutefois, j'ai pris en pitié mes personnages, j'ai pensé que les tubes associatifs les gênaient, j'ai diminué la grosseur de ces liaisons de sorte qu'elles deviennent très fines. Enfin, j'ai pensé que le lien était un être et j'ai inventé les personnages virtuels.
            </p>
          </div>
        </article>

        <article className="lexique right">
          <div className="lexique-text">
            <h3>T. Taillansnobs</h3>
            <p>
              Au T.L. (Taillandier-land) on dit plus brièvement et simplement les snobs. Ce qui les caractérise, c'est leur prétention à imiter l'alterorbe. C'est eux qui protègent les trocébibis, taillandier-landais exceptionnels n'ayant qu'une tête, qu'un tronc, que deux bras et deux jambes. Ils m'applaudissent d'avoir introduit dans mon pays imaginaire des automobiles, des avions, des hélicoptères, des vaisseaux (une barque, des paquebots), des maisons et, sur la suggestion d'Alain Benoit, qui édite mes textes poétiques, des arbres. Dans mon Taillandier-land littéraire, les Taillansnobs ont introduit des pharmaciens, des médecins, des croque-morts et des cimetières. Ce qui serait parfaitement inutile car, sans eux, on ne connaîtrait au T.L. ni accident, ni maladie, ni mort. A chaque enterrement auquel j'assiste dans l'alterorbe, ils m'incitent à dessiner la réception du défunt dans le ciel.
            </p>
          </div>
          <div className="lexique-img"><img src={images['yt-lex-31.png']} alt="" /></div>
        </article>

        <article className="lexique left">
          <div className="lexique-img"><img src={images['yt-lex-32.png']} alt="" /></div>
          <div className="lexique-text">
            <h3>Zoprofin</h3>
            <p>
              Les zoprofins sont des ZOnes à PROFondeur INdéterminée. Autrement dit des taches ou des fonds qui ne représentent rien, dont on ne peut savoir si ces phénomènes visuels sont proches, lointains, ou à une distance moyenne. Ils introduisent dans les images un sentiment de mouvement en profondeur car on peut faire toutes ces suppositions sans s'attacher à aucune d'elles. C'est ce qui se passe quand on regarde une peinture abstraite. Le zoprofin est donc une citation, une allusion ou un hommage que, dans le Taillandier-land résolument figuratif, on rend à l'abstraction artistique à laquelle on doit - comme au fauvisme, au cubisme et au surréalisme de nombreuses et précieuses libertés.
            </p>
          </div>
        </article>

      </div>
    </div>
    <Footer />
  </div>
);

export default TaillandierLand;
