import React from 'react';
import Footer from './Footer';
import ytIllustration from '../img/yt-illustration.svg';
import Giotto from '../img/giotto.jpg';
import Rodin from '../img/rodin.svg';
import Cézanne from '../img/cezanne.svg';
import Monet from '../img/monet.svg';
import Corot from '../img/corot.svg';
import Klein from '../img/klein.svg';
import Lanskoy from '../img/lanskoy.svg';

const CritiqueArt = props => (
  <div className="page">
    <div className="container">
      <div className="critique-art">
        <h1>Yvon Taillandier : le critique et historien d’art</h1>
        <p>
          « Effort de renouvellement dans le livre d’art – (..) Certains auteurs essaient de trouver des
          formules nouvelles. C’est ainsi que mon jeune confrère Yvon Taillandier, s’est attaché à découvrir
          dans les œuvres des maîtres du XIXè siècle les signes annonciateurs de la peinture moderne »
        </p>
        <p className="italic align-right">Frank Elgar dans le « Parisien Libéré » du 6 avril 1964.</p>
        <p>
          « Excellent pédagogue, Yvon Taillandier part souvent d’une anecdote pour nous initier à la compréhension
          de l’art moderne. Ainsi, en ouvrant son « Rodin », nous apprenons qu’à l’exposition universelle de 1900
          où Auguste Rodin avait son pavillon, s’arrêtant devant un de ses personnages acéphale et gigantesque
          (« l’homme qui marche »), une petite fille demanda à Rodin : « Maître, pourquoi n’a-t-il pas de tête ? »
        </p>
        <p className="italic align-right">Le « Figaro » du 10 avril 1968</p>
        <div className="text-img">
          <div>
            <p>
              C’est en expliquant que l’art moderne a commencé un peu partout, et « le plus souvent là où on s’y serait
              attendu le moins », qu’Yvon Taillandier, critique et historien d’art de 1945 à 1970, apporte un regard
              novateur sur les origines de l’art moderne.  Il fait ainsi des rapprochements surprenants :  Ingres
              inspirateur de la vision de Picasso, Delacroix et le refus de la perspective, Courbet précurseur
              inattendu du cubisme, les impressionnistes dont les taches de couleur annoncent l’art abstrait, Rodin le
              géniteur de Brancusi.
              <br />
              Comme critique d'art, Yvon Taillandier collabore à la revue Connaissance des arts de 1953 à 1968 et à
              la revue XXè siècle de 1958 à 1974. Il y publiera notamment le célèbre interview de Miro : « je travaille
              comme un jardinier ».
              <br />
              Comme historien de l’art, il publie de très nombreux articles et monographies consacrés à Giotto, Monet,
              Rodin, Gauguin, Cézanne, Corot…
            </p>
            <p>
              Yvon Taillandier va notamment s'attacher à l'histoire de l'art moderne dont il publiera un ouvrage
              important : « Les naissances de la peinture moderne – histoire de la peinture inventive de Ingres au tachisme ».
            </p>
          </div>
          <img src={ytIllustration} alt="" />
        </div>

        <h2>Extraits</h2>
        <article className="extrait right">
          <div className="extrait-text">
            <h4>Giotto</h4>
            <p>«… La première fresque choisie représente la rencontre de Joachim et d’Anne à la Porte-Dorée (..). Telle que Giotto nous la montre, la Porte-Dorée est un étrange édifice qui semble être à la fois proche et lointain (..). Cette invraisemblance qui fait d’un même objet un objet proche et un objet éloigné n’est pas la seule ni la plus curieuse invraisemblance qui soit dans la peinture de Giotto.</p>
            <p>« La vision de Ste Anne » et « la naissance de la vierge » sont deux évènements qui se produisent dans la même maison ; ce qui n’a rien d’invraisemblable. Ce qui est invraisemblable, c’est la maison elle-même dont on voit en même temps l’extérieur et l’intérieur (..).</p>
            <p>Et ces maisons dont l’intérieur et l’extérieur apparaissent en même temps, ces édifices comme la Porte-Dorée, qui sont représentés comme s’ils étaient vus en même temps et par la même personne de près et de loin, font penser (..) à certaines toiles de Braque, celles par exemple qui donnent au spectateur l’impression qu’il est possible aux mêmes yeux de voir au même moment un visage de face et de profil (..).</p>
            <p>Ce qu’il faut considérer dans la peinture de Giotto, c’est (..) le caractère d’actualité qu’elle affecte à nos yeux et qui nous permet de nous intéresser à elle plus que jamais peut-être on ne l’a fait ».</p>
            <p className="italic">Giotto – Hypérion</p>
          </div>
          <div className="extrait-img"><img src={Giotto} alt="" /></div>
        </article>
        <article className="extrait left">
          <div className="extrait-img"><img src={Rodin} alt="" /></div>
          <div className="extrait-text">
            <h4>Rodin</h4>
            <p>
              « Ainsi, le piéton décapité donne t’il à Rodin une place qu’aucun sculpteur avant lui, sinon Michel-Ange, n’avait occupé…
              <br />
              Les détracteurs de son homme acéphale (..) ne prévoyaient pas qu’en appliquant le principe qui permet de supprimer une tête et deux bras, et en supprimant les jambes, (..) puis la plupart des points de repère qui rendent possible l’identification du corps humain, on peut arriver aux ovoïdes de Brancusi, au nuages lisses de Arp, aux colonnes de glandes hypertrophiées de Stahly ».
            </p>
            <p className="italic">Rodin – Flammarion</p>
          </div>
        </article>
        <article className="extrait right">
          <div className="extrait-text">
            <h4>Cézanne</h4>
            <p>
              « Que l’amateur de peinture contemporaine veuille bien examiner (..) le corps des brigands au bas de la composition Les Voleurs et l’âne. Il lui trouvera des proportions singulières (..). Qu’il examine le bras du jeune homme au gilet rouge et il lui verra une longueur démesurée, parfaitement anormale. Qu’il regarde la tête de l’Arlequin. Que pense t’il de cette lèvre supérieure étonnement hypertrophiée….
              <br />
              Ne retrouve t’il pas dans tous ces exemples le prélude à cette trituration de l’anatomie, qui caractérise les recherches de nos cubistes, de nos expressionnistes, de nos naïfs et de nos abstraits (..), lesquels, chacun à sa manière, s’efforcent de faire rêver d’une humanité nouvelle ? ».
            </p>
            <p className="italic">Cézanne – Flammarion</p>
          </div>
          <div className="extrait-img"><img src={Cézanne} alt="" /></div>
        </article>
        <article className="extrait left">
          <div className="extrait-img"><img src={Monet} alt="" /></div>
          <div className="extrait-text">
            <h4>Monet</h4>
            <p>
              « Kandinsky a raconté l’effet que les Meules de Monet eurent sur lui, (..). Il a commencé par être irrité par le fait que le tableau était si flou qu’il a dû vérifier dans le catalogue pour en saisir le sujet. Mais la beauté des couleurs a vite fait de balayer son exaspération. Une splendeur tenant aux seules couleurs l’a bouleversé, et pour la première fois il a eu l’intuition de ce que l’objet n’était pas indispensable au tableau. En d’autres termes il a eu là l’idée de ce que serait la peinture moderne ».
            </p>
            <p className="italic">Monet  – Flammarion</p>
          </div>
        </article>
        <article className="extrait right">
          <div className="extrait-text">
            <h4>Corot</h4>
            <p>
              « Quand j’ai examiné attentivement ce paysage qui s’intitule La cathédrale de Chartres (..) quelque chose m’inquiétait. Pourquoi Corot (..) a-t-il accepté qu’une masse conique et herbeuse, comme un écran d’ombre, en occulte une partie ?
              <br />
              (..) Corot a fui la révolution qui agite Paris. La révolution de 1830 à laquelle il ne participe pas ne lui est peut-être pas aussi étrangère qu’on pourrait le supposer. Un révolutionnaire, il l’est peut-être, lui aussi, dans son domaine et à sa façon. (..). Son père lui accorde une pension (..) mais il n’est pas indépendant : son progéniteur le méprise de ne pas gagner d’argent et de n’avoir aucun succès. Ses envois (..) au Salon sont refusés ou mal accrochés ; et personne n’en parle. Sans être dans la misère il est et il sera longtemps du parti des humiliés.
              <br />
              Pourtant, il est en train de réaliser un chef d’œuvre (..). c’est sans doute un des motifs pour lesquels il inverse la hiérarchie qui eût voulu que la cathédrale, monument noble et antique, fût au premier plan. Il rétablit la justice dans l’imaginaire en donnant plus à ceux qui ont eu moins.
            </p>
            <p className="italic">Corot – Flammarion</p>
          </div>
          <div className="extrait-img"><img src={Corot} alt="" /></div>
        </article>
        <article className="extrait left">
          <div className="extrait-img"><img src={Klein} alt="" /></div>
          <div className="extrait-text">
            <h4>Yves Klein</h4>
            <p>
              « La toile est bleue, mais d’un bleu absolument uni, tirant sur le violet. Cette toile est si simple qu’elle ne comporte même pas un point. Comment s’intéresser à un pareil tableau ? qui a eu cette hardiesse ?
              <br />
              Yves Klein a fait de nombreux tableaux bleus sans rien d’autre que ce bleu uni. Il s’est même surnommé Yves le Monochrome.
              <br />
              Pourquoi ce bleu ne serait-il pas le bleu de la nuit ? (..) Le rectangle serait une fenêtre et, par cette fenêtre on voit un ciel lointain. Pourquoi ne serait-ce pas la mer ? je peux imaginer que je la survole. Ce peut être un rectangle de papier bleu posé au niveau du sol. (..) Une lucarne percée dans un plafond, et le ciel apparaît très loin. (..) un objet occupant des positions obliques. (..) Un rectangle que la perspective transforme en trapèze, mais que le peintre, après correction de l’effet perspectif, a représenté tel qu’il est, c’est-à-dire rectangulaire, et non tel qu’il apparaît, c’est-à-dire trapézoïdal. Dans ce cas, toutes les hypothèses concernant la position sont possibles, car le nombre des positions obliques est infini.
              <br />
              Cette idée d’infini s’ajoutant à l’idée des situations qu’on peut attribuer au rectangle (..) impressionne beaucoup. Il n’y a pas de doute, la peinture simple constitue un puissant stimulant pour l’imagination. (..). Nous vivons une époque d’invention où l’imagination est reine. (..) La peinture abstraite, en nous incitant à fortifier notre imagination, nous aide à vivre avec notre époque ».
            </p>
            <p className="italic">Yves Klein – extrait du <a className="link" href="https://www.amazon.fr/Voyage-loeil-Connaissance-peinture-abstraite/dp/B004F1S6QU/ref=sr_1_2?ie=UTF8&qid=1541589957&sr=8-2&keywords=yvon+taillandier" target="_blank" rel="noopener noreferrer">Voyage de l’œil</a></p>
          </div>
        </article>
        <article className="extrait right">
          <div className="extrait-text">
            <h4>Lanskoy</h4>
            <p>
              Longtemps je ne voyais que désordre et confusion dans la peinture de Lanskoy. Puis un jour, j’avisai une toile qui montrait une forme comparable à un cerceau dans un feuillage. Cette forme me sembla très simple et très facile à suivre du regard, pour peu que je ne prisse pas trop garde à l’éparpillement des feuilles, et que je voulusse bien envisager l’ensemble. La toile était de Lanskoy. Dans ce qui me paraissant auparavant si complexe, j’avais trouvé ce qu’il y avait de simple. Dès lors je ne cessai de m’intéresser à l’œuvre de ce peintre.
            </p>
            <p className="italic">Lanskoy – extrait du <a className="link" href="https://www.amazon.fr/Voyage-loeil-Connaissance-peinture-abstraite/dp/B004F1S6QU/ref=sr_1_2?ie=UTF8&qid=1541589957&sr=8-2&keywords=yvon+taillandier" target="_blank" rel="noopener noreferrer">Voyage de l’œil</a></p>
          </div>
          <div className="extrait-img lanskoy"><img src={Lanskoy} alt="" /></div>
        </article>
      </div>
    </div>
    <Footer />
  </div>
);

export default CritiqueArt;
